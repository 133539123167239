import { makeStyles } from '@material-ui/core';

export const useTabsStyles = makeStyles(() => ({
  tabs: {
    backgroundColor: 'white',
    borderRadius: '20px 20px 0 0',
    border: '1px solid #E7E7E7',
    borderBottom: 'none',
  },

  indicator: {
    display: 'none',
  },
}));
