import { TChainId } from 'api/types';
import { getReadProvider } from './provider/getReadProvider';

interface IReadContractDataArgs {
  chainId: TChainId;
  address: string;
  abi: any;
  methodName: string;
  methodArgs: unknown;
}

/**
 * Request data from a read contract method.
 * This function is used to get data from a contract method that does not modify the blockchain state.
 *
 * @returns The data returned by the contract method.
 */
export async function getReadContractData({
  chainId,
  address,
  abi,
  methodName,
  methodArgs,
}: IReadContractDataArgs): Promise<unknown> {
  const provider = getReadProvider(chainId);
  const contract = provider.createContract(abi, address) as any;

  const data = await contract.methods[methodName]
    .apply(null, methodArgs)
    .call();

  // if BigInt is returned, convert it to string
  if (typeof data === 'bigint' && data !== null) {
    return data.toString();
  }

  return data;
}
