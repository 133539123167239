import React from 'react';
import { Fade } from '@material-ui/core';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  className?: string;
}

export const TabPanel = ({
  children,
  value,
  index,
  ...other
}: TabPanelProps) => {
  const isActive = value === index;
  return (
    <Fade in={isActive}>
      <div
        role="tabpanel"
        hidden={!isActive}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {isActive && children}
      </div>
    </Fade>
  );
};
