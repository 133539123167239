import React from 'react';
import { Tooltip as TooltipComponent, TooltipProps } from '@material-ui/core';
import { useTooltipStyles } from './TooltipStyles';

interface ITooltipProps extends TooltipProps {
  theme?: 'light' | 'default' | 'black';
}

export const Tooltip = ({ theme = 'light', ...restProps }: ITooltipProps) => {
  const classes = useTooltipStyles();

  return (
    <TooltipComponent
      classes={{
        tooltip: classes[theme],
        arrow: classes[`${theme}Arrow`],
      }}
      {...restProps}
    />
  );
};
