import { IBaseTransaction } from 'common/types';
import { normalizeBigNumber } from 'common/utils/format';
import { pickBy } from 'lodash';
import querystring from 'query-string';

export const getQueryStringWithCleanedParams = (
  params: Record<string, any>,
): string => {
  const cleanedParams = pickBy(params, Boolean);
  return querystring.stringify(cleanedParams);
};

export function formatTransactionItem<TransactionType extends IBaseTransaction>(
  tx: TransactionType,
) {
  return {
    ...tx,
    valueFormatted: normalizeBigNumber(tx.valueHumanReadable),
  };
}

export function formatTransactionsArray<
  TransactionType extends IBaseTransaction,
>(txs: TransactionType[]) {
  return txs.map(formatTransactionItem);
}

export function mapTransactionsData<
  Reply extends { txs: any[] },
  Query extends { items: any[] },
>({ data }: { data: Reply; currentData?: Query }) {
  const { txs } = data;

  const lastTransaction = txs[txs.length - 1];

  const formattedData = formatTransactionsArray(txs);

  return {
    items: formattedData,
    lastTransaction,
  };
}
