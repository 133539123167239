import { IAbiItem, getContractAbi } from 'api/getContractAbi';
import { web3Api } from 'api/web3Api';
import { ACTION_CACHE_LONG } from 'common/const';
import { Address } from 'common/types';

interface IContractAbiArgs {
  chainId: number;
  address: Address;
}

export const { useGetContractAbiQuery, endpoints } = web3Api.injectEndpoints({
  endpoints: build => ({
    getContractAbi: build.query<IAbiItem[], IContractAbiArgs>({
      queryFn: async ({ address, chainId }) => {
        try {
          return { data: await getContractAbi(chainId, address) };
        } catch (error) {
          return { data: [] };
        }
      },
      keepUnusedDataFor: ACTION_CACHE_LONG,
    }),
  }),
});

export const {
  getContractAbi: { select: getContractAbiSelector },
} = endpoints;
