import { ReactNode } from 'react';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useMethodOutputStyles } from './useMethodOutputStyles';

interface IMethodOutputProps {
  name?: string;
  type: string;
  value?: ReactNode;
  isLoading?: boolean;
}

export function MethodOutput({
  name,
  type,
  value,
  isLoading,
}: IMethodOutputProps): JSX.Element {
  const classes = useMethodOutputStyles();

  const withName = !!name;

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', m: 0 }} component="p">
        {withName && <span>{name}</span>}

        <Box
          className={classes.type}
          ml={withName ? 0.5 : undefined}
          component="span"
        >
          {withName ? `(${type})` : type}
        </Box>
      </Box>

      {(!!value || isLoading) && (
        <p className={classes.value}>
          {isLoading ? <Skeleton width={40} /> : value}
        </p>
      )}
    </div>
  );
}
