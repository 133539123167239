import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import {
  TransactionsRedirectRouteConfig,
  TransactionsRouteConfig,
} from '../router/const';

export const TransactionRedirect = () => {
  const { hash } =
    TransactionsRedirectRouteConfig.transactionsByHash.useParams();

  return (
    <Route
      element={
        <Navigate
          to={TransactionsRouteConfig.transactionsByHash.generatePath(hash)}
        />
      }
    />
  );
};
