import { ITransaction } from 'common/types';
import { IS_EMBEDDED, CUSTOM_CHAINS } from '../common/const';
import { explorerRpc } from './explorerRpc';
import { buildRpcRequest } from './utils/buildRpcRequest';
import { mapTransactions } from './utils/mapTransactions';

interface IRequestParams {
  transactionHash: string;
}

type ITransactionsQueryV2 = {
  lastTransaction?: ITransaction;
  items?: ITransaction[];
};

export const { useGetTransactionsByHashQuery } = explorerRpc.injectEndpoints({
  endpoints: build => ({
    getTransactionsByHash: build.query<ITransactionsQueryV2, IRequestParams>({
      query: ({ transactionHash }) =>
        buildRpcRequest({
          method: 'explorer_getTransactions',
          params: {
            transactionHash,
            blockchain: IS_EMBEDDED ? CUSTOM_CHAINS : undefined,
          },
        }),
      transformResponse: mapTransactions,
    }),
  }),
});
