import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useSearchStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    height: 56,
    width: '100%',
    position: 'relative',
  },
  select: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline-flex',
      minWidth: 134,
      height: '100%',
    },
    '& .MuiInputBase-input': {
      padding: theme.spacing(0, 2),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 2.5),
      },
    },
    '& .MuiInput-root': {
      borderRadius: '12px 0 0 12px',
      height: '100%',
      marginRight: -1,
      fontWeight: 500,
    },
    '& .MuiSelect-icon': {
      right: theme.spacing(1.5),
    },
  },
  search: {
    width: 'calc(100% - 40px)',
    height: '100%',
    // todo: refactor with classes prop usage
    '& .MuiInput-root': {
      height: '100%',
      borderRadius: 12,
    },
    '& .MuiInput-input': {
      height: '100%',
      padding: theme.spacing(0, 4, 0, 2),
      textOverflow: 'ellipsis',
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 4, 0, 2.5),
      },
      '&::placeholder': {
        fontSize: 14,
        [theme.breakpoints.up('sm')]: {
          fontSize: 'inherit',
        },
      },
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      top: '100%',
    },
  },
  btn: {
    width: 60,
    height: '100%',
    borderRadius: '0 12px 12px 0',
    position: 'absolute',
    right: 0,
  },
}));
