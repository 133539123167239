import React, { ComponentProps, forwardRef } from 'react';
import { Container as MuiContainer, ContainerProps } from '@material-ui/core';

export const Container = forwardRef<
  HTMLElement,
  ContainerProps & ComponentProps<any> & { component?: React.ElementType }
>(({ children, component = 'div', ...props }, ref) => (
  <MuiContainer component={component} {...props} ref={ref}>
    {children}
  </MuiContainer>
));
