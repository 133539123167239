import { Milliseconds, Seconds } from 'common/types';

export * from './captions';
export * from './chains';
export * from './env';

export const SECOND: Milliseconds = 1000;

export const PER_PAGE_DEFAULT = 10;

export const ACTION_CACHE_LONG: Seconds = 600;

export const ERROR_CODE_COULDNT_RESOLVE = -32603;
export const ERROR_CODE_INVALID_ARGUMENT = -32602;

export const REQUEST_ERROR_MESSAGE = 'Request error';
export const ERROR_NAME_INVALID_ARGUMENT = 'Invalid argument';
