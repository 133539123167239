import {
  ERROR_CODE_COULDNT_RESOLVE,
  ERROR_CODE_INVALID_ARGUMENT,
  ERROR_NAME_INVALID_ARGUMENT,
} from 'common/const';

interface ICustomException extends Error {
  code?: string | number;
}

export function customException({
  message,
  code,
}: {
  message: string;
  code?: string | number;
}): ICustomException {
  const error: ICustomException = {
    name: 'Custom exception',
    message,
    code,
  };
  if (
    error.code === ERROR_CODE_INVALID_ARGUMENT ||
    ERROR_CODE_COULDNT_RESOLVE
  ) {
    error.name = ERROR_NAME_INVALID_ARGUMENT;
  }
  error.code = code;

  return error;
}
