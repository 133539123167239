import React from 'react';
import { IApiBlockchainName } from 'api/types';
import cn from 'classnames';
import { t } from 'common/utils/intl';
import { BlockchainIcon } from 'components/BlockchainIcon';
import { useBlockchainsForAddress } from './useBlockchainsForAddress';
import { useSupportedBlockchainsStyles } from './SupportedBlockchainsStyles';

interface ISupportedBlockchains {
  setSelected: (b: IApiBlockchainName | undefined) => void;
  selected: IApiBlockchainName | undefined;
}

export const SupportedBlockchains = ({
  selected,
  setSelected,
}: ISupportedBlockchains) => {
  const classes = useSupportedBlockchainsStyles();
  const { data } = useBlockchainsForAddress();

  function onSelect(item: IApiBlockchainName | undefined) {
    setSelected(item);
  }

  return (
    <div className={classes.container}>
      <div
        className={cn(classes.btn, !selected && classes.btnSelected)}
        onClick={() => setSelected(undefined)}
      >
        {t('supportedBlockchains.btnAllChains')}
      </div>

      {data.blockchainEntities.map(b => (
        <div
          className={cn(
            classes.btn,
            selected === b.name && classes.btnSelected,
          )}
          key={b.name}
          onClick={() => onSelect(b.name)}
        >
          <BlockchainIcon className={classes.blockchainIcon} type={b.name} />
          <div className={classes.value}>{b.verboseName}</div>
        </div>
      ))}
    </div>
  );
};
