export const OChainId = {
  ethereum: 1,
  ropsten: 3,
  rinkeby: 4,
  goerli: 5,
  dev: 2018,
  classic: 61,
  mordor: 63,
  kotti: 6,
  smartchain: 56,
  smartchainTestnet: 97,
  arbitrum: 42161,
  arbitrumTestnet: 421613,
  arbitrumSepoliaTestnet: 421614,
  arbitrumNova: 42170,
  avalanche: 43114,
  avalancheTestnet: 43113,
  polygon: 137,
  polygonZkEVM: 1101,
  polygonZkEVMTestnet: 1442,
  fantom: 250,
  fantomTestnet: 4002,
  mumbai: 80001,
  gnosis: 100,
  chiado: 10200,
  sokol: 77,
  xdc: 50,
  xdcTestnet: 51,
  sepolia: 11155111,
  optimism: 10,
  optimismTestnet: 420,
  optimismSepolia: 11155420,
  linea: 59144,
  lineaTestnet: 59140,
  holesky: 17000,
  opBNB: 204,
  opBNBTestnet: 5611,
  tron: 728126428,
  base: 8453,
  baseTestnet: 84532,
  mantle: 5000,
  mantleGoerli: 5001,
  mantleSepolia: 5003,
  celo: 42220,
  blast: 81457,
  blastTestnet: 168587773,
  zkSync: 324,
  zkSyncGoerli: 280,
  zkSyncSepolia: 300,
  moonbeam: 1284,
  taikoTestnet: 167008,
  nervos: 71402,
  tenet: 1559,
  rollux: 570,
  rolluxTestnet: 57000,
  chiliz: 88888,
  flare: 14,
  horizenEON: 7332,
  horizenEONTestnet: 1663,
  syscoin: 57,
  bitTorrent: 199,
  kava: 2222,
  iotex: 4689,
  harmony: 1666600000,
  zetaChainTestnet: 7001,
  scroll: 534352,
  scrollTestnet: 534351,
  klaytn: 8217,
  klaytnTestnet: 1001,
  core: 1116,
  mode: 34443,
  modeTestnet: 919,
  unknown: 0,
  neuraTestnet: 267,
  neuraDevnet: 268,
} as const;

export type TChainId = typeof OChainId[keyof typeof OChainId];
