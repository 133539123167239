import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { t } from 'common/utils/intl';
import { Search } from 'components/Search';
import { Container } from 'uiKit/Container';
import { useFilterStyles } from './FilterStyles';

function Header() {
  return (
    <Box textAlign="center" mb={5}>
      <Typography variant="h1">{t('mainPage.title')}</Typography>
      <Typography variant="body1">{t('mainPage.text')}</Typography>
    </Box>
  );
}

interface IFilterProps {
  HeaderComponent?: () => JSX.Element;
}

export const Filter = ({ HeaderComponent = Header }: IFilterProps) => {
  const classes = useFilterStyles();

  return (
    <section className={classes.root}>
      <Container>
        <HeaderComponent />
        <Search className={classes.searchBox} />
      </Container>
    </section>
  );
};
