import React from 'react';
import { Box, BoxProps } from '@material-ui/core';
import { ReactComponent as ErrorIcon } from './assets/error.svg';
import { ReactComponent as EtcIcon } from './assets/etc.svg';
import { ReactComponent as SuccessIcon } from './assets/success.svg';
import { useStatusStyles } from './StatusStyles';

export enum Statuses {
  success = '1',
  error = '0',
}

const getStatus = (status: string) => {
  switch (status) {
    case Statuses.success:
      return {
        msg: 'Success',
        icon: SuccessIcon,
      };

    case Statuses.error:
      return {
        msg: 'Error',
        icon: ErrorIcon,
      };

    default:
      return {
        msg: status,
        icon: EtcIcon,
      };
  }
};

interface IStatusProps extends BoxProps {
  type: string;
}

export const Status = ({ type, ...restProps }: IStatusProps) => {
  const classes = useStatusStyles();
  const status = getStatus(type);
  const Icon = status.icon;

  return (
    <Box display="flex" alignItems="center" {...restProps}>
      <Icon className={classes.icon} />
      {status.msg}
    </Box>
  );
};
