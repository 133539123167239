import { IApiTransaction } from 'api/types';
import { REQUEST_ERROR_MESSAGE } from 'common/const';
import { ITransaction } from 'common/types';
import { customException } from 'common/utils/customException';
import { mapTransactionsData } from 'store/actions/utils';

interface ITransactionReplyV2 {
  result?: {
    transactions: IApiTransaction[];
    nextPageToken?: string;
  };
  error?: { code: number; message: string };
}

export function mapTransactions(data: ITransactionReplyV2) {
  if (data.error) {
    throw customException({
      message: data.error?.message || REQUEST_ERROR_MESSAGE,
      code: data.error?.code,
    });
  }

  const defaultMethodName = 'transfer';
  const reply = {
    txs:
      data.result?.transactions?.map(tx => {
        return {
          ...tx,
          blockchainName: tx.blockchainName,
          methodName: tx.methodName || tx.method || defaultMethodName,
          fromAddressName: tx.fromAddressName || tx.fromAddress,
          toAddressName: tx.toAddressName || tx.toAddress,
        };
      }) || [],
  };

  return {
    ...mapTransactionsData<
      { txs: IApiTransaction[] },
      { items: ITransaction[] }
    >({ data: reply }),
    nextPageToken: data.result?.nextPageToken,
  };
}
