import React from 'react';
import { t } from 'common/utils/intl';
import { ErrorMessage } from 'components/ErrorMessage';
import { useLatestTransactions } from '../Lobby/components/Latest/useLatestTransactions';
import { TransactionsPage } from './components/TransactionsUI/TransactionsPage';

export const Transactions = () => {
  const { data, loading, error, blockchain, setBlockchain } =
    useLatestTransactions();

  const renderedError = error && (
    <ErrorMessage
      text={t('errorMessages.requestError')}
      style={{ marginBottom: 20 }}
    />
  );

  return (
    <TransactionsPage
      data={data?.items}
      isLoading={loading}
      error={renderedError}
      blockchain={blockchain}
      setBlockchainFilter={setBlockchain}
    />
  );
};
