import { ReactComponentElement } from 'react';

export const getAddressTitle = ({
  addressName,
  address,
}: {
  addressName: string | ReactComponentElement<any>;
  address: string;
}): string => {
  if (addressName === address) {
    return addressName;
  }

  return `${addressName}\n${address}`;
};

export const getPlains = (direction?: string) => {
  const fromPlainText = direction === 'OUT' || direction === 'SELF';
  const toPlainText = direction === 'IN' || direction === 'SELF';

  return {
    fromPlainText,
    toPlainText,
  };
};
