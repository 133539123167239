import React from 'react';
import { withSvgIcon } from './withSvgIcon';

export const SearchIcon = withSvgIcon(
  <path
    fill="currentColor"
    fillRule="evenodd"
    d="M18.4 11.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zm-1.238 6.676a8.5 8.5 0 111.414-1.414l6.532 6.53-1.415 1.415-6.53-6.531z"
    clipRule="evenodd"
  />,
  { viewBox: '0 0 28 28' },
);
