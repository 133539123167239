import { makeStyles } from '@material-ui/core';

export const useMethodsBoxStyles = makeStyles(theme => ({
  root: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: 'none',
    padding: theme.spacing(0, 2, 2),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 2.5, 2.5),
    },
  },
}));
