import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IApiBlockchainName } from 'api/types';
import { getChainIdByName } from 'api/utils/getChainIdByName';
import { CUSTOM_CHAINS } from 'common/const';
import querystring from 'query-string';
import { AddressRoutesConfig } from '../../router/const';
import { OTxTab, TTxTab } from '../types';
import {
  getCurrentAssetTab,
  getCurrentHistoryTab,
  getQueryParamsForTabs,
} from '../utils';
import { useContractMethods } from './useContractMethods';

const defaultBlockchain = CUSTOM_CHAINS ? CUSTOM_CHAINS[0] : undefined;

const useTabTagsFromSearchParams = () => {
  const { search } = useLocation();

  return useMemo(() => {
    const {
      blockchain = defaultBlockchain,
      history,
      balance,
    } = querystring.parse(search);

    if (
      (blockchain && typeof blockchain !== 'string') ||
      (history && typeof history !== 'string') ||
      (balance && typeof balance !== 'string')
    ) {
      console.error(`check search params in url ${search}`);
    }

    return {
      blockchain,
      history,
      balance,
    } as Record<string, string | undefined>; // console.error for string[] parsing
  }, [search]);
};

export const useAddress = () => {
  const {
    blockchain,
    history: historyTab,
    balance: balanceTab,
  } = useTabTagsFromSearchParams();
  const navigate = useNavigate();
  const { address } = AddressRoutesConfig.address.useParams();
  const currentRoutePath = AddressRoutesConfig.address.generatePath(address);

  const setTransactionsTabFromSearchParams = () =>
    getCurrentHistoryTab(historyTab);
  const setBalancesTabFromSearchParams = () => getCurrentAssetTab(balanceTab);

  const [selectedBn, setSelectedBn] = useState<IApiBlockchainName | undefined>(
    blockchain as IApiBlockchainName,
  );

  const chainId = getChainIdByName(selectedBn);
  const { contractABI } = useContractMethods(chainId);
  const isTabsShown = contractABI.length > 0;

  const [tabTx, setTxsTab] = useState<TTxTab>(
    setTransactionsTabFromSearchParams,
  );
  const [tabBalances, setTabAssets] = useState<0 | 1>(
    setBalancesTabFromSearchParams,
  );
  const hasFilterOrSelectedTab = selectedBn || tabTx || tabBalances;

  useEffect(() => {
    if (hasFilterOrSelectedTab) {
      setSelectedBn(blockchain as IApiBlockchainName);
      setTxsTab(setTransactionsTabFromSearchParams);
      setTabAssets(setBalancesTabFromSearchParams);
    }

    // current effect has to be fired only on address change
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [address]);

  useEffect(() => {
    let path = currentRoutePath;

    if (hasFilterOrSelectedTab) {
      path =
        currentRoutePath +
        getQueryParamsForTabs(selectedBn, tabTx, tabBalances);
    }

    // setting search params to url if filters or tabs were changed
    navigate(path);

    // current effect has to be fired only on tabs/filters switching
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [selectedBn, tabTx, tabBalances]);

  useEffect(() => {
    const isContractTab =
      tabTx === OTxTab.readContract || tabTx === OTxTab.writeContract;

    const shouldResetTxTab = !isTabsShown && isContractTab;

    if (shouldResetTxTab) {
      setTxsTab(OTxTab.transactions);
    }
  }, [isTabsShown, tabTx]);

  return {
    address,
    selectedBn,
    setSelectedBn,
    tabTx,
    setTxsTab,
    tabBalances,
    setTabAssets,
  };
};
