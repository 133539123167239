import { ITransaction } from 'common/types';
import { explorerRpc } from './explorerRpc';
import { IApiBlockchainName } from './types';
import { buildRpcRequest } from './utils/buildRpcRequest';
import { mapTransactions } from './utils/mapTransactions';

interface IRequestParams {
  blockchainName: IApiBlockchainName;
  blockNumber: string;
}

type ITransactionByBlockQueryV2 = {
  lastTransaction?: ITransaction;
  items?: ITransaction[];
};

export const { useGetBlockTransactionsQuery } = explorerRpc.injectEndpoints({
  endpoints: build => ({
    getBlockTransactions: build.query<
      ITransactionByBlockQueryV2,
      IRequestParams
    >({
      query: ({ blockchainName, blockNumber }) =>
        buildRpcRequest({
          method: 'explorer_getBlockTransactions',
          params: {
            blockchainName,
            blockNumber,
          },
        }),
      transformResponse: mapTransactions,
    }),
  }),
});
