import { IAbiItem } from 'api/getContractAbi';

/**
 * Filter read methods
 *
 * @param item ABI item
 * @returns `true` if item is a read method
 *
 * @example
 * const readMethods = abi.filter(isReadMethod);
 */
export function isReadMethod(item: IAbiItem) {
  return item.type === 'function' && item.stateMutability === 'view';
}
