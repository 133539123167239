import React, { ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { ErrorPage } from './ErrorPage';

export const ErrorBoundary = ({ children }: { children: ReactNode }) => {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog>
      {children}
    </Sentry.ErrorBoundary>
  );
};
