import React from 'react';
import { uid } from 'react-uid';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { PER_PAGE_DEFAULT, TransferCaptionType } from 'common/const';
import { TableBodyCell, TableRow } from 'components/TableComponents';
import { useTransactionsTableCellStyles } from '../TransactionsTableCellStyles';

interface IProps {
  captions: TransferCaptionType[];
  itemsCount?: number;
}

export const TransactionsTableRowSkeleton = ({
  captions,
  itemsCount = PER_PAGE_DEFAULT,
}: IProps) => {
  const classes = useTransactionsTableCellStyles();

  return (
    <>
      {Array(itemsCount)
        .fill(0)
        .map((_, i) => (
          <TableRow key={uid(i)}>
            {captions.map(({ label, key }, i) =>
              i === 0 ? (
                <TableBodyCell
                  className={classes.tableCell}
                  label={label}
                  key={key}
                >
                  <div className={classes.hashCell}>
                    <Box
                      display="flex"
                      justifyContent={{ xs: 'flex-end', md: 'flex-start' }}
                      className={classes.hashWrap}
                    >
                      <Skeleton variant="text" width="60%" />
                    </Box>
                    <Skeleton variant="circle" className={classes.icon} />
                  </div>
                </TableBodyCell>
              ) : (
                <TableBodyCell
                  className={classes.tableCell}
                  label={label}
                  key={key}
                >
                  <Box
                    display="flex"
                    justifyContent={{ xs: 'flex-end', md: 'flex-start' }}
                  >
                    <Skeleton variant="text" width="60%" />
                  </Box>
                </TableBodyCell>
              ),
            )}
          </TableRow>
        ))}
    </>
  );
};
