import React, { useCallback } from 'react';
import { Tabs as MuiTabs, TabsProps } from '@material-ui/core';
import { useTabsStyles } from './useTabsStyles';

type TTabsProps = Omit<
  TabsProps,
  | 'textColor'
  | 'variant'
  | 'scrollButtons'
  | 'className'
  | 'classes'
  | 'onChange'
>;

interface ITabsProps extends TTabsProps {
  onChange: (value: any) => void;
}

export function Tabs({ onChange, ...props }: ITabsProps): JSX.Element {
  const classes = useTabsStyles();

  const handleChange = useCallback(
    (_event: React.ChangeEvent<unknown>, value: any) => {
      onChange?.(value);
    },
    [onChange],
  );

  return (
    <MuiTabs
      {...props}
      onChange={handleChange}
      textColor="primary"
      variant="scrollable"
      scrollButtons="auto"
      className={classes.tabs}
      classes={{
        indicator: classes.indicator,
      }}
    />
  );
}
