import { useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetInteractionsQuery } from 'api/getInterractions';
import { IApiBlockchainName } from 'api/types';
import { useBlockchainName } from 'common/hooks/useBlockchainName';
import { IBlockchainInfoForAddress } from 'components/Blockchains/types';
import { AddressRoutesConfig } from 'modules/router/const';
import { ANKRSCAN_CHAINS, CUSTOM_CHAINS } from '../../common/const';

// TODO: handle and use isError, isLoading, isFetching
export const useBlockchainsForAddress = () => {
  const blockchains = CUSTOM_CHAINS ? CUSTOM_CHAINS : ANKRSCAN_CHAINS;
  const { address } = AddressRoutesConfig.address.useParams();

  const {
    data: iterationBlockchains,
    isError,
    isLoading,
    isFetching,
  } = useGetInteractionsQuery(
    address
      ? {
          address,
        }
      : skipToken,
  );

  const [blockchainEntities, setBlockchainEntities] = useState<
    IBlockchainInfoForAddress[]
  >([]);

  useEffect(() => {
    if (iterationBlockchains) {
      const newBlockchainEntities: IBlockchainInfoForAddress[] =
        iterationBlockchains.map(
          (name: IApiBlockchainName): IBlockchainInfoForAddress => {
            const chainName = useBlockchainName(name as IApiBlockchainName);
            return {
              verboseName: chainName,
              logo: '',
              name,
            };
          },
        );
      setBlockchainEntities(newBlockchainEntities);
    }

    if (!iterationBlockchains) {
      const newBlockchainEntities: IBlockchainInfoForAddress[] =
        blockchains.map(
          (name: IApiBlockchainName): IBlockchainInfoForAddress => {
            const chainName = useBlockchainName(name as IApiBlockchainName);
            return {
              verboseName: chainName,
              logo: '',
              name,
            };
          },
        );
      setBlockchainEntities(newBlockchainEntities);
    }
  }, [iterationBlockchains, blockchains, isLoading, isFetching, isError]);

  const data = {
    blockchainEntities,
  };

  return {
    data,
  };
};
