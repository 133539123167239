import React from 'react';
import { uid } from 'react-uid';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { TableBodyCell, TableRow } from 'components/TableComponents';
import { SUPPORTED_CHAINS } from '../../common/const';

interface IBlocksSkeletonProps {
  blocksCaptions: { key: string; label: string }[];
  classes: Record<string, string>;
}

const placeHolderArr = Array(SUPPORTED_CHAINS.length).fill(0);

export const BlocksSkeleton: React.FC<IBlocksSkeletonProps> = ({
  blocksCaptions,
  classes,
}) => {
  return (
    <>
      {placeHolderArr.map((_, i) => (
        <TableRow key={uid(i)}>
          {blocksCaptions.map(({ label, key }, i) =>
            i === 0 ? (
              <TableBodyCell
                className={classes.tableCell}
                label={label}
                key={key}
              >
                <div className={classes.blockCell}>
                  <Box
                    display="flex"
                    justifyContent={{ xs: 'flex-end', md: 'flex-start' }}
                    className={classes.blockWrap}
                  >
                    <Skeleton variant="text" width={60} />
                  </Box>

                  <Skeleton variant="circle" className={classes.icon} />
                </div>
              </TableBodyCell>
            ) : (
              <TableBodyCell
                className={classes.tableCell}
                label={label}
                key={key}
              >
                <Box
                  display="flex"
                  justifyContent={{ xs: 'flex-end', md: 'flex-start' }}
                >
                  <Skeleton variant="text" width={70} />
                </Box>
              </TableBodyCell>
            ),
          )}
        </TableRow>
      ))}
    </>
  );
};
