import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useChainscannerSearchStyles = makeStyles<Theme>(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    justifyContent: 'right',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  input: {
    width: '100%',
    maxWidth: 492,

    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },

    '& .MuiInputBase-input': {
      padding: theme.spacing(0, 2),
    },
    '& .MuiInput-root': {
      borderRadius: '12px 0 0 12px',
      border: '1px solid #DFE3EB',
      borderRight: 'none',
      height: 42,
    },
  },
  btn: {
    height: 42,
    borderRadius: '0 12px 12px 0',
  },
}));
