import { OWalletId, TWalletId } from './types';

type Connector = () => Promise<any>;

export const connectorsConfig: Record<TWalletId, Connector> = {
  [OWalletId.injected]: async () => {
    const { ethereum } = window as any;

    if (!ethereum) {
      throw new Error('Ethereum object not found');
    }

    await ethereum.request({
      method: 'eth_requestAccounts',
    });

    return ethereum;
  },
};
