import React from 'react';
import { Box, Button, Collapse, Paper, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import classNames from 'classnames';
import { useIsSMDown } from 'common/hooks/themeHooks';
import { IBalanceMap } from 'common/types';
import { t } from 'common/utils/intl';
import { ErrorMessage } from 'components/ErrorMessage';
import { Spinner } from 'components/Spinner';
import { ImageWithExternalSrc } from 'uiKit/ImageWithExternalSrc/ImageWithExternalSrc';
import { IS_EMBEDDED } from '../../../../common/const';
import { BalanceCheckbox } from '../BalanceCheckbox/BalanceCheckbox';
import { ReactComponent as Arrow } from './assets/arrow.svg';
import emptyIcon from './assets/emptyIcon.png';
import { ReactComponent as Update } from './assets/update.svg';
import { BalancesTable } from './BalancesTable';
import { useBalances } from './useBalances';
import { useBalanceStyles } from './BalanceStyles';

interface IBalanceProps {
  isLoading: boolean;
  error?: Error;
  balancesData?: IBalanceMap[] | null;
  className?: string;
  getBalances: () => void;
  totalBalanceUsd?: string;
}

export const BalanceUI = ({
  balancesData,
  isLoading,
  error,
  className,
  getBalances,
  totalBalanceUsd,
}: IBalanceProps) => {
  const classes = useBalanceStyles();
  const {
    totalBalance,
    hideZeroBalances,
    onChangeHideMode,
    amountHidden,
    balancesToRender,
    isOpened,
    toggleBalancesVisibility,
  } = useBalances(balancesData);

  const isSmDown = useIsSMDown();

  if (error) {
    return (
      <ErrorMessage
        className={classes.error}
        noBorderRadius
        title={error.name}
        text={error.message}
        onClick={getBalances}
      />
    );
  }

  if (!balancesToRender.length) {
    return (
      <Box className={classes.emptyContainer}>
        <img
          src={emptyIcon}
          alt=""
          title="No Assets"
          className={classes.emptyIcon}
        />
        <Typography variant="body1">{t('balances.noAssets')}</Typography>
      </Box>
    );
  }

  const assetLogosCount = 4;

  return (
    <div className={className}>
      <div
        className={classNames(
          !IS_EMBEDDED && classes.info,
          !isOpened && classes.borderRadiusBottom,
        )}
      >
        {!IS_EMBEDDED && (
          <Box className={classes.totalWrapper}>
            <Typography className={classes.totalTitle} variant="body2">
              {t('balances.total')}
            </Typography>
            <span className={classes.totalValue}>
              {isLoading ? (
                <Skeleton variant="text" height={54} width={75} />
              ) : (
                <Box display="flex" alignItems="center">
                  {t('unitUSD', {
                    value: totalBalanceUsd || totalBalance,
                  })}

                  <Button
                    className={classNames(classes.btn, classes.updateButton, {
                      [classes.isRefetching]: isLoading,
                    })}
                    onClick={getBalances}
                  >
                    {isLoading ? (
                      <Spinner className={classes.spinner} size={20} />
                    ) : (
                      <Update />
                    )}
                  </Button>

                  {balancesData && balancesData.length > 0 && (
                    <>
                      <Button
                        className={classNames(
                          classes.btn,
                          classes.collapseButton,
                        )}
                        onClick={toggleBalancesVisibility}
                      >
                        {isOpened
                          ? t('balances.hideAssets')
                          : t('balances.showAssets')}

                        <Arrow
                          className={classNames(
                            classes.btnArrow,
                            isOpened && classes.btnArrowReverted,
                          )}
                        />
                      </Button>

                      {!isOpened && !isSmDown && (
                        <Box marginLeft={3} display="flex">
                          {balancesToRender.map((b, i) => {
                            if (i >= assetLogosCount) {
                              return;
                            }
                            return (
                              <ImageWithExternalSrc
                                key={i}
                                className={classNames(
                                  classes.logo,
                                  classes.assetLogo,
                                )}
                                alt="asset logo"
                                src={b.logo}
                              />
                            );
                          })}
                          {balancesToRender.length >= assetLogosCount && (
                            <Box marginLeft={1}>
                              <Typography color="textSecondary">
                                {`+${
                                  balancesToRender.length - assetLogosCount
                                }`}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              )}
            </span>
          </Box>
        )}
        {!IS_EMBEDDED &&
          !isLoading &&
          balancesData &&
          balancesData.length > 0 && (
            <BalanceCheckbox
              hideZeroBalances={hideZeroBalances}
              onChangeHideMode={onChangeHideMode}
              amountHidden={amountHidden}
            />
          )}
      </div>

      {(isLoading || balancesToRender.length > 0) && (
        <Collapse in={isOpened}>
          <Paper className={classes.paper}>
            <BalancesTable
              balancesToRender={balancesToRender}
              isLoading={isLoading}
            />
          </Paper>
        </Collapse>
      )}
    </div>
  );
};
