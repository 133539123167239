import React from 'react';
import { uid } from 'react-uid';
import cn from 'classnames';
import { IBalanceMap } from 'common/types';
import { TablePagination } from 'components/TablePagination';
import { TableView } from 'components/TableView/TableView';
import { useTableViewPagination } from 'components/TableViewPagination/useTableViewPagination';
import { TransactionsTableRowSkeleton } from '../../../Transactions/components/TransactionsUI/TransactionsTableRow/TransactionsTableRowSkeleton';
import { BalanceRow } from './BalanceRow';
import { useBalanceStyles } from './BalanceStyles';

const assetsCaptions = [
  {
    key: 'asset',
    label: 'Asset',
  },
  {
    key: 'chain',
    label: 'Chain',
  },
  {
    key: 'address',
    label: 'Contract Address',
  },
  {
    key: 'quantity',
    label: 'Quantity',
  },
  {
    key: 'price',
    label: 'Token Price',
  },
  {
    key: 'usd',
    label: 'Value in USD',
  },
];

const balancesTableConfig = {
  tableGridTemplateColumns: '135px .8fr 1.2fr .8fr .5fr .5fr',
  tableCaptions: assetsCaptions,
};

interface IBalancesTableProps {
  isLoading: boolean;
  balancesToRender: IBalanceMap[];
}

export const BalancesTable = ({
  isLoading,
  balancesToRender,
}: IBalancesTableProps) => {
  const classes = useBalanceStyles();

  const {
    isLastPage,
    pageIndex,
    handleChangePage,
    rowsForCurrentPage,
    rowsPerPageCount,
    handlePerPageCountChange,
    hasPagination,
    pagesCount,
  } = useTableViewPagination<IBalanceMap>(balancesToRender);

  const rows = rowsForCurrentPage.map(asset => (
    <BalanceRow
      key={uid(asset.blockchainName + asset.contractAddress)}
      asset={asset}
      assetsCaptions={assetsCaptions}
      groupName={asset.blockchainName}
    />
  ));

  const { tableGridTemplateColumns, tableCaptions } = balancesTableConfig;

  return (
    <>
      <TableView
        className={cn(
          classes.assetsTable,
          hasPagination && classes.tableWithPagination,
        )}
        tableGridTemplateColumns={tableGridTemplateColumns}
        transfersCaptions={tableCaptions}
      >
        {isLoading ? (
          <TransactionsTableRowSkeleton
            captions={assetsCaptions}
            itemsCount={rowsForCurrentPage.length}
          />
        ) : (
          <>{rows}</>
        )}
      </TableView>
      {hasPagination && (
        <TablePagination
          className={classes.pagination}
          onPerPageChange={handlePerPageCountChange}
          onPaginationChange={handleChangePage}
          perPage={rowsPerPageCount}
          hasNextPage={!isLastPage}
          currentPage={pageIndex}
          pagesCount={pagesCount}
        />
      )}
    </>
  );
};
