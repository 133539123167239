import { Contract, Web3 } from 'web3';
import { IProvider } from './types';

export class ReadProvider implements IProvider {
  web3: Web3;

  constructor(public url: string, public chainId: number) {
    this.web3 = new Web3();
    const provider = new Web3.providers.HttpProvider(url);
    this.web3.setProvider(provider);
  }

  public createContract(abi: any, address: string) {
    return new this.web3.eth.Contract(abi, address);
  }
}
