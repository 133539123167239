import React, { forwardRef, ForwardRefExoticComponent } from 'react';
import { Link as RouterLink, useMatch } from 'react-router-dom';
import { Button, ButtonProps } from '@material-ui/core';
import classNames from 'classnames';

export type INavLinkProps = {
  component?: string | React.ComponentType;
  href: string;
  onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void;
  activeClassName?: string;
  exactMatch?: boolean;
  className?: string;
  isBlank?: boolean;
} & ButtonProps;

export const NavLink: ForwardRefExoticComponent<INavLinkProps> = forwardRef<
  HTMLButtonElement,
  INavLinkProps
>(
  (
    {
      href,
      onClick,
      activeClassName,
      className,
      exactMatch = false,
      variant = 'text',
      isBlank = true,
      ...props
    }: INavLinkProps,
    ref,
  ) => {
    const isLink =
      href.startsWith('http') ||
      href.startsWith('mailto') ||
      href.startsWith('tel');

    const match = useMatch({
      path: href,
    });

    if (isLink) {
      return (
        <Button
          component="a"
          href={href}
          onClick={onClick}
          role="link"
          rel={isBlank ? 'noopener noreferrer' : undefined}
          target={isBlank ? '_blank' : undefined}
          ref={ref}
          className={className}
          variant={variant}
          {...(props as any)}
        />
      );
    }

    return (
      <Button
        component={RouterLink as any}
        to={href}
        onClick={onClick}
        ref={ref}
        className={classNames(className, match && activeClassName)}
        variant={variant}
        {...props}
      />
    );
  },
);
