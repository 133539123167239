import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const usePaginationStyles = makeStyles<Theme>(theme => ({
  root: {},

  list: {
    display: 'grid',
    gridTemplateAreas: `'first prev total next'`,
    gridTemplateColumns: 'repeat(4, min-content)',
    gap: '0 6px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },

  btn: {
    height: 32,
    padding: theme.spacing(0, 1.5),
    minWidth: theme.spacing(4.5),
    color: theme.palette.primary.main,

    '& svg': {
      width: '1em',
      height: '1em',
    },
  },

  btnLabel: {
    textTransform: 'capitalize',
  },

  btnPrev: {
    gridArea: 'prev',
    fontSize: 14,
  },
  btnNext: {
    gridArea: 'next',
    fontSize: 14,
  },
  btnFirst: {
    gridArea: 'first',
  },
  btnTotal: {
    gridArea: 'total',
    whiteSpace: 'nowrap',

    '&&': {
      cursor: 'auto',
    },
  },
  btnLast: {
    gridArea: 'last',
  },
}));
