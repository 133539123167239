import React from 'react';
import { Link } from 'react-router-dom';
import { uid } from 'react-uid';
import { transactionsCaptions } from 'common/const';
import { ITransaction } from 'common/types';
import { fromWei } from 'common/utils/fromWei';
import { getTimePassed } from 'common/utils/getTimePassed';
import {
  TableBodyCell,
  TableCellAddress,
  TableCellHash,
  TableCellMethodName,
  TableRow,
} from 'components/TableComponents';
import { BlockRoutesConfig } from '../../../../router/const';
import { useTransactionsTableCellStyles } from '../TransactionsTableCellStyles';

interface IProps {
  items: ITransaction[];
}

const VALUE_PRECISION = 6;

export const TransactionsTableRow = ({ items }: IProps) => {
  const classes = useTransactionsTableCellStyles();

  return (
    <>
      {items.map(
        ({
          blockchainName,
          transactionHash,
          blockHeight,
          fromAddress,
          fromAddressName,
          toAddress,
          toAddressName,
          value,
          timestamp,
          methodName,
          blockchainLogo,
          valueSymbol,
        }) => {
          const convertedValue = fromWei(value)
            .decimalPlaces(VALUE_PRECISION)
            .toFormat();

          return (
            <TableRow key={uid(blockchainName + transactionHash)}>
              <TableCellHash
                label={transactionsCaptions[0].label}
                blockchainName={blockchainName}
                blockchainLogo={blockchainLogo}
                transactionHash={transactionHash}
              />

              <TableCellMethodName
                label={transactionsCaptions[1].label}
                methodName={methodName}
              />

              <TableBodyCell
                className={classes.tableCell}
                label={transactionsCaptions[2].label}
              >
                <Link
                  to={BlockRoutesConfig.blockDetails.generatePath(
                    blockchainName,
                    blockHeight,
                  )}
                >
                  {blockHeight}
                </Link>
              </TableBodyCell>

              <TableBodyCell
                className={classes.tableCell}
                label={transactionsCaptions[3].label}
              >
                {getTimePassed(timestamp)}
              </TableBodyCell>

              <TableCellAddress
                label={transactionsCaptions[4].label}
                address={fromAddress}
                addressName={fromAddressName}
              />

              <TableCellAddress
                label={transactionsCaptions[5].label}
                address={toAddress}
                addressName={toAddressName}
              />

              <TableBodyCell
                className={classes.tableCell}
                label={transactionsCaptions[6].label}
              >
                {`${convertedValue} ${valueSymbol || blockchainName}`}
              </TableBodyCell>
            </TableRow>
          );
        },
      )}
    </>
  );
};
