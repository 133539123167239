import { useParams } from 'react-router';
import { useGetBlocksByHeightQuery } from 'api/getBlocksByHeight';

export const useBlocksByHeight = () => {
  const { blockHeight } = useParams<{
    blockHeight: string;
  }>();

  const { data, error, refetch, isLoading, isFetching } =
    useGetBlocksByHeightQuery({
      blockNumber: +(blockHeight as string),
    });

  return {
    error,
    loading: isLoading || isFetching,
    data,
    getBlocks: refetch,
  };
};
