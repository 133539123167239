import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTransactionDirectionStyles } from './TransactionDirectionStyles';

export type TTransactionDirection = 'IN' | 'OUT' | 'SELF' | '?';

const getDirection = (
  address: string,
  fromAddress: string,
  toAddress: string,
  direction?: TTransactionDirection,
): TTransactionDirection => {
  if (direction !== undefined) {
    return direction;
  }

  if (
    fromAddress.toLocaleLowerCase() === toAddress.toLocaleLowerCase() &&
    fromAddress.toLocaleLowerCase() === address.toLocaleLowerCase()
  )
    return 'SELF';
  if (toAddress.toLocaleLowerCase() === address.toLocaleLowerCase())
    return 'IN';
  if (fromAddress.toLocaleLowerCase() === address.toLocaleLowerCase())
    return 'OUT';
  return '?';
};

interface ITransactionDirectionProps {
  address: string;
  fromAddress: string;
  toAddress: string;
  direction?: TTransactionDirection;
}

export const TransactionDirection = ({
  address,
  fromAddress,
  toAddress,
  direction,
}: ITransactionDirectionProps) => {
  const classes = useTransactionDirectionStyles();

  const directionValue = useMemo(
    () => getDirection(address, fromAddress, toAddress, direction),
    [address, fromAddress, toAddress, direction],
  );

  return (
    <span
      className={classNames(
        classes.direction,
        classes[`direction${directionValue}`],
      )}
    >
      {directionValue}
    </span>
  );
};
