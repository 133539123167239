import { WriteProvider } from './WriteProvider';

export async function getWriteProvider(): Promise<WriteProvider> {
  const provider = WriteProvider.getInstance();

  if (!provider.isConnected()) {
    await provider.connect();
  }

  return provider;
}
