import React from 'react';
import { t } from 'common/utils/intl';
import { ErrorMessage } from 'components/ErrorMessage';
import { TransactionsRouteConfig } from '../router/const';
import { TransactionsPage } from './components/TransactionsUI/TransactionsPage';
import { useTransactionsByHash } from './useTransactionsByHash';

export const TransactionsByHash = () => {
  const { hash } = TransactionsRouteConfig.transactionsByHash.useParams();

  const { getTransactions, data, loading, error } = useTransactionsByHash(hash);

  const renderedError = error && (
    <ErrorMessage
      text={t('errorMessages.requestError')}
      onClick={getTransactions}
    />
  );

  return (
    <TransactionsPage
      data={data?.items}
      isLoading={loading}
      error={renderedError}
    />
  );
};
