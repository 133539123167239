import { IBlockchainForAddressInfo } from 'components/Blockchains/types';
import { getQueryStringWithCleanedParams } from 'store/actions/utils';

export * from './isReadMethod';
export * from './isWriteMethod';

export const HISTORY_ANCHORS = {
  TRANSACTIONS: 'transactions',
  MONEY_TRANSFERS: 'moneyTransfers',
  NFT_TRANSACTIONS: 'nftTransactions',
};

export const BALANCE_ANCHORS = {
  ASSET: 'asset',
  NFT: 'nft',
};

export const getCurrentHistoryTab = (historyTab?: string) => {
  switch (historyTab) {
    case HISTORY_ANCHORS.TRANSACTIONS: {
      return 0;
    }

    case HISTORY_ANCHORS.MONEY_TRANSFERS: {
      return 1;
    }

    case HISTORY_ANCHORS.NFT_TRANSACTIONS: {
      return 2;
    }

    default: {
      return 0;
    }
  }
};

export const getHistoryKeyByTab = (tab?: number) => {
  switch (tab) {
    case 0: {
      return HISTORY_ANCHORS.TRANSACTIONS;
    }

    case 1: {
      return HISTORY_ANCHORS.MONEY_TRANSFERS;
    }

    case 2: {
      return HISTORY_ANCHORS.NFT_TRANSACTIONS;
    }

    default: {
      return undefined;
    }
  }
};

export const getCurrentAssetTab = (historyTab?: string) => {
  switch (historyTab) {
    case BALANCE_ANCHORS.ASSET: {
      return 0;
    }

    case BALANCE_ANCHORS.NFT: {
      return 1;
    }

    default: {
      return 0;
    }
  }
};

export const getBalanceKeyByTab = (tab?: number) => {
  switch (tab) {
    case 0: {
      return BALANCE_ANCHORS.ASSET;
    }

    case 1: {
      return BALANCE_ANCHORS.NFT;
    }

    default: {
      return undefined;
    }
  }
};

export const getQueryParamsForTabs = (
  blockchain?: IBlockchainForAddressInfo,
  historyTab = 0,
  tabBalances = 0,
) => {
  const value = getQueryStringWithCleanedParams({
    blockchain,
    history: getHistoryKeyByTab(historyTab),
    balance: getBalanceKeyByTab(tabBalances),
  });

  if (!value) {
    return '';
  }

  return `?${value}`;
};
