import React, { useCallback } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { t } from 'common/utils/intl';
import { validateAddressResolver } from 'common/utils/validateAddressResolver';
import {
  AddressRoutesConfig,
  BlockRoutesConfig,
  TransactionsRouteConfig,
} from 'modules/router/const';
import { Button } from 'uiKit/Button';
import { SearchIcon } from 'uiKit/Icons/SearchIcon';
import { InputField } from 'uiKit/InputField';
import { searchFormat } from './utils';
import { useSearchStyles } from './SearchStyles';

const MIN_ADDRESS_LENGTH = 20;
const MAX_ADDRESS_LENGTH = 60;
const TRANSACTION_LENGTH = 66;
const BLOCK_LENGTH = 10;

interface ISearchValues {
  search: string;
}

export interface ISearchProps {
  className?: string;
  onSubmit: (values: ISearchValues) => void;
}

/**
 * todo: rewrite with hook-form
 */
export const SearchComponent = ({ className, onSubmit }: ISearchProps) => {
  const classes = useSearchStyles();
  const renderForm = useCallback(
    ({ handleSubmit, form }: FormRenderProps<ISearchValues>) => {
      return (
        <form
          className={classNames(classes.root, className)}
          onSubmit={event => {
            handleSubmit(event);
            if (!form.getState().hasSubmitErrors) {
              form.reset();
            }
          }}
          autoComplete="off"
        >
          <Field
            className={classes.search}
            component={InputField}
            name="search"
            type="text"
            placeholder="Search by Address / Txn Hash / Block"
            color="primary"
            format={searchFormat}
          />
          <Button className={classes.btn} type="submit">
            <SearchIcon />
          </Button>
        </form>
      );
    },
    [className, classes],
  );

  return (
    <Form
      onSubmit={onSubmit}
      render={renderForm}
      initialValues={{ search: '' }}
    />
  );
};

interface SearchProps {
  className?: string;
}

export const Search = ({ className }: SearchProps) => {
  const navigate = useNavigate();
  const onSubmit = useCallback(
    ({ search }: ISearchValues) => {
      const searchValue = searchFormat(search);
      const isStartsFrom0x = searchValue.slice(0, 2) === '0x';
      const isTransaction =
        (searchValue.length === TRANSACTION_LENGTH ||
          searchValue.length < MIN_ADDRESS_LENGTH) &&
        isStartsFrom0x;
      const isAddress =
        MIN_ADDRESS_LENGTH < searchValue.length &&
        searchValue.length < MAX_ADDRESS_LENGTH &&
        isStartsFrom0x;
      const isBlock =
        parseInt(searchValue) === Number(searchValue) &&
        searchValue.length < BLOCK_LENGTH;
      const isAddressToResolve = validateAddressResolver(searchValue);

      const goToTxnDetails = () =>
        navigate(
          TransactionsRouteConfig.transactionsByHash.generatePath(searchValue),
        );
      const goToAddress = () =>
        navigate(AddressRoutesConfig.address.generatePath(searchValue));
      const goToBlock = () =>
        navigate(BlockRoutesConfig.blocksByHeight.generatePath(searchValue));
      const goToAddressResolver = async () => {
        navigate(AddressRoutesConfig.addressResolver.generatePath(searchValue));
      };

      if (isBlock) {
        return goToBlock();
      } else if (isTransaction) {
        return goToTxnDetails();
      } else if (isAddress) {
        return goToAddress();
      } else if (isAddressToResolve) {
        return goToAddressResolver();
      } else {
        return {
          search: t('errorMessages.invalidSearchValue'),
        };
      }
    },
    [navigate],
  );

  return <SearchComponent onSubmit={onSubmit} className={className} />;
};
