import { IBalanceMap } from 'common/types';
import { customException } from 'common/utils/customException';
import { normalizeBigNumber } from 'common/utils/format';
import { IS_EMBEDDED, CUSTOM_CHAINS } from '../common/const';
import { explorerRpc } from './explorerRpc';
import { IApiBalance } from './types';
import { buildRpcRequest } from './utils/buildRpcRequest';

interface IRequestParams {
  address: string;
}

interface IAddressAssetsQuery {
  assets?: IBalanceMap[];
  totalBalanceUsd: string;
}

interface IAddressAssetsReply {
  result?: {
    assets?: IApiBalance[];
    totalBalanceUsd: string;
  };
  error?: {
    code: number;
    message: string;
  };
}

export const { useGetAddressAssetsQuery } = explorerRpc.injectEndpoints({
  endpoints: build => ({
    getAddressAssets: build.query<IAddressAssetsQuery, IRequestParams>({
      query: ({ address }) =>
        buildRpcRequest({
          method: 'explorer_getAddressAssets',
          params: {
            address,
            blockchain: IS_EMBEDDED ? CUSTOM_CHAINS : undefined,
          },
        }),

      transformResponse: (data: IAddressAssetsReply) => {
        if (data.error) {
          throw customException(data.error);
        }

        return {
          assets: data.result?.assets?.map(asset => {
            return {
              blockchainName: asset.blockchain,
              contractAddress: asset.contractAddress || '',
              logo: asset.thumbnail,
              usdPrice: +asset.tokenPrice,
              usdValue: +asset.balanceUsd,
              value: asset.balance,
              valueDecimals: asset.tokenDecimals,
              valueFormatted: normalizeBigNumber(asset.balance),
              valueHumanReadable: asset.balance,
              valueSymbol: asset.tokenSymbol,
              tokenName: asset.tokenName,
              tokenType: asset.tokenType,
            };
          }),
          totalBalanceUsd: data.result?.totalBalanceUsd || '0',
        };
      },
    }),
  }),
  overrideExisting: false,
});
