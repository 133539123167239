import React from 'react';
import { Link } from 'react-router-dom';
import { Box, BoxProps, Typography } from '@material-ui/core';
import { IApiBlockchainName } from 'api/types';
import classNames from 'classnames';
import { useBlockchainName } from 'common/hooks/useBlockchainName';
import { t } from 'common/utils/intl';
import { BlockchainIcon } from 'components/BlockchainIcon';
import { BlockRoutesConfig, ChainRoutesConfig } from '../../../router/const';
import { useBlockInfoStyles } from './BlockInfoStyles';

interface IBlockInfoProps extends BoxProps {
  className?: string;
  blockHeight: string;
  blockchain: IApiBlockchainName;
  transactionsCount: number;
}

export const BlockInfo = ({
  className,
  blockHeight,
  blockchain,
  transactionsCount,
  ...restProps
}: IBlockInfoProps) => {
  const classes = useBlockInfoStyles();
  const blockchainName = useBlockchainName(blockchain);

  return (
    <Box {...restProps} className={classNames(classes.root, className)}>
      <span className={classes.text}>{t('blockInfo.forBlock')}</span>
      <Link
        className={classes.link}
        to={BlockRoutesConfig.blockDetails.generatePath(
          blockchain,
          blockHeight,
        )}
      >
        {blockHeight}
      </Link>
      <BlockchainIcon className={classes.icon} type={blockchain} />
      <Link to={ChainRoutesConfig.chainDetails.generatePath(blockchain)}>
        {blockchainName}
      </Link>
      {Boolean(transactionsCount) && (
        <Typography variant="body1" className={classes.totalTransactionsCount}>
          {t('blockInfo.totalTransactions')}
          {transactionsCount}
        </Typography>
      )}
    </Box>
  );
};
