import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useTablePaginationStyles = makeStyles<Theme>(theme => ({
  root: {
    padding: theme.spacing(2, 0),
    color: theme.palette.text.secondary,

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4.5, 2.5),
      background: theme.palette.background.paper,
      borderRadius: '0 0 20px 20px',
      border: 'solid #E6E6E6',
      borderWidth: '0 1px 1px',
    },
  },
}));
