import React from 'react';
import { useMatch } from 'react-router';
import { GlobalMenu } from '@ankr.com/global-menu';
import classNames from 'classnames';
import { useIsSMDown } from 'common/hooks/themeHooks';
import { Search } from 'components/Search';
import { Container } from 'uiKit/Container';
import { NavLink } from 'uiKit/NavLink';
import { PATH } from '../../../../../router/const';
import { ReactComponent as LogoIcon } from './assets/logoBeta.svg';
import { ReactComponent as MenuIcon } from './assets/menu.svg';
import { useHeaderStyles } from './HeaderStyles';

const IS_MENU_ICON_HIDDEN = true;

export const Header = () => {
  const classes = useHeaderStyles();
  const match = useMatch(PATH.HOME);
  const isHomePage = !!match;
  const isMobile = useIsSMDown();

  return (
    <header
      className={classNames(classes.root, !isHomePage && classes.rootUnderline)}
    >
      <Container className={classes.container} maxWidth={false}>
        <GlobalMenu project="ankrscan" isMobile={isMobile} locale="en-US" />
        <NavLink
          className={classes.logo}
          href={PATH.HOME}
          activeClassName={classes.logoActive}
          exactMatch
        >
          <div
            className={classNames(
              classes.menuIcon,
              IS_MENU_ICON_HIDDEN && classes.menuIconHidden,
            )}
          >
            <MenuIcon />
          </div>
          <LogoIcon className={classes.logoIcon} />
        </NavLink>
        {!isHomePage && <Search className={classes.search} />}
      </Container>
    </header>
  );
};
