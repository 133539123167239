import { alpha, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useTableBodyCellStyles = makeStyles<
  Theme,
  {
    defense?: boolean;
    paddingCollapse?: boolean;
    on?: boolean;
  }
>(theme => ({
  cell: {
    alignItems: 'center',
    fontWeight: 400,

    [theme.breakpoints.up('md')]: {
      display: 'inline-grid',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
    },

    '&:first-child': {
      paddingLeft: 0,
    },

    '&:last-child': {
      paddingRight: 0,
    },

    '&$centerCell': {
      textAlign: 'center',
    },

    '&$leftCell': {
      textAlign: 'left',
    },

    '&$rightCell': {
      textAlign: 'right',
    },
  },

  withCaption: {
    display: 'grid',
    gridTemplateColumns: '0.4fr 0.6fr',
    gap: theme.spacing(0, 2),
    textAlign: 'right',

    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      display: 'inline-grid',
      gridTemplateColumns: 'auto',
      gap: 0,
    },

    '&::before': {
      content: 'attr(data-label)',
      display: 'block',
      maxWidth: '100%',
      fontSize: 14,
      textAlign: 'left',

      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  },

  bodyCell: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),

    [theme.breakpoints.up('md')]: {
      paddingTop: props =>
        props.defense ? theme.spacing(0.75) : theme.spacing(2),
      paddingBottom: props =>
        props.defense ? theme.spacing(0.75) : theme.spacing(2),

      minHeight: props => (props.defense ? 0 : theme.spacing(8)),
    },
  },

  centerCell: {},
  leftCell: {},
  rightCell: {},

  cellWrapper: {
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  tableCell: {
    position: 'relative',
    transitionTimingFunction: 'linear',
    transitionDuration: '200ms',
    transitionProperty: 'background-color',
  },
}));
