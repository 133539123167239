import { ReactNode } from 'react';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { featureConfig } from 'common/featureConfig';
import { ChainscannerSearch } from '../Search/ChainscannerSearch';
import { usePageTopStyles } from './usePageTopStyles';

interface PageTopProps {
  children?: ReactNode;
  className?: string;
}

export const PageTop = ({ children, className }: PageTopProps) => {
  const classes = usePageTopStyles();
  return (
    <Box className={classNames(classes.root, className)}>
      {children}
      {featureConfig.isSearchInsidePageDisplayed && <ChainscannerSearch />}
    </Box>
  );
};
