import { IAbiInput } from 'api/getContractAbi';
import { TChainId } from 'api/types';
import { useLazyGetReadContractDataQuery } from 'modules/Address/actions/getReadContractData';
import { AddressRoutesConfig } from 'modules/router/const';
import { MethodForm, TMethodFormSubmit } from '../MethodForm';

interface IReadMethodFormProps {
  chainId: TChainId;
  methodName: string;
  inputs: IAbiInput[];
  outputs: IAbiInput[];
  proxyAddress?: string;
}

export function ReadMethodForm({
  methodName,
  inputs,
  outputs,
  chainId,
  proxyAddress,
}: IReadMethodFormProps): JSX.Element {
  const { address } = AddressRoutesConfig.address.useParams();

  const [getReactContractData, { data: readContractData, isLoading }] =
    useLazyGetReadContractDataQuery();

  const { data, isError } = readContractData || {};

  const handleSubmit: TMethodFormSubmit = data => {
    const methodArgs = inputs.map(({ name }) => data[name]);
    getReactContractData({
      chainId,
      address,
      methodName,
      methodArgs,
      proxyAddress,
    });
  };

  return (
    <MethodForm
      name={methodName}
      inputs={inputs}
      outputs={outputs}
      onSubmit={handleSubmit}
      result={data ? `${data}` : undefined}
      isError={isError}
      isLoading={isLoading}
    />
  );
}
