import React from 'react';
import { useTransactionsTableCellStyles } from 'modules/Transactions/components/TransactionsUI/TransactionsTableCellStyles';
import { TableBodyCell } from '../TableBodyCell';

interface IProps {
  label: string;
  methodName: string;
}

export const TableCellMethodName = ({ label, methodName }: IProps) => {
  const classes = useTransactionsTableCellStyles();

  return (
    <TableBodyCell className={classes.tableCell} label={label}>
      <div className={classes.tableCellRootMethodName}>
        <div className={classes.tableCellMethodName}>{methodName}</div>
      </div>
    </TableBodyCell>
  );
};
