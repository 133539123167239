import { alpha, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useTransactionDirectionStyles = makeStyles<Theme>(theme => ({
  direction: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 24,
    paddingTop: 4,
    paddingBottom: 3,
    borderRadius: 4,
    width: 42,
    fontSize: 12,
  },

  directionIN: {
    color: '#00B474',
    background: 'rgba(39, 165, 119, 0.2)',
  },
  directionOUT: {
    color: theme.palette.primary.main,
    background: alpha(theme.palette.primary.main, 0.1),
  },
  directionSELF: {
    color: '#8690A8',
    background: '#EAEDF3',
  },
}));
