import { customException } from 'common/utils/customException';
import { explorerRpc } from './explorerRpc';
import { buildRpcRequest } from './utils/buildRpcRequest';

interface IAddressResolverQuery {
  address?: string;
}

interface IAddressResolverParams {
  address: string;
}

interface IAddressResolverReply {
  result?: {
    address: string;
  };
  error?: {
    code: number;
    message: string;
  };
}

export const { useGetAddressResolverQuery } = explorerRpc.injectEndpoints({
  endpoints: build => ({
    getAddressResolver: build.query<
      IAddressResolverQuery,
      IAddressResolverParams
    >({
      query: ({ address }) =>
        buildRpcRequest({
          method: 'explorer_resolveDomain',
          params: {
            namespace: address,
          },
        }),

      transformResponse: (data: IAddressResolverReply) => {
        if (data.error) {
          throw customException(data.error);
        }

        return {
          address: data.result?.address,
        };
      },
    }),
  }),
});
