import React, { ReactNode } from 'react';
import { IApiBlockchainName } from 'api/types';
import cn from 'classnames';
import { transactionsCaptions } from 'common/const';
import { ITransaction } from 'common/types';
import { SupportedBlockchains } from 'components/SupportedBlockchains';
import { TablePagination } from 'components/TablePagination';
import { TableView } from 'components/TableView/TableView';
import { useTableViewPagination } from 'components/TableViewPagination/useTableViewPagination';
import { BlockInfo } from '../BlockInfo';
import { TransactionsTableRow } from './TransactionsTableRow/TransactionsTableRow';
import { TransactionsTableRowSkeleton } from './TransactionsTableRow/TransactionsTableRowSkeleton';
import { useTransactionsStyles } from './TransactionsStyles';

const tableGridTemplateColumns = '1.2fr 1fr 1fr 1fr 1fr 1fr 1fr';

interface ITransactionsUiProps {
  isTransactionsFilterAvailable: boolean;
  blockchain?: IApiBlockchainName;
  setBlockchainFilter?: (b: IApiBlockchainName | undefined) => void;
  blockHeight?: string;
  error?: ReactNode;
  isLoading?: boolean;
  data?: ITransaction[];
  className?: string;
}

export const TransactionsUi = ({
  isTransactionsFilterAvailable,
  blockchain,
  setBlockchainFilter,
  blockHeight,
  error,
  isLoading = false,
  data,
  className,
}: ITransactionsUiProps) => {
  const classes = useTransactionsStyles();
  const {
    isLastPage,
    pageIndex,
    pagesCount,
    hasPagination,
    handleChangePage,
    rowsForCurrentPage,
    rowsPerPageCount,
    handlePerPageCountChange,
    transactionsCount,
  } = useTableViewPagination<ITransaction>(data);

  const renderContent = (
    <>
      <TableView
        tableGridTemplateColumns={tableGridTemplateColumns}
        transfersCaptions={transactionsCaptions}
        className={cn(
          classes.tableTransactions,
          hasPagination && classes.tableWithPagination,
          className,
        )}
      >
        {isLoading ? (
          <TransactionsTableRowSkeleton captions={transactionsCaptions} />
        ) : (
          <TransactionsTableRow items={rowsForCurrentPage} />
        )}
      </TableView>
      {hasPagination && (
        <TablePagination
          onPerPageChange={handlePerPageCountChange}
          onPaginationChange={handleChangePage}
          perPage={rowsPerPageCount}
          hasNextPage={!isLastPage}
          currentPage={pageIndex}
          pagesCount={pagesCount}
        />
      )}
    </>
  );

  return (
    <>
      {isTransactionsFilterAvailable && setBlockchainFilter && (
        <SupportedBlockchains
          selected={blockchain}
          setSelected={setBlockchainFilter}
        />
      )}
      {blockchain && blockHeight && (
        <BlockInfo
          blockchain={blockchain}
          blockHeight={blockHeight}
          transactionsCount={transactionsCount}
          mt={{ xs: 1, md: -1 }}
          mb={{ xs: 1, md: 3.5 }}
        />
      )}
      {error || renderContent}
    </>
  );
};
