import { alpha, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useHeaderStyles = makeStyles<Theme>(theme => ({
  root: {
    padding: theme.spacing(1.2, 0),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 0),
    },
  },
  rootUnderline: {
    [theme.breakpoints.up('md')]: {
      borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
    },
  },
  container: {
    display: 'grid',
    gap: theme.spacing(3.5, 0),
    gridTemplateColumns: 'min-content auto 1fr',
    alignItems: 'center',
    minHeight: 52,

    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(0, 5),
    },
  },
  menuIcon: {
    borderRight: '1px solid #c4c4c4',
    display: 'flex',
    padding: '10px 16px 10px 0',
    marginRight: 16,
  },
  menuIconHidden: {
    display: 'none',
  },
  logo: {
    fontSize: 36,
    padding: 0,
    justifyContent: 'flex-start',
    '&:hover': {
      background: 'none',
      color: theme.palette.primary.dark,
    },
  },
  logoActive: {
    cursor: 'default',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  logoIcon: {
    display: 'block',
    height: '1em',
    width: 'auto',
  },
  search: {
    justifySelf: 'end',
    height: 52,
    width: '100%',
    maxWidth: 760,
    paddingLeft: theme.spacing(3),
  },
}));
