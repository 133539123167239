import { makeStyles, Theme } from '@material-ui/core';

export const useBlockStyles = makeStyles<Theme>(theme => ({
  titleHash: {
    fontSize: 'inherit',
    marginLeft: theme.spacing(1),
  },

  questionIcon: {
    fontSize: 16,
    marginRight: theme.spacing(0.75),
    opacity: 0.5,
  },

  blockchainIcon: {
    fontSize: 21,
    marginRight: theme.spacing(0.75),
  },

  breakWord: {
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
}));
