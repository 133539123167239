import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useTransactionUIStyles = makeStyles<Theme>(theme => ({
  questionIcon: {
    fontSize: 16,
    marginRight: theme.spacing(0.75),
    opacity: 0.5,
  },

  blockchainIcon: {
    fontSize: 21,
    marginRight: theme.spacing(0.75),
  },

  copyHash: {
    fontSize: 'inherit',
    color: theme.palette.primary.main,
    maxWidth: '100%',
  },

  breakWord: {
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
}));
