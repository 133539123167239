import React from 'react';
import { Box, BoxProps } from '@material-ui/core';
import classNames from 'classnames';
import { t } from 'common/utils/intl';
import { Spinner } from '../Spinner';
import { useTableShowOlderStyles } from './TableShowOlderStyles';

interface IProps extends BoxProps {
  onLoadOlder: (event: any) => void;
  isLoading?: boolean;
  hasNextPage: boolean;
}

export const TableShowOlder = ({
  onLoadOlder,
  isLoading = false,
  hasNextPage,
  className,
  ...rest
}: IProps) => {
  const classes = useTableShowOlderStyles();

  if (!hasNextPage) {
    return null;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      className={classNames(classes.root, className)}
      {...rest}
    >
      <button
        onClick={onLoadOlder}
        disabled={isLoading}
        className={classes.showOlder}
      >
        {isLoading ? (
          <Spinner centered={false} size={16} />
        ) : (
          t('tableShowOlder.showOlder')
        )}
      </button>
    </Box>
  );
};
