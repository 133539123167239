import BigNumber from 'bignumber.js';

const ETH_SCALE_FACTOR = 10 ** 18;

export const fromWei = (value: number | BigNumber | string) => {
  const ethScaleFactor = new BigNumber(ETH_SCALE_FACTOR);

  if (typeof value === 'number' || typeof value === 'string') {
    return new BigNumber(value).dividedBy(ethScaleFactor);
  } else {
    return value.dividedBy(ethScaleFactor);
  }
};
