import React, { useMemo } from 'react';
import { uid } from 'react-uid';
import Skeleton from '@material-ui/lab/Skeleton';
import { getRandomInt } from 'common/utils/getRandomInt';
import { Table, TableBody, TableBodyCell, TableRow } from '../TableComponents';

const DetailsRowSkeleton = ({
  firstCellWith = 70,
  lastCellWidth = 60,
}: {
  firstCellWith?: number;
  lastCellWidth?: number;
}) => {
  return (
    <TableRow>
      <TableBodyCell>
        <Skeleton variant="text" width={firstCellWith} />
      </TableBodyCell>

      <TableBodyCell>
        <Skeleton variant="text" width={lastCellWidth} />
      </TableBodyCell>
    </TableRow>
  );
};

export const DetailsSkeleton = () => {
  const renderedRows = useMemo(
    () =>
      Array(5)
        .fill(0)
        .map((_, i) => (
          <DetailsRowSkeleton
            key={uid(i)}
            lastCellWidth={getRandomInt(60, 100)}
          />
        )),
    [],
  );

  return (
    <Table columnsCount={2} customCell="0.3fr 0.7fr">
      <TableBody>
        <DetailsRowSkeleton lastCellWidth={60} />

        <DetailsRowSkeleton firstCellWith={120} lastCellWidth={300} />

        {renderedRows}
      </TableBody>
    </Table>
  );
};
