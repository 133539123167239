import { TransferCaptionType } from 'common/const';
import { IBlockchainForAddressInfo } from 'components/Blockchains/types';

export interface IAddressBlock {
  blockchain?: IBlockchainForAddressInfo;
  tab: number;
  onTabChange: (newValue: any) => void;
}

export type RequestType =
  | 'tokenTransfers'
  | 'nftTransfers'
  | 'transactions'
  | 'nftAssets';

export interface ITransfersTableConfig {
  type: RequestType;
  transfersCaptions: TransferCaptionType[];
  tableGridTemplateColumns: string;
}

export const OTxTab = {
  transactions: 0,
  tokenTransfers: 1,
  nftTransfers: 2,
  readContract: 3,
  writeContract: 4,
  readProxyContract: 5,
  writeProxyContract: 6,
} as const;

export type TTxTab = typeof OTxTab[keyof typeof OTxTab];
