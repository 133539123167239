import { ReactNode, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { AngleDownIcon } from 'uiKit/Icons/AngleDownIcon';
import { useMethodsListStyles } from './useMethodsListStyles';

interface IMethodsItemProps {
  number: number;
  name: string;
  children?: ReactNode | ((expanded: boolean) => ReactNode);
}

export function MethodsItem({
  children,
  name,
  number,
}: IMethodsItemProps): JSX.Element {
  const classes = useMethodsListStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleExpand}
      component="li"
      variant="outlined"
      className={classes.item}
    >
      <AccordionSummary
        classes={{
          root: classes.itemSummary,
          content: classes.itemSummaryContent,
        }}
        expandIcon={<AngleDownIcon size="xs" />}
      >
        {`${number}. ${name}`}
      </AccordionSummary>

      <AccordionDetails className={classes.itemDetails}>
        {children instanceof Function ? children(expanded) : children}
      </AccordionDetails>
    </Accordion>
  );
}
