import { ReactNode } from 'react';
import { usePageLayoutStyles } from './usePageLayoutStyles';

export interface IPageLayoutProps {
  children?: ReactNode;
  headerSlot?: ReactNode;
  footerSlot?: ReactNode;
}

export function PageLayout({
  children,
  headerSlot,
  footerSlot,
}: IPageLayoutProps): JSX.Element {
  const classes = usePageLayoutStyles();

  return (
    <div className={classes.root}>
      {headerSlot}

      <main className={classes.main}>{children}</main>

      {footerSlot}
    </div>
  );
}
