import React from 'react';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { t } from 'common/utils/intl';
import { IconCheckboxChecked } from 'uiKit/Icons/IconCheckboxChecked';
import { IconCheckboxEmpty } from 'uiKit/Icons/IconCheckboxEmpty';
import { useBalanceCheckboxStyles } from './BalanceCheckboxStyles';

interface IBalanceCheckboxProps {
  hideZeroBalances: boolean;
  onChangeHideMode: () => void;
  amountHidden: number;
}

export const BalanceCheckbox = ({
  hideZeroBalances,
  onChangeHideMode,
  amountHidden,
}: IBalanceCheckboxProps) => {
  const classes = useBalanceCheckboxStyles();

  return (
    <FormControlLabel
      className={classes.checkboxLabel}
      control={
        <Checkbox
          color="primary"
          icon={<IconCheckboxEmpty fill="white" color="primary" />}
          checkedIcon={<IconCheckboxChecked fill="white" color="primary" />}
          checked={hideZeroBalances}
          onChange={onChangeHideMode}
          name="balances"
        />
      }
      label={
        <Typography color="secondary" className={classes.itemMore}>
          {t('balances.smallBalancesCheckbox')}
          {amountHidden > 0 && `\u00a0(${amountHidden})`}
        </Typography>
      }
    />
  );
};
