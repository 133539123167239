import React, { FC, useEffect } from 'react';
import { Tabs } from '@material-ui/core';
import { IMoneyTransfer, INftTransfer } from 'common/types';
import { StyledTab } from 'components/StyledTab';
import { TabPanel } from 'components/TabPanel';
import { TxTransfersTable } from '../../TxTransfersTable';
import { useTxTransfersStyles } from './TxTransfersStyles';

interface ITxTransfers {
  moneyTransfers?: IMoneyTransfer[];
  nftTransfers?: INftTransfer[];
}

export const TxTransfers: FC<ITxTransfers> = ({
  moneyTransfers,
  nftTransfers,
}) => {
  const classes = useTxTransfersStyles();

  const isMoneyTransferAvailable = moneyTransfers && moneyTransfers.length > 0;
  const isNftTransferAvailable = nftTransfers && nftTransfers.length > 0;

  const [tabIndex, setTabIndex] = React.useState(0);
  useEffect(() => {
    const initialTabIndex = isMoneyTransferAvailable ? 0 : 1;
    setTabIndex(initialTabIndex);
  }, [isMoneyTransferAvailable, isNftTransferAvailable]);

  if (!(isMoneyTransferAvailable || isNftTransferAvailable)) {
    return null;
  }

  const handleTxTabChange = (
    event: React.ChangeEvent<unknown>,
    newValue: any,
  ) => {
    setTabIndex(newValue);
  };

  return (
    <section className={classes.table}>
      <Tabs
        value={tabIndex}
        onChange={handleTxTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        className={classes.tabs}
        classes={{
          indicator: classes.indicator,
        }}
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
      >
        <StyledTab
          isHidden={!isMoneyTransferAvailable}
          label="Token Transfers"
        />
        <StyledTab
          isHidden={!isMoneyTransferAvailable}
          label="NFT Transactions"
        />
      </Tabs>

      <TabPanel value={tabIndex} index={0}>
        <TxTransfersTable items={moneyTransfers} type="tokenTransfers" />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <TxTransfersTable items={nftTransfers} type="nftTransfers" />
      </TabPanel>
    </section>
  );
};
