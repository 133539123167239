import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useFilterStyles = makeStyles<Theme>(theme => ({
  root: {
    padding: theme.spacing(4, 0, 4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6, 0),
    },
  },
  searchBox: {
    maxWidth: 760,
    margin: '0 auto',
    height: 52,
    [theme.breakpoints.up('md')]: {
      height: 62,
    },
  },
}));
