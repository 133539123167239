import { TChainId } from 'api/types';
import { MethodsBox } from '../MethodsBox';
import { MethodsItem, MethodsList } from '../MethodsList';
import { ReadMethodOutput } from '../ReadMethodOutput';
import { ReadMethodForm } from '../ReadMethodsForm';
import { useProxyReadMethods } from './useProxyReadMethods';

interface IProxyReadMethodsProps {
  chainId: TChainId;
}

export function ProxyReadMethods({
  chainId,
}: IProxyReadMethodsProps): JSX.Element {
  const { methods, proxyAddress, isLoading } = useProxyReadMethods(chainId);
  const hasMethods = !!methods.length;

  return (
    <MethodsBox isLoading={isLoading} isNoData={!hasMethods}>
      {hasMethods && (
        <MethodsList>
          {methods.map(({ name, outputs, inputs }, index) => (
            <MethodsItem number={index + 1} name={name} key={name}>
              {expanded => {
                const hasInputs = !!inputs.length;
                return hasInputs ? (
                  <ReadMethodForm
                    chainId={chainId}
                    methodName={name}
                    inputs={inputs}
                    outputs={outputs}
                    proxyAddress={proxyAddress}
                  />
                ) : (
                  <ReadMethodOutput
                    chainId={chainId}
                    outputs={outputs}
                    methodName={name}
                    shouldRequestData={expanded}
                    proxyAddress={proxyAddress}
                  />
                );
              }}
            </MethodsItem>
          ))}
        </MethodsList>
      )}
    </MethodsBox>
  );
}
