import React, { useCallback, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import { t } from 'common/utils/intl';
import { ReactComponent as CopyIcon } from './assets/copy.svg';
import { useCopyAddressStyles } from './CopyAddressStyles';

interface ICopyAddressProps {
  address: string;
  className?: string;
}

export const CopyAddress = ({ address, className }: ICopyAddressProps) => {
  const classes = useCopyAddressStyles();
  const [isCopy, setCopy] = useState(false);

  useEffect(() => {
    if (isCopy) {
      setTimeout(() => {
        setCopy(false);
        (document.activeElement as any)?.blur();
      }, 1000);
    }
  }, [isCopy]);

  const onCopy = useCallback(() => setCopy(true), []);

  return (
    <CopyToClipboard text={address} onCopy={onCopy}>
      <Typography
        className={classNames(classes.root, className)}
        color="secondary"
        variant="body1"
        component="div"
        title={t('copyAddress.title')}
      >
        <span>{address}</span>

        {isCopy ? (
          <div className={classes.copyText}>{t('copyAddress.copyText')}</div>
        ) : (
          <CopyIcon className={classes.copyIcon} />
        )}
      </Typography>
    </CopyToClipboard>
  );
};
