import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useSuccessTxStyles = makeStyles<Theme>(() => ({
  noSuccess: {
    fontWeight: 900,
    color: '#ffffff !important',
    textAlign: 'center',
    textSize: '16px',
    marginRight: '4px',
    position: 'relative',
    top: '1px',

    '&::before': {
      content: `"!"`,
      display: 'block',
      boxSizing: 'border-box',
      whiteSpace: 'nowrap',
      background: '#de4437',
      borderRadius: '50%',
      width: '16px',
      height: '16px',
      fontSize: '12px',
    },
  },

  title: {
    display: 'flex',
  },

  link: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
