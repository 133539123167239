import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const IconCheckboxChecked = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 22 22" fill="none">
      <rect
        x="0.5"
        y="0.5"
        width="21"
        height="21"
        rx="3.5"
        fill={props.fill}
        stroke="currentColor"
      />
      <path
        d="M5.5 11.5L9.5 15.5L18 7"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
      />
    </SvgIcon>
  );
};
