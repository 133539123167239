import { uniqueId } from 'lodash';

type Method =
  | 'explorer_getLatestBlocks'
  | 'explorer_getLatestTransactions'
  | 'explorer_getBlockDetails'
  | 'explorer_getBlocks'
  | 'explorer_getTransactionDetails'
  | 'explorer_getTransactions'
  | 'explorer_getAddressTransactions'
  | 'explorer_getBlockTransactions'
  | 'explorer_getAddressAssets'
  | 'explorer_getAddressNFTs'
  | 'explorer_getAddressTokenTransfers'
  | 'explorer_getAddressNftTransfers'
  | 'explorer_getBlockchainStats'
  | 'explorer_getTransactionTransfers'
  | 'explorer_getInteractions'
  | 'explorer_resolveDomain';

type RpcRequestParams = {
  method: Method;
  params?: Record<string, unknown>;
};

const buildRpcRequestData = ({ method, params = {} }: RpcRequestParams) => {
  return {
    jsonrpc: '2.0',
    method,
    params,
    id: uniqueId(),
  };
};

export const buildRpcRequest = ({ method, params }: RpcRequestParams) => {
  return {
    url: `/`,
    method: 'POST',
    body: buildRpcRequestData({
      method,
      params,
    }),
  };
};
