import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const web3Api = createApi({
  reducerPath: 'web3Api',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: '',
  }),
  endpoints: () => ({}),
});
