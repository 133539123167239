import moment from 'moment';

export const getTimePassed = (timestamp: string | number | Date) => {
  if (typeof timestamp === 'string' || typeof timestamp === 'number') {
    return moment(
      moment(parseInt(timestamp + '000')).format('YYYY-MM-DD HH:mm:ss'),
      'YYYY-MM-DD HH:mm:ss',
    ).fromNow();
  } else {
    return moment(
      moment(timestamp.toString()).format('YYYY-MM-DD HH:mm:ss'),
      'YYYY-MM-DD HH:mm:ss',
    ).fromNow();
  }
};
