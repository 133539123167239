import React, { useEffect, useState } from 'react';
import { noop } from 'lodash';
import { Spinner } from '../../components/Spinner';
import placeholder from './placeholder.svg';

interface IProps {
  src?: string;
  className?: string;
  alt?: string;
}

export const ImageWithExternalSrc = (props: IProps) => {
  const { src = placeholder, alt, className } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setIsLoaded(true);
    img.onerror = () => setHasError(true);

    return () => {
      img.onload = noop;
      img.onerror = noop;
    };
  }, [src]);

  if (hasError) {
    return <img className={className} alt={alt} src={placeholder} />;
  }

  if (!isLoaded) {
    return <Spinner className={className} centered={false} />;
  }

  return <img className={className} alt={alt} {...props} />;
};
