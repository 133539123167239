import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useBalanceCheckboxStyles = makeStyles<Theme>(theme => ({
  checkboxLabel: {
    marginRight: 0,
  },
  itemMore: {
    fontSize: 14,

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));
