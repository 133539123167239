import { ITransaction } from 'common/types';
import { IS_EMBEDDED, CUSTOM_CHAINS } from '../common/const';
import { explorerRpc } from './explorerRpc';
import { IApiBlockchainName } from './types';
import { buildRpcRequest } from './utils/buildRpcRequest';
import { mapTransactions } from './utils/mapTransactions';

interface IRequestParams {
  blockchain?: IApiBlockchainName;
  walletAddress: string;
  pageToken?: string;
  pageSize?: number;
}

type ITransactionsQueryV2 = {
  lastTransaction?: ITransaction;
  items?: ITransaction[];
  nextPageToken?: string;
};

export const { useGetTransactionsByAddressQuery } = explorerRpc.injectEndpoints(
  {
    endpoints: build => ({
      getTransactionsByAddress: build.query<
        ITransactionsQueryV2,
        IRequestParams
      >({
        query: ({ walletAddress, blockchain, pageToken, pageSize = 10 }) =>
          buildRpcRequest({
            method: 'explorer_getAddressTransactions',
            params: {
              walletAddress,
              blockchain:
                blockchain || (IS_EMBEDDED ? CUSTOM_CHAINS : undefined),
              pageToken,
              pageSize,
            },
          }),
        transformResponse: mapTransactions,
      }),
    }),
    overrideExisting: false,
  },
);
