import { makeStyles } from '@material-ui/core';

export const useMethodsListStyles = makeStyles(
  theme => ({
    root: {
      display: 'grid',
      gap: theme.spacing(2),
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },

    item: {
      borderRadius: theme.shape.borderRadius,

      '&.Mui-expanded': {
        margin: 0,
      },
    },

    itemSummary: {
      minHeight: 36,
      backgroundColor: theme.palette.background.default,

      '&.Mui-expanded': {
        minHeight: 36,
      },
    },

    itemSummaryContent: {
      margin: 0,

      '&.Mui-expanded': {
        margin: 0,
      },
    },

    itemDetails: {
      display: 'grid',
      gap: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
  }),
  { index: 1 },
);
