import { REQUEST_ERROR_MESSAGE } from 'common/const';
import { customException } from 'common/utils/customException';
import { explorerRpc } from './explorerRpc';
import { IApiBlockchainName, IApiBlockchainStats } from './types';
import { buildRpcRequest } from './utils/buildRpcRequest';

type InteractionsStatsQuery = IApiBlockchainName[];

interface IInteractionsStatsParams {
  address: string;
}

interface IInteractionsStatsReply {
  result?: { blockchains: IApiBlockchainName[] };
  error?: {
    code: number;
    message: string;
  };
}

export const { useGetInteractionsQuery } = explorerRpc.injectEndpoints({
  endpoints: build => ({
    getInteractions: build.query<
      InteractionsStatsQuery,
      IInteractionsStatsParams
    >({
      query: ({ address }) =>
        buildRpcRequest({
          method: 'explorer_getInteractions',
          params: {
            address,
          },
        }),

      transformResponse: (data: IInteractionsStatsReply) => {
        if (data.error || !data.result) {
          throw customException({
            message: data.error?.message || REQUEST_ERROR_MESSAGE,
            code: data.error?.code,
          });
        }

        return data.result.blockchains;
      },
    }),
  }),
});
