import { ReactNode, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@material-ui/core';
import { IAbiInput } from 'api/getContractAbi';
import classNames from 'classnames';
import { Spinner } from 'components/Spinner';
import { MethodOutput } from '../MethodOutput';
import { FormInput } from './FormInput';
import { useMethodContentFormStyles } from './useMethodContentFormStyles';

export type TMethodFormSubmit = (data: any) => void;

const defaultSubmitHandler: TMethodFormSubmit = data => {
  console.log(data);
};

interface IMethodFormProps {
  name?: string;
  inputs: IAbiInput[];
  outputs: IAbiInput[];
  submitLabel?: string;
  result?: ReactNode;
  isError?: boolean;
  isLoading?: boolean;
  onSubmit?: TMethodFormSubmit;
}

export function MethodForm({
  name,
  inputs,
  submitLabel,
  outputs,
  result,
  isError,
  isLoading,
  onSubmit = defaultSubmitHandler,
}: IMethodFormProps): JSX.Element {
  const classes = useMethodContentFormStyles();

  const defaultValues = useMemo(
    () =>
      inputs.reduce((acc, { name }) => {
        acc[name] = '';
        return acc;
      }, {} as Record<string, string>),
    [inputs],
  );

  const { handleSubmit, control } = useForm({ defaultValues });

  return (
    <form
      className={classes.root}
      onSubmit={handleSubmit(onSubmit)}
      name={name}
    >
      {inputs.map(({ name, type }) => (
        <FormInput key={name} control={control} name={name} type={type} />
      ))}

      <div>
        <Button
          type="submit"
          variant="outlined"
          disabled={isLoading}
          endIcon={isLoading && <Spinner size={16} centered={false} />}
        >
          {submitLabel || 'Query'}
        </Button>
      </div>

      {outputs.map(output => (
        <MethodOutput name={output.name} type={output.type} key={output.name} />
      ))}

      <div className={classNames(isError && classes.resultError)}>{result}</div>
    </form>
  );
}
