import { makeStyles, Theme } from '@material-ui/core';

export const useStatusStyles = makeStyles<Theme>(theme => ({
  icon: {
    marginRight: theme.spacing(0.75),
    width: '1em',
    height: '1em',
    fontSize: 17,
  },
}));
