import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const usePageTopStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '20px',
    marginBottom: theme.spacing(4),

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
}));
