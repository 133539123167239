import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useTxTransfersStyles = makeStyles<Theme>(() => ({
  table: {
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    boxSizing: 'border-box',
    marginTop: '32px',
    border: '1px solid #E6E6E6',
    overflow: 'hidden',
  },
  container: {
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    boxSizing: 'border-box',
    marginTop: '32px',
    paddingBottom: '24px',
    border: '1px solid #E6E6E6',
  },
  title: {
    height: 62,
    textTransform: 'none',
    color: '#000',
    fontSize: 16,
    padding: '20px',
  },
}));
