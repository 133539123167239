import React from 'react';
import { Box, Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ReactComponent as NothingFoundImg } from './nothingFound.svg';

export const NothingFound = ({ className }: { className?: string }) => {
  return (
    <Paper variant="outlined" className={className}>
      <Box py={5} px={{ xs: 2, md: 4 }} sx={{ textAlign: 'center' }}>
        <NothingFoundImg />
      </Box>
    </Paper>
  );
};

export const NothingFoundMargin = () => {
  const classes = useNothingFoundStyles();
  return <NothingFound className={classes.margin} />;
};

const useNothingFoundStyles = makeStyles<Theme>(() => ({
  margin: {
    border: 'none',
    borderTop: '1px solid #E6E6E6',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
  },
}));

export const useNotFoundTxStyles = makeStyles<Theme>(() => ({
  notFound: {
    backgroundColor: '#ffffff',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    boxSizing: 'border-box',
    border: '1px solid #E6E6E6',
    borderTopColor: 'transparent',
  },
}));

export const NothingFoundTxs = () => {
  const classes = useNotFoundTxStyles();
  return <NothingFound className={classes.notFound} />;
};
