import { ReactNode } from 'react';
import { useMethodsListStyles } from './useMethodsListStyles';

interface IMethodsListProps {
  children: ReactNode;
}

export function MethodsList(props: IMethodsListProps): JSX.Element {
  const classes = useMethodsListStyles();

  return <ol {...props} className={classes.root} />;
}
