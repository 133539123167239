import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useTableHeadStyles = makeStyles<
  Theme,
  {
    count: number;
    customCell?: string;
    paddingCollapse?: boolean;
  }
>(theme => ({
  head: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: props =>
        props.customCell ? props.customCell : `repeat(${props.count}, 1fr)`,
      alignItems: 'stretch',
      borderRadius: '20px',
      padding: theme.spacing(0, 2),
    },
  },
  headSticky: {
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      zIndex: 1,
      top: 0,
    },
  },
  row: {
    display: 'contents',
  },
}));
