import { IAbiItem } from 'api/getContractAbi';
import { TChainId } from 'api/types';
import { useContractMethods } from 'modules/Address/hooks/useContractMethods';
import { isReadMethod } from 'modules/Address/utils';

interface IUseReadMethods {
  methods: IAbiItem[];
  isLoading: boolean;
}

export function useReadMethods(chainId: TChainId): IUseReadMethods {
  const { contractABI, isContractAbiLoading } = useContractMethods(chainId);

  const methods = contractABI.filter(isReadMethod);

  return {
    methods,
    isLoading: isContractAbiLoading,
  };
}
