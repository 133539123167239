import { IAbiItem } from 'api/getContractAbi';
import { TChainId } from 'api/types';
import { Address } from 'common/types';
import { useContractMethods } from 'modules/Address/hooks/useContractMethods';
import { isReadMethod } from 'modules/Address/utils';

interface IUseReadMethods {
  methods: IAbiItem[];
  proxyAddress?: Address;
  isLoading: boolean;
}

export function useProxyReadMethods(chainId: TChainId): IUseReadMethods {
  const {
    proxyContractABI,
    proxyAddress,
    isProxyAddressLoading,
    isProxyContractAbiLoading,
  } = useContractMethods(chainId);

  const methods = proxyContractABI.filter(isReadMethod);

  return {
    methods,
    proxyAddress,
    isLoading: isProxyAddressLoading || isProxyContractAbiLoading,
  };
}
