import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

export const explorerRpc = createApi({
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: process.env.REACT_APP_API_URL,
      prepareHeaders: headers => {
        if (process.env.REACT_APP_API_KEY) {
          headers.set('X-api-key', process.env.REACT_APP_API_KEY);
        }
        return headers;
      },
    }),
    {
      maxRetries: 3,
    },
  ),
  endpoints: () => ({}),
});
