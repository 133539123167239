import React from 'react';
import { withSvgIcon } from './withSvgIcon';

export const QuestionIcon = withSvgIcon(
  <path
    fill="currentColor"
    fillRule="evenodd"
    d="M15 8A7 7 0 111 8a7 7 0 0114 0zm1 0A8 8 0 110 8a8 8 0 0116 0zm-9.083 3.997v-1.642h1.675v1.642H6.917zm-.149-5.162H5.2c.007-.384.071-.736.192-1.056.128-.32.302-.598.523-.832a2.36 2.36 0 01.81-.544c.32-.135.676-.203 1.067-.203.505 0 .924.071 1.259.213.341.135.615.306.821.512.206.207.352.43.437.672.093.235.139.455.139.662 0 .341-.046.622-.139.842a1.91 1.91 0 01-.33.566 2.147 2.147 0 01-.438.405 7.301 7.301 0 00-.448.33c-.142.107-.27.232-.384.374-.106.142-.174.32-.202.533v.406h-1.44v-.48a2.27 2.27 0 01.17-.768 2.556 2.556 0 01.747-.907c.142-.107.274-.213.395-.32.12-.107.217-.224.288-.352a.844.844 0 00.106-.48c0-.327-.081-.569-.245-.725-.156-.157-.377-.235-.661-.235-.192 0-.36.04-.502.117a.98.98 0 00-.341.299c-.085.12-.15.267-.192.437a2.109 2.109 0 00-.064.534z"
    clipRule="evenodd"
  />,
  { viewBox: '0 0 16 16' },
);
