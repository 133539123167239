import { ButtonBase, Container } from '@material-ui/core';
import { ExternalLinkIcon } from 'uiKit/Icons';
import { SocialLink } from './SocialLink';
import { useNeuraFooterStyles } from './useNeuraFooterStyles';

const VERIFIER_LINK = 'https://sourcify.neura-dev.ankr.network/#/verifier';
const TWITTER_LINK = 'https://twitter.com/ankr';
const TELEGRAM_LINK = 'https://t.me/ankrnetwork';
const DISCORD_LINK = 'https://discord.ankr.com/';

const currentYear = new Date().getFullYear();

export function NeuraFooter(): JSX.Element {
  const classes = useNeuraFooterStyles();

  return (
    <footer className={classes.root}>
      <Container className={classes.container} maxWidth={false}>
        <div>{`©${currentYear}. Neura All rights reserved`}</div>

        <ButtonBase
          component="a"
          className={classes.link}
          href={VERIFIER_LINK}
          rel="noreferrer"
          target="_blank"
          disableRipple
        >
          {'Contract Verifier'}

          <ExternalLinkIcon className={classes.linkIcon} />
        </ButtonBase>

        <div className={classes.social}>
          <SocialLink href={DISCORD_LINK} type="discord" />

          <SocialLink href={TELEGRAM_LINK} type="telegram" />

          <SocialLink href={TWITTER_LINK} type="twitter" />
        </div>
      </Container>
    </footer>
  );
}
