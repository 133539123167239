import { IAbiItem } from 'api/getContractAbi';

/**
 * Filter write methods
 *
 * @param item ABI item
 * @returns `true` if item is a write method
 *
 * @example
 * const writeMethods = abi.filter(isWriteMethod);
 */
export function isWriteMethod(item: IAbiItem): boolean {
  return (
    item.type === 'function' &&
    (item.stateMutability === 'payable' ||
      item.stateMutability === 'nonpayable')
  );
}
