import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useCellStyles = makeStyles<
  Theme,
  { defense?: boolean; paddingCollapse?: boolean; on?: boolean },
  'cell' | 'headCell' | 'centerCell' | 'leftCell' | 'rightCell' | 'content'
>(theme =>
  createStyles({
    cell: {
      display: 'inline-grid',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      fontWeight: 400,
      textAlign: 'left',
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
      '&$centerCell': {
        textAlign: 'center',
      },
      '&$leftCell': {
        textAlign: 'left',
      },
      '&$rightCell': {
        textAlign: 'right',
      },
    },

    headCell: {
      paddingTop: props =>
        props.defense ? theme.spacing(0.75) : theme.spacing(2.5),
      paddingBottom: props =>
        props.defense ? theme.spacing(0.75) : theme.spacing(2.5),
      boxSizing: 'border-box',
      fontSize: 12,
      lineHeight: 1.5,
      color: theme.palette.text.secondary,
      fontWeight: 500,
    },

    centerCell: {},
    leftCell: {},
    rightCell: {},
    content: {
      fontSize: 13,
      width: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  }),
);
