import { ITransaction } from 'common/types';
import { IS_EMBEDDED, CUSTOM_CHAINS } from '../common/const';
import { explorerRpc } from './explorerRpc';
import { IApiBlockchainName, IApiNftTransaction } from './types';
import { buildRpcRequest } from './utils/buildRpcRequest';

interface IRequestParams {
  blockchain?: IApiBlockchainName;
  walletAddress: string;
  pageToken?: string;
  pageSize?: number;
}

type ITransactionsQueryV2 = {
  lastTransaction?: ITransaction; //TODO: make separate type for nftTransfers
  items?: ITransaction[]; //TODO: make separate type for nftTransfers
  nextPageToken?: string;
};

interface GetAddressNftTransfersReply {
  result?: {
    transfers: IApiNftTransaction[];
    nextPageToken: string;
  };
}

export const { useGetNftTransfersByAddressQuery } = explorerRpc.injectEndpoints(
  {
    endpoints: build => ({
      getNftTransfersByAddress: build.query<
        ITransactionsQueryV2,
        IRequestParams
      >({
        query: ({ walletAddress, blockchain, pageToken, pageSize = 10 }) =>
          buildRpcRequest({
            method: 'explorer_getAddressNftTransfers',
            params: {
              walletAddress,
              blockchain:
                blockchain || (IS_EMBEDDED ? CUSTOM_CHAINS : undefined),
              pageToken,
              pageSize,
            },
          }),

        transformResponse: (data: GetAddressNftTransfersReply) => {
          return {
            items:
              data.result?.transfers.map(t => {
                return {
                  valueFormatted: t.value,

                  blockchainVerboseName: 'TODO',
                  fromAddressIsContract: false,
                  toAddressIsContract: false,
                  valueDecimals: 0,
                  valueHumanReadable: t.value,
                  valueSymbol: t.collectionSymbol,
                  method: 'TODO',
                  methodName: 'TODO',

                  ...t,
                  logo: t.imageUrl,
                  blockchainLogo: '',
                  id: t.transactionHash,
                  blockchainName: t.blockchainName,
                  blockHeight: t.blockHeight.toString(),
                  fromAddressName: t.fromAddressName || t.fromAddress,
                  toAddressName: t.toAddressName || t.toAddress,
                };
              }) || [],
            nextPageToken: data.result?.nextPageToken,
          };
        },
      }),
    }),
    overrideExisting: false,
  },
);
