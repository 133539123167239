import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useBlockchainIconStyles = makeStyles<Theme>(() => ({
  icon: {
    display: 'inline-block',
    width: '1em',
    height: '1em',
  },
}));
