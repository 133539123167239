import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useTransactionsTableCellStyles = makeStyles<Theme>(theme => ({
  table: {
    borderTopColor: 'transparent',
    borderRadius: 0,
  },
  txTable: {
    borderTopColor: 'transparent',
    borderLeft: 'none',
    borderRight: 'none',
    borderRadius: 0,
  },
  head: {
    backgroundColor: '#F2F5FA',
    padding: 0,
    margin: '0 20px',
    borderRadius: 20,
    borderBottom: 'none',
  },
  headCell: {
    paddingTop: 16,
    paddingBottom: 16,
    fontWeight: 'bold',
    '&:first-child': {
      paddingLeft: 40,
    },
    '&:last-child': {
      paddingRight: 16,
    },
  },
  iconNft: {
    display: 'inline-block',
    width: 52,
    height: 52,
    borderRadius: 8,
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tableCell: {
    fontSize: 14,
  },
  tableCellRootMethodName: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  tableCellMethodName: {
    alignItems: 'center',
    padding: '4px 10px',
    backgroundColor: '#F2F5FA',
    color: 'rgba(76, 93, 121, .5)',
    height: 24,
    borderRadius: 4,
    fontSize: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tableCellToken: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    fontSize: 14,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  hashCell: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateAreas: `'value icon'`,
    gridTemplateColumns: '1fr auto',
    gap: theme.spacing(0, 1.5),
    [theme.breakpoints.up('md')]: {
      gridTemplateAreas: `'icon value'`,
      gridTemplateColumns: 'auto 1fr',
    },
  },
  hashWrap: {
    gridArea: 'value',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    gridArea: 'icon',
    width: '1em',
    height: '1em',
    fontSize: 28,
  },
  link: {
    lineHeight: '14px',
  },
  emptyCellMdash: {
    display: 'inline-block',
    width: '100%',
  },
}));
