import { TChainId } from 'api/types';
import { web3Api } from 'api/web3Api';
import { getProxyContractAddress, getReadProvider } from 'api/web3Sdk';
import { ACTION_CACHE_LONG } from 'common/const';
import { Address } from 'common/types';
import { RootState } from 'store';
import { getContractAbiSelector } from './getContractAbi';

interface IContractAbiArgs {
  chainId: TChainId;
  address: Address;
}

export const { useGetProxyContractAddressQuery } = web3Api.injectEndpoints({
  endpoints: build => ({
    getProxyContractAddress: build.query<Address | undefined, IContractAbiArgs>(
      {
        queryFn: async ({ address, chainId }, { getState }) => {
          const state = getState() as RootState;
          const select = getContractAbiSelector({ address, chainId });
          const { data: abi } = select(state);

          if (!abi) {
            throw new Error('ABI not found');
          }

          const { web3 } = getReadProvider(chainId);

          try {
            return { data: await getProxyContractAddress(web3, address) };
          } catch (error) {
            return { data: undefined };
          }
        },
        keepUnusedDataFor: ACTION_CACHE_LONG,
      },
    ),
  }),
});
