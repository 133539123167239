import { alpha, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useTableRowStyles = makeStyles<Theme>(theme => ({
  row: {
    listStyle: 'none',
    margin: 0,
    padding: theme.spacing(1.5, 0),
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,

    [theme.breakpoints.up('md')]: {
      display: 'contents',
      border: 'none',
      padding: 0,
    },

    '&:last-of-type': {
      border: 'none',
    },

    '&:last-child': {
      '&>[role=cell]': {
        border: 'none',
      },
    },
  },

  withoutLastBorder: {
    '&:last-of-type li': {
      border: 'none',
    },
  },
  cell: {},
  rowHovered: {
    position: 'relative',
    textDecoration: 'none',
    '&:hover $cell:first-child::after': {
      height: '100%',
    },
  },
}));
