import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Paper, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useGetBlockchainStatsQuery } from 'api/getBlockchainStats';
import { IApiBlockchainName } from 'api/types';
import classNames from 'classnames';
import { normalizeBigNumber } from 'common/utils/format';
import { t } from 'common/utils/intl';
import { TableBodyCell, TableRow } from 'components/TableComponents';
import { TableView } from 'components/TableView/TableView';
import { BlockRoutesConfig } from '../router/const';
import { useBlockchainStatsStyles } from './BlockchainStatsStyles';

interface IBlockchainStatsProps {
  blockchain: IApiBlockchainName;
}

const captions = [
  { key: 'blockTimeMs', label: 'Block time (ms)' },
  { key: 'latestBlockNumber', label: 'Latest block number' },
  { key: 'nativeCoinUsdPrice', label: 'Native coin price (USD)' },
  { key: 'totalEventsCount', label: 'Total events count' },
  { key: 'totalTransactionsCount', label: 'Total transactions count' },
];

const customCell = '1fr 1fr 1fr 1fr 1fr';

export const BlockchainStats: React.FC<IBlockchainStatsProps> = ({
  blockchain,
}) => {
  const classes = useBlockchainStatsStyles();
  const { data, isLoading, error } = useGetBlockchainStatsQuery({ blockchain });

  const currentChainStats = data?.find(i => i.blockchain === blockchain);

  if ((!currentChainStats && !isLoading) || error) {
    toast.error(t('errorMessages.statsError'), { toastId: blockchain });
    return null;
  }

  const renderStatistics = () => {
    if (!currentChainStats) {
      return t('errorMessages.statsError');
    }

    const {
      blockTimeMs,
      latestBlockNumber,
      nativeCoinUsdPrice,
      totalEventsCount,
      totalTransactionsCount,
    } = currentChainStats;
    return (
      <>
        <TableBodyCell className={classes.tableCell} label={captions[0].label}>
          {normalizeBigNumber(blockTimeMs)}
        </TableBodyCell>
        <TableBodyCell className={classes.tableCell} label={captions[1].label}>
          <Link
            to={BlockRoutesConfig.blockDetails.generatePath(
              blockchain,
              latestBlockNumber.toString(),
            )}
          >
            {latestBlockNumber}
          </Link>
        </TableBodyCell>
        <TableBodyCell className={classes.tableCell} label={captions[2].label}>
          {t('unitUSD', {
            value: nativeCoinUsdPrice,
          })}
        </TableBodyCell>
        <TableBodyCell className={classes.tableCell} label={captions[3].label}>
          {normalizeBigNumber(totalEventsCount)}
        </TableBodyCell>
        <TableBodyCell className={classes.tableCell} label={captions[4].label}>
          {totalTransactionsCount
            ? normalizeBigNumber(totalTransactionsCount)
            : t('errorMessages.noInformation')}
        </TableBodyCell>
      </>
    );
  };

  const renderSkeleton = captions.map(({ label, key }) => (
    <TableBodyCell className={classes.tableCell} label={label} key={key}>
      <Box display="flex" justifyContent={{ xs: 'flex-end', md: 'flex-start' }}>
        <Skeleton variant="text" width="40%" />
      </Box>
    </TableBodyCell>
  ));

  return (
    <>
      <Typography variant="h2" className={classes.title}>
        {t('statistics.title')}
      </Typography>
      <Paper className={classes.paper}>
        <TableView
          tableGridTemplateColumns={customCell}
          transfersCaptions={captions}
          className={classNames(classes.table)}
        >
          <TableRow>{isLoading ? renderSkeleton : renderStatistics()}</TableRow>
        </TableView>
      </Paper>
    </>
  );
};
