import { makeStyles } from '@material-ui/core';

export const useMethodContentFormStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'grid',
    gap: theme.spacing(2),
  },

  resultError: {
    color: theme.palette.error.main,
  },
}));
