import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ROOT_PATH } from 'common/const';
import { Address } from 'modules/Address';
import { AddressResolver } from 'modules/AddressResolver/AddressResolver';
import { Block } from 'modules/Block';
import { Blocks, BlocksByHeight } from 'modules/Blocks';
import { ChainDetails } from 'modules/ChainDetails';
import { Lobby } from 'modules/Lobby';
import { Transaction } from 'modules/Transaction';
import { TransactionRedirect } from 'modules/Transaction/TransactionRedirect';
import { Transactions, TransactionsByBlock } from 'modules/Transactions';
import { TransactionsByHash } from 'modules/Transactions/TransactionsByHash';
import {
  AddressRoutesConfig,
  BlockRoutesConfig,
  ChainRoutesConfig,
  PATH,
  TransactionsRedirectRouteConfig,
  TransactionsRouteConfig,
} from '../const';
import { PageNotFound } from './PageNotFound/PageNotFound';

function MainRoutes() {
  return (
    <BrowserRouter basename={ROOT_PATH}>
      <Routes>
        <Route path={PATH.HOME} element={<Lobby />} />

        <Route
          path={ChainRoutesConfig.chainDetails.path}
          element={<ChainDetails />}
        />

        <Route path={AddressRoutesConfig.address.path} element={<Address />} />

        <Route
          path={AddressRoutesConfig.addressResolver.path}
          element={<AddressResolver />}
        />

        <Route
          path={AddressRoutesConfig.addressTransactions.path}
          element={
            <Navigate
              replace={true}
              to={AddressRoutesConfig.addressTransactions.redirectPath}
            />
          }
        />

        <Route
          path={AddressRoutesConfig.addressTransfersMoney.path}
          element={
            <Navigate
              replace={true}
              to={AddressRoutesConfig.addressTransfersMoney.redirectPath}
            />
          }
        />

        <Route
          path={AddressRoutesConfig.addressTransfersNft.path}
          element={
            <Navigate
              replace={true}
              to={AddressRoutesConfig.addressTransfersNft.redirectPath}
            />
          }
        />

        <Route
          path={AddressRoutesConfig.addressTokenRedirect.path}
          element={
            <Navigate
              replace={true}
              to={AddressRoutesConfig.addressTokenRedirect.redirectPath}
            />
          }
        />

        <Route
          path={TransactionsRouteConfig.transactionDetails.path}
          element={<Transaction />}
        />

        <Route
          path={TransactionsRouteConfig.transactions.path}
          element={<Transactions />}
        />

        <Route
          path={TransactionsRouteConfig.transactionsByBlock.path}
          element={<TransactionsByBlock />}
        />

        <Route
          path={TransactionsRouteConfig.transactionsByHash.path}
          element={<TransactionsByHash />}
        />

        <Route
          path={TransactionsRedirectRouteConfig.transactionsByHash.path}
          element={<TransactionRedirect />}
        />

        <Route path={BlockRoutesConfig.blocks.path} element={<Blocks />} />

        <Route path={BlockRoutesConfig.blockDetails.path} element={<Block />} />

        <Route
          path={BlockRoutesConfig.blocksByHeight.path}
          element={<BlocksByHeight />}
        />

        <Route path={''} element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export { MainRoutes };
