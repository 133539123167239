import React from 'react';
import classNames from 'classnames';
import { t } from 'common/utils/intl';
import { Button } from 'uiKit/Button';
import { ReactComponent as NextIcon } from './assets/next.svg';
import { ReactComponent as PrevIcon } from './assets/prev.svg';
import { usePaginationStyles } from './PaginationStyles';

type ClickEventType = React.MouseEvent<HTMLButtonElement>;

export interface IPaginationProps {
  page: number;
  hasNextPage: boolean;
  onChange: (page: number, event: ClickEventType) => void;
  disabled?: boolean;
  pagesCount?: number;
}

export const Pagination = ({
  hasNextPage,
  onChange,
  page,
  disabled,
  pagesCount,
}: IPaginationProps) => {
  const classes = usePaginationStyles();

  const isFirstBtnDisabled = page === 0 || disabled;
  const isPrevBtnDisabled = isFirstBtnDisabled;
  const isNextBtnDisabled = !hasNextPage || disabled;

  const onFirstClick = (event: ClickEventType) => {
    if (isFirstBtnDisabled) {
      return;
    }
    onChange(0, event);
  };

  const onPrevClick = (event: ClickEventType) => {
    if (isPrevBtnDisabled) {
      return;
    }
    onChange(page - 1, event);
  };

  const onNextClick = (event: ClickEventType) => {
    if (isNextBtnDisabled) {
      return;
    }
    onChange(page + 1, event);
  };

  const pagesCountText = pagesCount
    ? `${t('pagination.page')} ${page + 1} of ${pagesCount}`
    : `${t('pagination.page')} ${page + 1}`;

  return (
    <nav className={classes.root}>
      <div className={classes.list}>
        <Button
          className={classNames(classes.btn, classes.btnFirst)}
          variant="outlined"
          classes={{ label: classes.btnLabel }}
          onClick={onFirstClick}
          disabled={isFirstBtnDisabled}
        >
          {t('pagination.first')}
        </Button>

        <Button
          className={classNames(classes.btn, classes.btnPrev)}
          variant="outlined"
          onClick={onPrevClick}
          disabled={isPrevBtnDisabled}
        >
          <PrevIcon />
        </Button>

        <Button
          className={classNames(classes.btn, classes.btnTotal)}
          variant="outlined"
          disabled
        >
          {pagesCountText}
        </Button>

        <Button
          className={classNames(classes.btn, classes.btnNext)}
          variant="outlined"
          onClick={onNextClick}
          disabled={isNextBtnDisabled}
        >
          <NextIcon />
        </Button>
      </div>
    </nav>
  );
};
