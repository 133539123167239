import React, { createContext, CSSProperties, ReactNode } from 'react';
import { Paper, WithStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { ICustomProps, IStyleProps } from '../types';
import { tableStyles } from './TableStyles';

export type TableContextType = {
  count: number;
} & Pick<
  ITableComponentProps,
  | 'defense'
  | 'paddingCollapse'
  | 'customCell'
  | 'alignCell'
  | 'stickyHeader'
  | 'withFooter'
>;

export const TableContext = createContext<TableContextType>({
  count: 0,
});

interface ITableComponentProps
  extends WithStyles<typeof tableStyles>,
    ICustomProps,
    IStyleProps {
  className?: string;
  columnsCount: number;
  children: ReactNode;
  withFooter?: boolean;
  style?: CSSProperties;
}

const TableComponent = ({
  className,
  children,
  classes,
  withFooter,
}: ITableComponentProps) => {
  return (
    <Paper
      variant="outlined"
      className={classNames(
        classes.container,
        className,
        withFooter && classes.withFooter,
      )}
    >
      <div className={classes.table} role="grid">
        {children}
      </div>
    </Paper>
  );
};

export type ITableProps = ITableComponentProps;

export const Table = withStyles(tableStyles)((props: ITableProps) => {
  return (
    <TableContext.Provider
      value={{
        defense: props.defense,
        paddingCollapse: props.paddingCollapse,
        customCell: props.customCell,
        alignCell: props.alignCell,
        count: props.columnsCount,
        stickyHeader: props.stickyHeader,
        withFooter: props.withFooter,
      }}
    >
      <TableComponent {...props} />
    </TableContext.Provider>
  );
});
