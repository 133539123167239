import { INft } from 'common/types';
import { IBlockchainForAddressInfo } from 'components/Blockchains/types';
import { useAddressData } from '../TransfersTable/useAddressData';

export const useNftsTable = (blockchain?: IBlockchainForAddressInfo) => {
  const { items, loading, isFetching, error, loadMore, hasNextPage, refetch } =
    useAddressData<INft>({
      blockchain,
      type: 'nftAssets',
    });

  return {
    error,
    loadData: refetch,
    visibleItems: items,
    loading,
    isFetching,
    loadNext: loadMore,
    hasNextPage,
  };
};
