import { useMemo, useState } from 'react';
import { IBalanceMap } from 'common/types';
import { IS_EMBEDDED } from '../../../../common/const';

const BALANCES_FILTER = ({ usdValue, tokenType }: IBalanceMap) =>
  usdValue !== 0 || tokenType === 'NATIVE';

export interface IGroup {
  [blockChainName: string]: IBalanceMap[];
}

export const useBalances = (balances?: IBalanceMap[] | null) => {
  const [isOpened, setOpened] = useState(IS_EMBEDDED);
  const [hideZeroBalances, setHideZeroBalances] = useState(true);
  const availableBalances = useMemo(() => {
    if (!balances) {
      return [];
    }

    if (hideZeroBalances) {
      return balances.filter(BALANCES_FILTER);
    }

    return balances;
  }, [hideZeroBalances, balances]);

  const toggleBalancesVisibility = () => {
    setOpened(!isOpened);
  };

  function onChangeHideMode() {
    setHideZeroBalances(!hideZeroBalances);
  }

  const totalBalance = useMemo(
    () =>
      availableBalances
        ? availableBalances.reduce((acc, item) => acc + item.usdValue, 0)
        : 0,
    [availableBalances],
  );

  const groups = useMemo(() => {
    if (!availableBalances) {
      return {};
    }
    const groups: IGroup = {};

    availableBalances.forEach(item => {
      if (!groups[item.blockchainName]) {
        groups[item.blockchainName] = [];
      }

      groups[item.blockchainName].push(item);
    });

    return groups;
  }, [availableBalances]);

  const amountHidden = balances
    ? balances.length - availableBalances.length
    : 0;

  const balancesSortedArray = Object.keys(groups).reduce(
    (previousValue: IBalanceMap[], currentValue: string) => {
      return [...previousValue, ...groups[currentValue]];
    },
    [],
  );

  return {
    totalBalance,
    hideZeroBalances,
    onChangeHideMode,
    amountHidden,
    balancesToRender: balancesSortedArray,
    isOpened,
    toggleBalancesVisibility,
  };
};
