import { IAbiInput } from 'api/getContractAbi';
import { TChainId } from 'api/types';
import { Address } from 'common/types';
import { useSendWriteRequestMutation } from 'modules/Address/actions/sendWriteRequest';
import { AddressRoutesConfig } from 'modules/router/const';
import { MethodForm, TMethodFormSubmit } from '../MethodForm';

interface IWriteMethodFormProps {
  methodName: string;
  inputs: IAbiInput[];
  outputs: IAbiInput[];
  chainId: TChainId;
  proxyAddress?: Address;
}

export function WriteMethodForm({
  methodName,
  inputs,
  outputs,
  chainId,
  proxyAddress,
}: IWriteMethodFormProps): JSX.Element {
  const { address } = AddressRoutesConfig.address.useParams();

  const [sendWriteRequest, { data: result, isLoading }] =
    useSendWriteRequestMutation({
      fixedCacheKey: methodName,
    });

  const { data, isError } = result || {};

  const handleSubmit: TMethodFormSubmit = data => {
    const methodArgs = inputs.map(({ name }) => data[name]);
    sendWriteRequest({
      chainId,
      address,
      methodName,
      methodArgs,
      proxyAddress,
    });
  };

  return (
    <MethodForm
      name={methodName}
      inputs={inputs}
      outputs={outputs}
      onSubmit={handleSubmit}
      result={data ? `${data}` : undefined}
      isError={isError}
      isLoading={isLoading}
      submitLabel="Write"
    />
  );
}
