import React from 'react';
import { uid } from 'react-uid';
import { addressTransactionsCaptions } from 'common/const';
import { ITransaction } from 'common/types';
import { getTimePassed } from 'common/utils/getTimePassed';
import { t } from 'common/utils/intl';
import {
  TableBodyCell,
  TableCellAddress,
  TableCellHash,
  TableCellMethodName,
  TableRow,
} from 'components/TableComponents';
import { TransactionDirection } from 'components/TransactionDirection/TransactionDirection';
import { useTransactionsTableCellStyles } from 'modules/Transactions/components/TransactionsUI/TransactionsTableCellStyles';
import { getPlains } from './TransactionsTableUtils';

interface ITransferTableTransactionsRowsProps {
  address: string;
  items: ITransaction[];
}

export const TransactionsTableRows = ({
  address,
  items,
}: ITransferTableTransactionsRowsProps) => {
  const classes = useTransactionsTableCellStyles();

  return (
    <>
      {items?.map((tx, i) => {
        const {
          blockchainName,
          blockchainLogo,
          transactionHash,
          blockHeight,
          fromAddress,
          fromAddressName,
          toAddress,
          toAddressName,
          valueFormatted,
          timestamp,
          methodName,
          valueSymbol,
          direction,
          fromAddressIsContract,
          toAddressIsContract,
          success,
        } = tx;

        const { toPlainText, fromPlainText } = getPlains(direction);

        return (
          <TableRow key={uid(transactionHash, i)}>
            <TableCellHash
              label={addressTransactionsCaptions[0].label}
              blockchainName={blockchainName}
              blockchainLogo={blockchainLogo}
              transactionHash={transactionHash}
              success={success}
            />

            <TableCellMethodName
              label={addressTransactionsCaptions[1].label}
              methodName={methodName}
            />

            <TableBodyCell
              className={classes.tableCell}
              label={addressTransactionsCaptions[2].label}
            >
              {blockHeight}
            </TableBodyCell>

            <TableBodyCell
              className={classes.tableCell}
              label={addressTransactionsCaptions[3].label}
            >
              {getTimePassed(timestamp)}
            </TableBodyCell>

            <TableCellAddress
              label={addressTransactionsCaptions[4].label}
              address={fromAddress}
              addressName={fromAddressName}
              isContract={fromAddressIsContract}
              plainText={fromPlainText}
            />

            <TableBodyCell
              className={classes.tableCell}
              label={addressTransactionsCaptions[5].label}
            >
              <TransactionDirection
                address={address}
                fromAddress={fromAddress}
                toAddress={toAddress}
                direction={direction}
              />
            </TableBodyCell>

            <TableCellAddress
              label={addressTransactionsCaptions[6].label}
              address={toAddress}
              addressName={toAddressName}
              isContract={toAddressIsContract}
              plainText={toPlainText}
            />

            <TableBodyCell
              className={classes.tableCell}
              label={addressTransactionsCaptions[7].label}
            >
              {t('unitToken', {
                value: valueFormatted,
                unit: valueSymbol || blockchainName,
              })}
            </TableBodyCell>
          </TableRow>
        );
      })}
    </>
  );
};
