import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { IApiBlockchainName } from 'api/types';
import { useBlockchainName } from 'common/hooks/useBlockchainName';
import { IBaseTransaction, IEthTxDetailsMap } from 'common/types';
import { t } from 'common/utils/intl';
import { BlockchainIcon } from 'components/BlockchainIcon';
import { CopyAddress } from 'components/CopyAddress';
import {
  Table,
  TableBody,
  TableBodyCell,
  TableRow,
} from 'components/TableComponents';
import { QuestionIcon } from 'uiKit/Icons/QuestionIcon';
import { Tooltip } from 'uiKit/Tooltip/Tooltip';
import { AddressRoutesConfig, BlockRoutesConfig } from '../../../router/const';
import { useTxActualTimePassed } from '../hooks';
import { Status } from '../Status';
import { useTransactionUIStyles } from '../TransactionUI/TransactionUIStyles';

const PERCENT_PRECISION = 100;

interface IEthDetailsProps {
  blockchainName: IApiBlockchainName;
  data: IEthTxDetailsMap;
  common: IBaseTransaction;
}

export const EthDetails = ({
  blockchainName,
  data,
  common,
}: IEthDetailsProps) => {
  const classes = useTransactionUIStyles();
  const {
    gasUsed,
    gasLimit,
    timestamp,
    transactionHash,
    status,
    blockHeight,
    fromAddress,
    toAddress,
    valueHumanReadable,
    transactionFee,
    gasPrice,
    nonce,
  } = data;

  const { valueSymbol } = common;
  const chainName = useBlockchainName(blockchainName);
  const gasUsedPercentage = useMemo(() => {
    return (
      Math.round(
        (+gasUsed.integerValue() / +gasLimit.integerValue()) *
          100 *
          PERCENT_PRECISION,
      ) / PERCENT_PRECISION
    );
  }, [data]);

  const { formattedDate, actualTimePassed } = useTxActualTimePassed(timestamp);

  return (
    <Table columnsCount={2} customCell="0.3fr 0.7fr">
      <TableBody>
        <TableRow>
          <TableBodyCell>
            {t('transaction.ethDetails.blockchain')}
          </TableBodyCell>

          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <BlockchainIcon
                className={classes.blockchainIcon}
                type={blockchainName}
              />
              {chainName}
            </Box>
          </TableBodyCell>
        </TableRow>

        <TableRow>
          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <Tooltip
                title={t('transaction.ethDetails.txHashTooltip')}
                placement="top"
              >
                <QuestionIcon className={classes.questionIcon} />
              </Tooltip>
              {t('transaction.ethDetails.txHashLabel')}
            </Box>
          </TableBodyCell>

          <TableBodyCell>
            <CopyAddress
              address={transactionHash}
              className={classes.copyHash}
            />
          </TableBodyCell>
        </TableRow>

        <TableRow>
          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <Tooltip
                title={t('transaction.ethDetails.statusTooltip')}
                placement="top"
              >
                <QuestionIcon className={classes.questionIcon} />
              </Tooltip>
              {t('transaction.ethDetails.statusLabel')}
            </Box>
          </TableBodyCell>

          <TableBodyCell>
            <Status type={status} />
          </TableBodyCell>
        </TableRow>

        <TableRow>
          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <Tooltip
                title={t('transaction.ethDetails.blockTooltip')}
                placement="top"
              >
                <QuestionIcon className={classes.questionIcon} />
              </Tooltip>
              {t('transaction.ethDetails.blockLabel')}
            </Box>
          </TableBodyCell>

          <TableBodyCell>
            <Link
              to={BlockRoutesConfig.blockDetails.generatePath(
                blockchainName,
                blockHeight,
              )}
            >
              {`#${blockHeight}`}
            </Link>
          </TableBodyCell>
        </TableRow>

        <TableRow>
          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <Tooltip
                title={t('transaction.ethDetails.timestampTooltip')}
                placement="top"
              >
                <QuestionIcon className={classes.questionIcon} />
              </Tooltip>
              {t('transaction.ethDetails.timestampLabel')}
            </Box>
          </TableBodyCell>

          <TableBodyCell>
            <span className={classes.breakWord}>
              {`${actualTimePassed} (${formattedDate})`}
            </span>
          </TableBodyCell>
        </TableRow>

        <TableRow>
          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <Tooltip
                title={t('transaction.ethDetails.fromTooltip')}
                placement="top"
              >
                <QuestionIcon className={classes.questionIcon} />
              </Tooltip>
              {t('transaction.ethDetails.fromLabel')}
            </Box>
          </TableBodyCell>

          <TableBodyCell>
            <Link to={AddressRoutesConfig.address.generatePath(fromAddress)}>
              {fromAddress}
            </Link>
          </TableBodyCell>
        </TableRow>

        <TableRow>
          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <Tooltip
                title={t('transaction.ethDetails.toTooltip')}
                placement="top"
              >
                <QuestionIcon className={classes.questionIcon} />
              </Tooltip>
              {t('transaction.ethDetails.toLabel')}
            </Box>
          </TableBodyCell>

          <TableBodyCell>
            <Link to={AddressRoutesConfig.address.generatePath(toAddress)}>
              {toAddress}
            </Link>
          </TableBodyCell>
        </TableRow>

        <TableRow>
          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <Tooltip
                title={t('transaction.ethDetails.valueTooltip')}
                placement="top"
              >
                <QuestionIcon className={classes.questionIcon} />
              </Tooltip>
              {t('transaction.ethDetails.valueLabel')}
            </Box>
          </TableBodyCell>

          <TableBodyCell>
            {`${valueHumanReadable} ${valueSymbol}`}
          </TableBodyCell>
        </TableRow>

        <TableRow>
          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <Tooltip
                title={t('transaction.ethDetails.txFeeTooltip')}
                placement="top"
              >
                <QuestionIcon className={classes.questionIcon} />
              </Tooltip>
              {t('transaction.ethDetails.txFeeLabel')}
            </Box>
          </TableBodyCell>

          <TableBodyCell>
            {`${transactionFee.toFormat()} ${valueSymbol}`}
          </TableBodyCell>
        </TableRow>

        <TableRow>
          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <Tooltip
                title={t('transaction.ethDetails.gasPriceTooltip')}
                placement="top"
              >
                <QuestionIcon className={classes.questionIcon} />
              </Tooltip>
              {t('transaction.ethDetails.gasPriceLabel')}
            </Box>
          </TableBodyCell>

          <TableBodyCell>
            {`${gasPrice.toFormat()} ${valueSymbol}`}
          </TableBodyCell>
        </TableRow>

        <TableRow>
          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <Tooltip
                title={t('transaction.ethDetails.gasUsedTooltip')}
                placement="top"
              >
                <QuestionIcon className={classes.questionIcon} />
              </Tooltip>
              {t('transaction.ethDetails.gasUsedLabel')}
            </Box>
          </TableBodyCell>

          <TableBodyCell>
            {`${gasUsed.toFormat()} (${gasUsedPercentage}%)`}
          </TableBodyCell>
        </TableRow>

        <TableRow>
          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <Tooltip
                title={t('transaction.ethDetails.nonceTooltip')}
                placement="top"
              >
                <QuestionIcon className={classes.questionIcon} />
              </Tooltip>
              {t('transaction.ethDetails.nonceLabel')}
            </Box>
          </TableBodyCell>

          <TableBodyCell>{nonce}</TableBodyCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
