import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useCopyAddressStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    transition: 'color 0.2s',
    cursor: 'pointer',

    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '&:hover': {
      background: 'none',
      color: theme.palette.primary.dark,
    },

    '& span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },

  copyIcon: {
    width: '1em',
    height: '1em',
    fontSize: 16,
    marginLeft: theme.spacing(0.5),
    flexShrink: 0,
  },

  copyText: {
    fontSize: 14,
    alignSelf: 'center',
    margin: theme.spacing(0, 0, 0, 0.5),
  },
}));
