import { merge } from 'lodash';
import { IS_EMBEDDED } from '../const';
import { Locale } from '../types';
import localeUSChainscanner from './en-US.chainscanner.json';
import localeUS from './en-US.json';
import localeCN from './zh-CN.json';

export interface ILocaleData {
  value: Locale;
  name: string;
}

// locale data
const locales: ILocaleData[] = [
  {
    name: 'EN',
    value: Locale.en,
  },
  {
    name: '中文',
    value: Locale.zh,
  },
];

const LOCALES_DATA = {
  [Locale.en]: IS_EMBEDDED ? merge(localeUS, localeUSChainscanner) : localeUS,
  [Locale.zh]: localeCN,
};

export { locales, LOCALES_DATA };
