import { alpha, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FONTS } from 'common/themes/mainTheme';

export const useSupportedBlockchainsStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '20px',
  },
  value: {
    marginLeft: theme.spacing(1),
  },
  blockchainIcon: {
    width: 20,
    height: 20,
    backgroundColor: '#ffffff',
    borderRadius: '50%',
  },
  btn: {
    fontFamily: FONTS.primary,
    borderRadius: '12px',
    padding: '11px 14px',
    fontSize: '14px',
    lineHeight: '100%',
    margin: '0 10px 10px 0',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    color: theme.palette.text.secondary,
    fontWeight: 500,
    transition: 'background-color .3s',

    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      color: theme.palette.primary.main,
    },
    '&:focus, &:active, &:hover': {
      [theme.breakpoints.down('sm')]: {
        backgroundColor: theme.palette.primary.main,
        color: '#ffffff',
      },
    },
  },
  btnSelected: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    pointerEvents: 'none',
  },
}));
