import { makeStyles } from '@material-ui/core';

export const useInputStyles = makeStyles(theme => ({
  label: {
    transform: 'none',
    position: 'relative',
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
  },
}));
