export const searchFormat = (value?: string) => {
  if (value) {
    const lineBreaks = /\r?\n|\r/g;
    const whiteSpaces = /\s+/g;
    let result = value.replace(lineBreaks, '').replace(whiteSpaces, '');
    if (result.length > 0 && result[0] === '-') {
      result = result.substr(1);
    }
    return result;
  }
  return '';
};
