import { getWriteProvider } from '../getWriteProvider';

/**
 * Check if the current network is the expected one.
 *
 * @param expectedNetwork - expected network id
 * @returns Promise that resolves when the network is correct
 */
export async function checkNetwork(expectedNetwork: number): Promise<void> {
  const provider = await getWriteProvider();

  if (expectedNetwork === provider.chainId) {
    return;
  }

  await provider.switchNetwork(expectedNetwork);
}
