import { useMatch } from 'react-router';
import { Container } from '@material-ui/core';
import { ROOT_PATH as homePage } from 'common/const';
import { Search } from 'components/Search';
import { NavLink } from 'uiKit/NavLink';
import logo from './assets/neura-logo.png';
import logo2x from './assets/neura-logo@2x.png';
import { useNeuraHeaderStyles } from './useNeuraHeaderStyles';

export function NeuraHeader(): JSX.Element {
  const classes = useNeuraHeaderStyles();
  const match = useMatch(homePage);
  const isHomePage = !!match;

  return (
    <header className={classes.root}>
      <Container className={classes.container} maxWidth={false}>
        <NavLink className={classes.logo} href={homePage}>
          <img className={classes.logoImg} src={logo} srcSet={`${logo2x} 2x`} />
        </NavLink>

        {!isHomePage && <Search className={classes.search} />}
      </Container>
    </header>
  );
}
