import React, { ReactNode, useMemo } from 'react';
import { generateUID, uid } from 'react-uid';
import {
  FormControl,
  FormHelperText,
  InputBase,
  InputBaseProps,
  InputLabel,
} from '@material-ui/core';
import { getUniqueId } from 'common/utils/getUniqueId';
import { useInputStyles } from './useInputStyles';

interface IInputProps extends InputBaseProps {
  label?: ReactNode;
  errorText?: ReactNode;
}

export function Input({
  className,
  label,
  name,
  errorText,
  ...restProps
}: IInputProps): JSX.Element {
  const classes = useInputStyles();
  const inputId = useMemo(() => `input-${name}-${getUniqueId()}`, []);

  return (
    <FormControl variant="standard" className={className}>
      {label && (
        <InputLabel shrink className={classes.label} htmlFor={inputId}>
          {label}
        </InputLabel>
      )}

      <InputBase {...restProps} name={name} id={inputId} />

      {errorText && <FormHelperText error>{errorText}</FormHelperText>}
    </FormControl>
  );
}
