import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField } from '@material-ui/core';
import { isValidETHAddress } from 'common/utils/isValidETHAddress';
import { t } from '../../../common/utils/intl';
import {
  AddressRoutesConfig,
  BlockRoutesConfig,
  TransactionsRouteConfig,
} from '../../../modules/router/const';
import { SearchIcon } from '../../../uiKit/Icons/SearchIcon';
import { searchFormat } from '../utils';
import { useChainscannerSearchStyles } from './useChainscannerSearchStyles';

export const isInteger = (value: string) => {
  return /^\d+$/i.test(value);
};

export const isIntegerPositive = (value: string) => {
  return isInteger(value) && +value > 0;
};

export const isValidETHTransaction = (str: string): boolean => {
  return /^0x([A-Fa-f\d]{64})$/.test(str);
};

export const ChainscannerSearch = () => {
  const classes = useChainscannerSearchStyles();
  const navigate = useNavigate();

  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const v = searchFormat(value);
    if (isIntegerPositive(v)) {
      navigate(BlockRoutesConfig.blocksByHeight.generatePath(v));
    } else if (isValidETHTransaction(v)) {
      navigate(TransactionsRouteConfig.transactionsByHash.generatePath(v));
    } else if (isValidETHAddress(v)) {
      navigate(AddressRoutesConfig.address.generatePath(v));
    } else {
      setError(t('errorMessages.invalidSearchValue'));
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setError('');
  };

  return (
    <Box component="form" className={classes.root} onSubmit={handleSubmit}>
      <TextField
        type="text"
        value={value}
        placeholder="Search by Address / Txn Hash / Block"
        onChange={handleChange}
        className={classes.input}
        helperText={error}
        error={!!error}
      />
      <Button onClick={handleSubmit} className={classes.btn} type="submit">
        <SearchIcon />
      </Button>
    </Box>
  );
};
