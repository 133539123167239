import React from 'react';
import { t } from 'common/utils/intl';
import { ErrorMessage } from 'components/ErrorMessage';
import { BlocksPage } from './BlocksPage';
import { useBlocksByHeight } from './useBlocksByHeight';

export const BlocksByHeight = () => {
  const { error, loading, data, getBlocks } = useBlocksByHeight();

  const renderedError = error && (
    <ErrorMessage text={t('errorMessages.requestError')} onClick={getBlocks} />
  );

  return (
    <BlocksPage items={data?.items} isLoading={loading} error={renderedError} />
  );
};
