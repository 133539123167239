import React from 'react';
import { featureConfig } from 'common/featureConfig';
import { StyledTab } from 'components/StyledTab';
import { TabPanel } from 'components/TabPanel';
import { Balance } from '../../../Balance';
import { useAddressStyles } from '../../AddressStyles';
import { IAddressBlock } from '../../types';
import { NftsTable } from '../NftsTable';
import { Tabs } from '../Tabs';

export const AddressAssets = ({
  blockchain,
  tab,
  onTabChange,
}: IAddressBlock) => {
  const classes = useAddressStyles();

  return (
    <>
      <Tabs value={tab} onChange={onTabChange}>
        <StyledTab label="Assets" />

        <StyledTab isHidden={!featureConfig.showNFT} label="NFTs" />
      </Tabs>

      <TabPanel value={tab} index={0}>
        <Balance className={classes.balance} blockchain={blockchain} />
      </TabPanel>

      <TabPanel value={tab} index={1} className={classes.nfts}>
        <NftsTable blockchain={blockchain} />
      </TabPanel>
    </>
  );
};
