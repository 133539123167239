import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { ROWS_PER_PAGE_DEFAULT_COUNT } from './TableViewPaginationUtils';

export function useTableViewPagination<DataType>(data: DataType[] = []) {
  const [rowsPerPageCount, setRowsPerPageCount] = useState(
    ROWS_PER_PAGE_DEFAULT_COUNT,
  );
  const rows = useMemo(() => data, [data]);
  const transactionsCount = rows.length;
  const [pageIndex, setPageIndex] = useState<number>(0);

  const pageNumber = pageIndex + 1;
  const pagesCount = Math.ceil(transactionsCount / rowsPerPageCount);
  const isFirstPage = pageIndex === 0;
  const isLastPage = pageNumber === pagesCount;

  const handleChangePage = useCallback(
    (newPage: number) => {
      if (isFirstPage && newPage <= pageIndex) {
        return;
      }
      if (isLastPage && newPage >= pageIndex) {
        return;
      }
      setPageIndex(newPage);
    },
    [isFirstPage, isLastPage, pageIndex],
  );

  const rowsForCurrentPage = rows.slice(
    pageIndex * rowsPerPageCount,
    pageIndex * rowsPerPageCount + rowsPerPageCount,
  );

  useEffect(() => {
    // if data is changed checking the pages count and current page
    if (pagesCount !== 0 && pageIndex >= pagesCount) {
      setPageIndex(pagesCount - 1);
    }
  }, [rows]);

  useEffect(() => {
    // when switching to bigger count by page transactions
    // checking if current page index is not bigger than pages count with current per page amount
    // and setting last page if current page index is bigger then pages count
    if (pagesCount !== 0 && pageIndex >= pagesCount) {
      setPageIndex(pagesCount - 1);
    }
  }, [rowsPerPageCount]);

  const handlePerPageCountChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>): void => {
      setRowsPerPageCount(+event.target.value);
    },
    [],
  );

  const hasPagination = pagesCount > 1;

  return {
    isLastPage,
    pageIndex,
    pagesCount,
    handleChangePage,
    rowsForCurrentPage,
    rowsPerPageCount,
    handlePerPageCountChange,
    hasPagination,
    transactionsCount,
  };
}
