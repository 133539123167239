import { TChainId } from 'api/types';
import { web3Api } from 'api/web3Api';
import { checkNetwork, getWriteProvider } from 'api/web3Sdk';
import { Address } from 'common/types';
import { RootState } from 'store';
import { getContractAbiSelector } from './getContractAbi';

interface IWriteRequestArgs {
  chainId: TChainId;
  address: Address;
  proxyAddress?: Address;
  methodName: string;
  methodArgs?: any;
}

interface IWriteRequestData {
  data: unknown;
  isError: boolean;
}

export const { useSendWriteRequestMutation } = web3Api.injectEndpoints({
  endpoints: build => ({
    sendWriteRequest: build.mutation<IWriteRequestData, IWriteRequestArgs>({
      queryFn: async (
        { address, chainId, methodName, methodArgs, proxyAddress },
        { getState },
      ) => {
        const state = getState() as RootState;

        const select = getContractAbiSelector({
          address: proxyAddress ?? address,
          chainId,
        });

        const { data: abi } = select(state);

        if (!abi) {
          throw new Error('ABI not found');
        }

        try {
          await checkNetwork(chainId);
          const provider = await getWriteProvider();
          const contract = provider.createContract(abi, address);
          const tx = contract.methods[methodName].apply(null, methodArgs);

          const { status } = await tx.send({ from: provider.currentAccount });

          return {
            data: {
              isError: false,
              data: !!status,
            },
          };
        } catch (error) {
          return {
            data: {
              isError: true,
              data: error,
            },
          };
        }
      },
    }),
  }),
});
