import { REQUEST_ERROR_MESSAGE } from 'common/const';
import { customException } from 'common/utils/customException';
import { explorerRpc } from './explorerRpc';
import { IApiBlockchainName, IApiBlockchainStats } from './types';
import { buildRpcRequest } from './utils/buildRpcRequest';

interface IStatsMapped {
  blockTimeMs: number;
  blockchain: IApiBlockchainName;
  latestBlockNumber: number;
  nativeCoinUsdPrice: string;
  totalEventsCount: number;
  totalTransactionsCount: string;
}

type BlockchainStatsQuery = IStatsMapped[];

interface IBlockchainStatsParams {
  blockchain: IApiBlockchainName | IApiBlockchainName[];
}

interface IBlockchainStatsReply {
  result?: {
    stats: IApiBlockchainStats[];
  };
  error?: {
    code: number;
    message: string;
  };
}

export const { useGetBlockchainStatsQuery } = explorerRpc.injectEndpoints({
  endpoints: build => ({
    getBlockchainStats: build.query<
      BlockchainStatsQuery,
      IBlockchainStatsParams
    >({
      query: ({ blockchain }) =>
        buildRpcRequest({
          method: 'explorer_getBlockchainStats',
          params: {
            blockchain,
          },
        }),

      transformResponse: (data: IBlockchainStatsReply) => {
        if (data.error || !data.result?.stats) {
          throw customException({
            message: data.error?.message || REQUEST_ERROR_MESSAGE,
            code: data.error?.code,
          });
        }

        return data.result.stats;
      },
    }),
  }),
});
