import { OChainId } from 'api/types';

/**
 * RPC URLS for HTTP providers by network id.
 */
const RPC_URLS_BY_NETWORK = {
  [OChainId.ethereum]: 'https://rpc.ankr.com/eth',
  [OChainId.goerli]: 'https://rpc.ankr.com/eth_goerli',
  [OChainId.sepolia]: 'https://rpc.ankr.com/eth_sepolia',
  [OChainId.arbitrum]: 'https://rpc.ankr.com/arbitrum',
  [OChainId.arbitrumTestnet]: 'https://goerli-rollup.arbitrum.io/rpc',
  [OChainId.arbitrumSepoliaTestnet]: 'https://sepolia-rollup.arbitrum.io/rpc',
  [OChainId.arbitrumNova]: 'https://rpc.ankr.com/arbitrumnova',
  [OChainId.avalanche]: 'https://rpc.ankr.com/avalanche',
  [OChainId.avalancheTestnet]: 'https://rpc.ankr.com/avalanche_fuji',
  [OChainId.smartchain]: 'https://rpc.ankr.com/bsc',
  [OChainId.smartchainTestnet]: 'https://rpc.ankr.com/bsc_testnet_chapel',
  [OChainId.fantom]: 'https://rpc.ankr.com/fantom',
  [OChainId.fantomTestnet]: 'https://rpc.ankr.com/fantom_testnet',
  [OChainId.mumbai]: 'https://rpc.ankr.com/polygon_mumbai',
  [OChainId.polygon]: 'https://rpc.ankr.com/polygon',
  [OChainId.polygonZkEVM]: 'https://rpc.ankr.com/polygon_zkevm',
  [OChainId.polygonZkEVMTestnet]: 'https://rpc.ankr.com/polygon_zkevm_testnet',
  [OChainId.gnosis]: 'https://rpc.ankr.com/gnosis',
  [OChainId.chiado]: 'https://rpc.chiadochain.net/',
  [OChainId.sokol]: 'https://sokol.poa.network',
  [OChainId.xdc]: 'https://erpc.xinfin.network',
  [OChainId.xdcTestnet]: 'https://erpc.apothem.network',
  [OChainId.optimism]: 'https://rpc.ankr.com/optimism',
  [OChainId.optimismTestnet]: 'https://rpc.ankr.com/optimism_testnet',
  [OChainId.optimismSepolia]: 'https://rpc.ankr.com/optimism_sepolia',
  [OChainId.linea]: 'https://rpc.linea.build',
  [OChainId.lineaTestnet]: 'https://rpc.goerli.linea.build',
  [OChainId.holesky]: 'https://rpc.ankr.com/eth_holesky',
  [OChainId.opBNB]: 'https://opbnb-mainnet-rpc.bnbchain.org',
  [OChainId.opBNBTestnet]: 'https://opbnb-testnet-rpc.bnbchain.org',
  [OChainId.tron]: 'https://rpc.ankr.com/tron_jsonrpc',
  [OChainId.base]: 'https://rpc.ankr.com/base',
  [OChainId.baseTestnet]: 'https://rpc.ankr.com/base_sepolia',
  [OChainId.mantle]: 'https://rpc.ankr.com/mantle',
  [OChainId.mantleGoerli]: 'https://rpc.ankr.com/mantle_testnet',
  [OChainId.mantleSepolia]: 'https://rpc.ankr.com/mantle_sepolia',
  [OChainId.celo]: 'https://rpc.ankr.com/celo',
  [OChainId.blast]: 'https://rpc.ankr.com/blast',
  [OChainId.blastTestnet]: 'https://rpc.ankr.com/blast_testnet_sepolia',
  [OChainId.zkSync]: 'https://rpc.ankr.com/zksync_era',
  [OChainId.zkSyncSepolia]: 'https://rpc.ankr.com/zksync_era_sepolia',
  [OChainId.zkSyncGoerli]: 'https://rpc.ankr.com/zksync_era_testnet',
  [OChainId.moonbeam]: 'https://rpc.ankr.com/moonbeam',
  [OChainId.taikoTestnet]: 'https://rpc.ankr.com/taiko_katla',
  [OChainId.nervos]: 'https://rpc.ankr.com/nervos',
  [OChainId.tenet]: 'https://rpc.ankr.com/tenet_evm',
  [OChainId.rollux]: 'https://rpc.ankr.com/rollux',
  [OChainId.rolluxTestnet]: 'https://rpc.ankr.com/rollux_testnet',
  [OChainId.chiliz]: 'https://rpc.ankr.com/chiliz',
  [OChainId.flare]: 'https://rpc.ankr.com/flare',
  [OChainId.horizenEON]: 'https://rpc.ankr.com/horizen_eon',
  [OChainId.horizenEONTestnet]: 'https://rpc.ankr.com/horizen_gobi_testnet',
  [OChainId.syscoin]: 'https://rpc.ankr.com/syscoin',
  [OChainId.bitTorrent]: 'https://rpc.ankr.com/bttc',
  [OChainId.kava]: 'https://rpc.ankr.com/kava_evm',
  [OChainId.iotex]: 'https://rpc.ankr.com/iotex',
  [OChainId.harmony]: 'https://rpc.ankr.com/harmony',
  [OChainId.zetaChainTestnet]:
    'https://rpc.ankr.com/zetachain_evm_athens_testnet',
  [OChainId.scroll]: 'https://rpc.ankr.com/scroll',
  [OChainId.scrollTestnet]: 'https://rpc.ankr.com/scroll_sepolia_testnet',
  [OChainId.klaytn]: 'https://rpc.ankr.com/klaytn',
  [OChainId.klaytnTestnet]: 'https://rpc.ankr.com/klaytn_testnet',
  [OChainId.core]: 'https://rpc.ankr.com/core',
  [OChainId.mode]: 'https://mode.drpc.org',
  [OChainId.modeTestnet]: 'https://sepolia.mode.network',
  [OChainId.neuraDevnet]:
    'https://lon3-109-123-108-62.ankr.com/neura_devnet_full_http_1',
  [OChainId.neuraTestnet]:
    'https://dallas-maxihost-207-188-6-94.ankr.com/neura_testnet_full_http_1',
} as const;

type AvailableChainId = keyof typeof RPC_URLS_BY_NETWORK;

/**
 * Returns RPC URL by chainId. Throws an error if chainId is not found.
 *
 * @param {number} chainId Chain ID
 * @return {string} RPC URL
 */
export function getRpcUrl(chainId: number): string {
  const rpcUrl = RPC_URLS_BY_NETWORK[chainId as AvailableChainId];
  if (!rpcUrl) {
    throw new Error(`RPC URL not found for chainId: ${chainId}`);
  }
  return rpcUrl;
}
