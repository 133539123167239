import { alpha, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useTooltipStyles = makeStyles<Theme>(theme => ({
  light: {
    backgroundColor: theme.palette.common.white,
    color: alpha('#000', 0.87),
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },

  /* theme black */
  black: {
    backgroundColor: alpha('#000', 0.87),
    padding: theme.spacing(1.5),
    whiteSpace: 'normal',
    textAlign: 'center',
    fontSize: 13,
    maxWidth: 340,
  },
  blackArrow: {
    color: theme.palette.common.black,
  },
}));
