import { useCallback, useEffect } from 'react';
import { IApiBlockchainName } from '../../api/types';
import { IS_EMBEDDED } from '../../common/const';
import { featureConfig } from '../../common/featureConfig';
import { historyInstance } from '../../common/utils/historyInstance';
import {
  AddressRoutesConfig,
  BlockRoutesConfig,
  ChainRoutesConfig,
  TransactionsRouteConfig,
} from '../router/const';

type WidgetLinkType = 'home' | 'address' | 'block' | 'tx';

enum WidgetMessageType {
  HeightChange = 'heightChange',
  RouteChange = 'routeChange',
}

type WidgetRouteParams = {
  type: WidgetLinkType;
  value: string;
  network: string;
};

type WidgetMessage =
  | {
      type: WidgetMessageType.HeightChange;
      value: number;
    }
  | {
      type: WidgetMessageType.RouteChange;
      value: WidgetRouteParams;
    };

const getPathFromParams = ({ type, network, value }: WidgetRouteParams) => {
  if (type === 'address' && value)
    return AddressRoutesConfig.address.generatePath(value);

  if (type === 'block' && network && value)
    return BlockRoutesConfig.blockDetails.generatePath(
      network as IApiBlockchainName,
      value,
    );

  if (type === 'tx' && value && network)
    return TransactionsRouteConfig.transactionDetails.generatePath(
      network as IApiBlockchainName,
      value,
    );

  if (network)
    return ChainRoutesConfig.chainDetails.generatePath(
      network as IApiBlockchainName,
    );

  return '/';
};

const sendMessage = (data: WidgetMessage) => {
  if (window.parent) {
    window.parent.postMessage(data, '*');
  }
};

const parseSearchParams = (
  searchParams: URLSearchParams,
): WidgetRouteParams => {
  return {
    type: (searchParams.get('type') as WidgetLinkType) ?? 'home',
    value: searchParams.get('value') ?? '',
    network: searchParams.get('network') ?? '',
  };
};

// if app used as iframe (widget/miniapp) this logic for syncing height and routes with superapp
export const useWidget = () => {
  if (!featureConfig.isBrowserHistoryDisabled) return;

  const handleMessage = useCallback((e: MessageEvent<WidgetMessage>) => {
    const msg = e.data;

    if (msg.type === WidgetMessageType.RouteChange) {
      const nextUrl = getPathFromParams(msg.value);
      historyInstance.push(nextUrl);
    }
  }, []);

  useEffect(() => {
    const params = parseSearchParams(
      new URLSearchParams(window.location.search),
    );
    const initial = getPathFromParams(params);
    historyInstance.replace(initial);
  }, []);

  useEffect(() => {
    if (!IS_EMBEDDED) return;

    window.addEventListener('message', handleMessage);
    // @ts-ignore
    const resizeObserver = new ResizeObserver(entries => {
      sendMessage({
        type: WidgetMessageType.HeightChange,
        value: entries[0].target.clientHeight,
      });
    });

    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);
};

export const useWidgetRouteChange = (
  type: WidgetLinkType,
  value = '',
  network: string | string[] = '',
) => {
  if (!IS_EMBEDDED) return;

  useEffect(() => {
    sendMessage({
      type: WidgetMessageType.RouteChange,
      value: {
        type,
        value,
        network: Array.isArray(network) ? network.join(',') : network,
      },
    });
  }, [type, value, network]);
};
