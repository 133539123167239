import React from 'react';
import { IBlockchainForAddressInfo } from 'components/Blockchains/types';
import { BalanceUI } from './components/BalanceUI/BalanceUI';
import { useBalance } from './components/useBalance';

export const Balance = ({
  className,
  blockchain,
}: {
  className?: string;
  blockchain?: IBlockchainForAddressInfo;
}) => {
  const { data, loading, error, getBalances, totalBalanceUsd } =
    useBalance(blockchain);

  return (
    <BalanceUI
      className={className}
      balancesData={data}
      error={error}
      isLoading={loading}
      getBalances={getBalances}
      totalBalanceUsd={totalBalanceUsd}
    />
  );
};
