import { Paper, PaperProps, Typography } from '@material-ui/core';
import { useMethodsBoxStyles } from './useMethodsBoxStyles';

interface IMethodsBoxProps extends PaperProps {
  isLoading?: boolean;
  isError?: boolean;
  isNoData?: boolean;
}

export function MethodsBox({
  children,
  isLoading,
  isNoData,
  ...props
}: IMethodsBoxProps): JSX.Element {
  const classes = useMethodsBoxStyles();

  return (
    <Paper {...props} variant="outlined" className={classes.root}>
      {isLoading && <Typography>{'Loading...'}</Typography>}

      {!isLoading && isNoData && (
        <Typography>
          {'Sorry, there are no available Contract ABI methods.'}
        </Typography>
      )}

      {children}
    </Paper>
  );
}
