import React, { FC } from 'react';
import { ButtonBase } from '@material-ui/core';
import classNames from 'classnames';
import { ReactComponent as DiscordIcon } from './assets/discord.svg';
import { ReactComponent as TelegramIcon } from './assets/telegram.svg';
import { ReactComponent as TwitterIcon } from './assets/x-twitter.svg';
import { useNeuraFooterStyles } from './useNeuraFooterStyles';

type TSocIcon = 'twitter' | 'telegram' | 'discord';

interface ISocialLinkProps {
  href: string;
  type: TSocIcon;
}

export function SocialLink({ href, type }: ISocialLinkProps): JSX.Element {
  const classes = useNeuraFooterStyles();

  const Icon = getIcon(type);

  return (
    <ButtonBase
      component="a"
      href={href}
      className={classes.socialLink}
      target="_blank"
      rel="noreferrer"
      disableRipple
    >
      <Icon
        className={classNames(
          classes.socialIcon,
          type === 'discord' && classes.socialIconDiscord,
          type === 'twitter' && classes.socialIconTwitter,
        )}
      />
    </ButtonBase>
  );
}

function getIcon(type: TSocIcon): FC<{ className?: string }> {
  switch (type) {
    case 'twitter':
      return TwitterIcon;
    case 'telegram':
      return TelegramIcon;
    case 'discord':
    default:
      return DiscordIcon;
  }
}
