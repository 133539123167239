import { alpha, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useFooterStyles = makeStyles<Theme>(theme => ({
  root: {
    padding: theme.spacing(4.75, 4.75, 3, 4.75),
    borderTop: `1px solid #e7eaf3`,
  },
  content: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  copyright: {
    fontFeatureSettings: '"ss01" 1, "ss02" 0, "calt" 1, "tnum" 0, "lnum" 0',
    fontSize: 13,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 18,
    },
  },
  mailTo: {
    fontSize: 13,
    color: theme.palette.text.secondary,
    transition: 'color .3s',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  selectWrapper: {
    height: 36,
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
    borderRadius: 65,
    marginRight: theme.spacing(3),
    '&:hover': {
      borderColor: alpha(theme.palette.text.secondary, 0.6),
    },
  },
  selectInput: {
    backgroundColor: 'transparent',
    '&&': {
      paddingRight: theme.spacing(4),
    },
  },
  selectIcon: {
    color: theme.palette.text.secondary,
  },
  socialButton: {
    color: alpha(theme.palette.text.secondary, 0.4),
    transition: 'color .3s',
    '&:hover': {
      backgroundColor: 'transparent',
      color: alpha(theme.palette.text.secondary, 0.6),
    },
  },
}));
