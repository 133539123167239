import { getChainIdByName } from 'api/utils/getChainIdByName';
import { featureConfig } from 'common/featureConfig';
import { StyledTab } from 'components/StyledTab';
import { TabPanel } from 'components/TabPanel';
import { useContractMethods } from 'modules/Address/hooks/useContractMethods';
import {
  IAddressBlock,
  ITransfersTableConfig,
  OTxTab,
  TTxTab,
} from '../../types';
import { transfersTablesConfig } from '../constants';
import { ProxyReadMethods } from '../ProxyReadMethods';
import { ProxyWriteMethods } from '../ProxyWriteMethods';
import { ReadMethods } from '../ReadMethods';
import { Tabs } from '../Tabs';
import { TransfersTableWrapper } from '../TransfersTable/TransfersTableWrapper';
import { WriteMethods } from '../WriteMethods';
import { useProxyContract } from './useProxyContract';

interface IAddressTransactionsProps {
  tab: TTxTab;
}

export const AddressTransactions = ({
  tab,
  blockchain,
  onTabChange,
}: IAddressBlock & IAddressTransactionsProps) => {
  const chainId = getChainIdByName(blockchain);
  const { contractABI, proxyContractABI } = useContractMethods(chainId);
  const isContractTabsShown = contractABI.length > 0;
  const isProxyContractShown = proxyContractABI.length > 0;

  const renderTabPanel = (props: ITransfersTableConfig, index: number) => (
    <TabPanel key={index} value={tab} index={index}>
      <TransfersTableWrapper blockchain={blockchain} {...props} />
    </TabPanel>
  );

  return (
    <>
      <Tabs value={tab} onChange={onTabChange}>
        <StyledTab label="Transactions" value={OTxTab.transactions} />

        <StyledTab label="Token Transfers" value={OTxTab.tokenTransfers} />

        <StyledTab
          isHidden={!featureConfig.showNftTransactions}
          label="NFT Transactions"
          value={OTxTab.nftTransfers}
        />

        <StyledTab
          isHidden={!isContractTabsShown}
          label="Read Contract"
          value={OTxTab.readContract}
        />

        <StyledTab
          isHidden={!isContractTabsShown}
          label="Write Contract"
          value={OTxTab.writeContract}
        />

        <StyledTab
          isHidden={!isProxyContractShown}
          label="Read as Proxy"
          value={OTxTab.readProxyContract}
        />

        <StyledTab
          isHidden={!isProxyContractShown}
          label="Write as Proxy"
          value={OTxTab.writeProxyContract}
        />
      </Tabs>

      {transfersTablesConfig.map(renderTabPanel)}

      {isContractTabsShown && chainId && (
        <>
          <TabPanel value={tab} index={OTxTab.readContract}>
            <ReadMethods chainId={chainId} />
          </TabPanel>

          <TabPanel value={tab} index={OTxTab.writeContract}>
            <WriteMethods chainId={chainId} />
          </TabPanel>
        </>
      )}

      {isProxyContractShown && chainId && (
        <>
          <TabPanel value={tab} index={OTxTab.readProxyContract}>
            <ProxyReadMethods chainId={chainId} />
          </TabPanel>

          <TabPanel value={tab} index={OTxTab.writeProxyContract}>
            <ProxyWriteMethods chainId={chainId} />
          </TabPanel>
        </>
      )}
    </>
  );
};
