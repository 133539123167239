import React, { ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getAddressTitle } from 'modules/Address/components/TransfersTable/TransactionsTableUtils';
import { AddressRoutesConfig } from 'modules/router/const';
import { useTransactionsTableCellStyles } from 'modules/Transactions/components/TransactionsUI/TransactionsTableCellStyles';
import { TxIcon } from 'uiKit/Icons/TxIcon';
import { TableBodyCell } from '../TableBodyCell';

interface ITableCellAddressProps {
  label: string;
  address: string;
  addressName: string;
  children?: ReactElement;
  isContract?: boolean;
  plainText?: boolean;
}

export const TableCellAddress = ({
  label,
  address,
  addressName,
  children,
  isContract,
  plainText,
}: ITableCellAddressProps) => {
  const classes = useTransactionsTableCellStyles();
  const addressTitle = getAddressTitle({
    addressName: addressName,
    address: address,
  });
  const { search } = useLocation();

  const renderAddress = (address?: string, addressName?: string) =>
    address ? (
      <Link
        className={classes.link}
        to={AddressRoutesConfig.address.generatePath(address) + search}
      >
        {children || addressName}
      </Link>
    ) : (
      <span className={classes.emptyCellMdash}>{'—'}</span>
    );

  return (
    <TableBodyCell
      className={classes.tableCell}
      label={label}
      tooltipText={addressTitle}
    >
      {isContract && <TxIcon />}
      {plainText ? addressName : renderAddress(address, addressName)}
    </TableBodyCell>
  );
};
