import React, { useMemo } from 'react';
import { INftTransfer, ITransaction } from 'common/types';
import { t } from 'common/utils/intl';
import { IBlockchainForAddressInfo } from 'components/Blockchains/types';
import { ErrorMessage } from 'components/ErrorMessage';
import { NothingFoundTxs } from 'components/NothingFound';
import { TableShowOlder } from 'components/TableShowMore';
import { TableView } from 'components/TableView/TableView';
import { TransactionsTableRowSkeleton } from '../../../Transactions/components/TransactionsUI/TransactionsTableRow/TransactionsTableRowSkeleton';
import { ITransfersTableConfig } from '../../types';
import { TransactionsTableRows } from './TransactionsTableRows';
import { TransfersTableRows } from './TransfersTableRows';
import { useAddressData } from './useAddressData';

interface IProps extends ITransfersTableConfig {
  blockchain?: IBlockchainForAddressInfo;
}

export const TransfersTableWrapper = ({
  type,
  blockchain,
  transfersCaptions,
  tableGridTemplateColumns,
}: IProps) => {
  const { error, loadMore, address, items, loading, hasNextPage } =
    useAddressData({
      blockchain,
      type,
    });

  const renderedError = error && (
    <ErrorMessage
      noBorderRadius
      text={t('errorMessages.requestError')}
      onClick={loadMore}
    />
  );

  const renderContent = useMemo(() => {
    if (items?.length) {
      return (
        <>
          <TableView
            tableGridTemplateColumns={tableGridTemplateColumns}
            transfersCaptions={transfersCaptions}
          >
            {type === 'transactions' ? (
              <TransactionsTableRows
                address={address}
                items={items as ITransaction[]}
              />
            ) : (
              <TransfersTableRows
                address={address}
                items={items as INftTransfer[]}
                transfersCaptions={transfersCaptions}
                type={type}
              />
            )}
          </TableView>
          <TableShowOlder
            onLoadOlder={loadMore}
            isLoading={loading}
            hasNextPage={hasNextPage}
          />
        </>
      );
    } else {
      if (loading) {
        return (
          <TableView
            tableGridTemplateColumns={tableGridTemplateColumns}
            transfersCaptions={transfersCaptions}
          >
            <TransactionsTableRowSkeleton captions={transfersCaptions} />
          </TableView>
        );
      } else {
        return <NothingFoundTxs />;
      }
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [items, address, loading]);

  return (
    <>
      {renderedError}
      {renderContent}
    </>
  );
};
