import { Web3, SupportedProviders, Contract } from 'web3';

export interface IProvider {
  chainId: number;
  web3: Web3;
  createContract: (abi: any, address: string) => Contract<any>;
}

export const OWalletId = {
  injected: 'injected',
} as const;

export type TWalletId = typeof OWalletId[keyof typeof OWalletId];

export type InjectedProvider = SupportedProviders & {
  enable(): Promise<void>;
};

export const OProviderEvents = {
  accountsChanged: 'accountsChanged',
  disconnect: 'disconnect',
  message: 'message',
  chainChanged: 'chainChanged',
} as const;

export type TProviderEvents =
  typeof OProviderEvents[keyof typeof OProviderEvents];
