import {
  alpha,
  createTheme,
  darken,
  lighten,
  ThemeOptions,
} from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { featureConfig } from 'common/featureConfig';
import { AngleDownIcon } from 'uiKit/Icons/AngleDownIcon';
import { Inter } from './fonts';

const TEN_SECONDS = 10 * 1000;

const fontFallback = [Inter, 'Arial', 'sans-serif'].join(',');

export const FONTS = {
  primary: fontFallback,
  secondary: fontFallback,
};

export const PALETTE = {
  type: 'dark',
  background: {
    default: '#F9F9F9',
    paper: '#fff',
  },
  primary: {
    light: lighten('#356DF3', 0.1),
    main: '#356DF3',
    dark: darken('#356DF3', 0.2),
    contrastText: '',
  },
  text: {
    primary: '#1F2226;',
    secondary: alpha('#1F2226;', 0.5),
  },
  grey: {},
  success: {
    main: '#38FF70',
    dark: '#155724',
    light: '#d4edda',
  },
};

const BREAKPOINTS = {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
};

export const defaultTheme = createTheme({
  breakpoints: BREAKPOINTS,
  palette: PALETTE as PaletteOptions,
});

const mainTheme = createTheme({
  spacing: 8,
  palette: PALETTE as PaletteOptions,
  breakpoints: BREAKPOINTS,

  typography: {
    fontFamily: FONTS.primary,
    color: PALETTE.text.primary,
  },

  props: {
    MuiContainer: {
      maxWidth: 'xl',
    },
    MuiButton: {
      disableRipple: true,
      variant: 'contained',
    },
    MuiPaper: {
      elevation: 0,
    },
    MuiInputBase: {
      autoComplete: 'off',
    },
    MuiSelect: {
      IconComponent: AngleDownIcon,
    },
    MuiTooltip: {
      enterTouchDelay: 0,
      leaveTouchDelay: TEN_SECONDS,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          fontFeatureSettings:
            '"ss01" 1, "ss02" 1, "calt" 0, "tnum" 1, "lnum" 1',
          overflowY: featureConfig.disableScroll ? 'hidden' : undefined,
        },
        a: {
          color: PALETTE.primary.main,
          fontSize: 14,
          textDecoration: 'none',
          '&:hover': {
            color: PALETTE.primary.dark,
          },
        },
        /* react-toastify override */
        '.Toastify__toast': {
          fontFamily: FONTS.primary,
        },
      },
    },
    MuiTypography: {
      h1: {
        fontFamily: FONTS.secondary,
        fontSize: 32,
        lineHeight: 47 / 40,
        fontWeight: 700,

        [defaultTheme.breakpoints.up('md')]: {
          fontSize: 40,
        },

        [defaultTheme.breakpoints.up('lg')]: {
          fontSize: 52,
        },
      },
      h2: {
        fontFamily: FONTS.secondary,
        fontSize: 30,
        fontWeight: 700,
        lineHeight: 33 / 28,
      },
      h3: {
        fontSize: 20,
        fontWeight: 500,
        lineHeight: 23 / 20,
      },
      body1: {
        fontSize: 16,
        fontWeight: 400,
      },
      body2: {
        fontSize: 14,
        fontWeight: 700,
        lineHeight: 17 / 14,
      },
      caption: {
        fontSize: 14,
      },
      colorSecondary: {
        color: PALETTE.text.secondary,
      },
    },
    MuiContainer: {
      maxWidthXl: {
        [defaultTheme.breakpoints.up('xl')]: {
          maxWidth: 1400 + defaultTheme.spacing(3 * 2),
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 14,
        color: '#1e2022',
        background: PALETTE.background.paper,
        border: '1px solid #E6E6E6',
        height: 32,
        borderRadius: 6,
        transition: 'border 0.2s',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '&$focused': {
          border: `1px solid ${darken('#E6E6E6', 0.1)}`,
        },
        '&$disabled': {
          color: '#ccc',
        },
        '&$error': {
          borderColor: defaultTheme.palette.error.main,
        },
      },
      input: {
        padding: defaultTheme.spacing(0, 1.5),
        height: '100%',
        backgroundColor: '#fff',
        borderRadius: 'inherit',
      },
    },
    MuiSelect: {
      select: {
        borderRadius: 'inherit',

        '&&': {
          paddingRight: 32,
        },
      },
      selectMenu: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 'inherit',
      },
      icon: {
        color: PALETTE.text.primary,
        top: 'calc(50% - 7px)',
        right: 10,
        '&&': {
          fontSize: 12,
        },
      },
    },
    MuiInputAdornment: {
      root: {
        width: 48,
      },
      positionStart: {
        marginRight: 0,
        '& svg': {
          margin: '0 auto',
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 6,
        '&$disabled': {
          cursor: 'not-allowed',
          pointerEvents: 'auto',
        },
      },
      contained: {
        backgroundColor: PALETTE.primary.main,
        fontWeight: 400,
        boxShadow: 'none',
        color: defaultTheme.palette.common.white,
        '&:hover': {
          backgroundColor: PALETTE.primary.dark,
          boxShadow: 'none',
        },
        '&:active, &:focus': {
          boxShadow: 'none',
        },
        '& svg': {
          color: 'inherit',
        },
      },
      outlined: {
        textTransform: 'none',
        fontWeight: 500,
        border: '1px solid #E6E6E6',
        '&$disabled': {
          border: '1px solid #E6E6E6',
          color: alpha(defaultTheme.palette.common.black, 0.4),
        },
      },
      outlinedPrimary: {
        border: `1px solid ${PALETTE.primary.main}`,
      },
      text: {
        textTransform: 'none',
      },
    },
    MuiIconButton: {
      root: {
        padding: 9,
        '&:hover': {
          backgroundColor: alpha(PALETTE.primary.main, 0.1),
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 20,
      },
      outlined: {
        border: '1px solid #E6E6E6',
      },
    },
    MuiTableContainer: {
      root: {
        padding: defaultTheme.spacing(0, 1.5),
        [defaultTheme.breakpoints.up('md')]: {
          padding: defaultTheme.spacing(0, 2.5),
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: `1px solid ${alpha('#000', 0.1)}`,
        '.MuiTableRow-root:last-of-type &': {
          borderBottom: 'none',
        },
      },
      body: {
        '&:first-child': {
          paddingLeft: 0,
        },
        '&:last-child': {
          paddingRight: 0,
        },
      },
    },
    MuiTabs: {
      root: {
        paddingTop: 12,
        [defaultTheme.breakpoints.up('md')]: {
          paddingTop: 20,
        },
      },
      flexContainer: {
        padding: '0 12px',
        [defaultTheme.breakpoints.up('md')]: {
          padding: '0 20px',
        },
      },
    },
    MuiTab: {
      root: {
        '&&': {
          minHeight: 'auto',
          textTransform: 'none',
          color: defaultTheme.palette.text.secondary,
          fontSize: 16,
          fontWeight: 600,
          marginBottom: 20,
          borderRadius: 12,
          paddingRight: 10,
          [defaultTheme.breakpoints.up('xs')]: {
            minWidth: 'auto',
          },
          [defaultTheme.breakpoints.up('md')]: {
            padding: '5px 14px',
          },
          '&:hover': {
            color: defaultTheme.palette.text.primary,
          },
          '&$selected': {
            color: '#FFFFFF',
            backgroundColor: defaultTheme.palette.primary.main,
          },
        },
      },
    },
  },
} as ThemeOptions);

export { mainTheme };
