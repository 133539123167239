import React from 'react';
import { t } from 'common/utils/intl';
import { ErrorMessage } from 'components/ErrorMessage';
import { useLatestBlocks } from '../Lobby/components/Latest/useLatestBlocks';
import { BlocksPage } from './BlocksPage';

export const Blocks = () => {
  const { data, loading, error, blockchainNames, setBlockchainNames } =
    useLatestBlocks();

  const renderedError = () => {
    return <ErrorMessage text={t('errorMessages.requestError')} />;
  };

  return (
    <BlocksPage
      items={data?.items}
      isLoading={loading}
      error={error && renderedError()}
      blockchainNames={blockchainNames}
      setBlockchainNames={setBlockchainNames}
    />
  );
};
