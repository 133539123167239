import { IMoneyTransfer } from 'common/types';
import { IS_EMBEDDED, CUSTOM_CHAINS } from '../common/const';
import { explorerRpc } from './explorerRpc';
import { IApiBlockchainName, IApiTokenTransfer } from './types';
import { buildRpcRequest } from './utils/buildRpcRequest';

interface IRequestParams {
  blockchain?: IApiBlockchainName;
  walletAddress: string;
  pageToken?: string;
  pageSize?: number;
}

type ITransactionsQueryV2 = {
  items?: IMoneyTransfer[];
  nextPageToken?: string;
};

interface IApiGetAddressTokenTransfersReply {
  result?: {
    transfers: IApiTokenTransfer[];
    nextPageToken: string;
  };
}

export const { useGetTokenTransfersByAddressQuery } =
  explorerRpc.injectEndpoints({
    endpoints: build => ({
      getTokenTransfersByAddress: build.query<
        ITransactionsQueryV2,
        IRequestParams
      >({
        query: ({ walletAddress, blockchain, pageToken, pageSize = 10 }) =>
          buildRpcRequest({
            method: 'explorer_getAddressTokenTransfers',
            params: {
              walletAddress,
              blockchain:
                blockchain || (IS_EMBEDDED ? CUSTOM_CHAINS : undefined),
              pageToken,
              pageSize,
            },
          }),
        transformResponse: (data: IApiGetAddressTokenTransfersReply) => {
          return {
            items:
              data.result?.transfers.map(t => {
                return {
                  ...t,
                  id: t.transactionHash,
                  blockchainName: t.blockchainName,
                  blockchainVerboseName: 'TODO',
                  blockchainLogo: '',
                  blockHeight: t.blockHeight.toString(),
                  fromAddressIsContract: false,
                  toAddressIsContract: false,
                  method: 'TODO',
                  methodName: 'TODO',
                  fromAddressName: t.fromAddressName || t.fromAddress,
                  toAddressName: t.toAddressName || t.toAddress,

                  valueFormatted: t.valueHumanReadable,

                  contractAddressName: 'TODO',
                  logo: 'TODO',
                };
              }) || [],
            nextPageToken: data.result?.nextPageToken,
          };
        },
      }),
    }),
    overrideExisting: false,
  });
