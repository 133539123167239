import React, { ChangeEvent } from 'react';
import { uid } from 'react-uid';
import { IconButton, Select } from '@material-ui/core';
import {
  useLocale,
  useLocaleUpdate,
} from 'common/locales/AppLocaleContext/AppLocaleContext';
import { locales } from 'common/locales/locales';
import { Locale } from 'common/types';
import { t, tHTML } from 'common/utils/intl';
import { ReactComponent as DiscordIcon } from './assets/discord.svg';
import { ReactComponent as MediumIcon } from './assets/medium.svg';
import { ReactComponent as RedditIcon } from './assets/reddit.svg';
import { ReactComponent as TelegramIcon } from './assets/telegram.svg';
import { ReactComponent as TwitterIcon } from './assets/twitter.svg';
import { useFooterStyles } from './FooterStyles';

const IS_LOCALIZATION_AVAILABLE = false;

const SOCIAL_BUTTONS = [
  {
    name: 'reddit',
    website: 'https://www.reddit.com/r/Ankrofficial/',
    icon: <RedditIcon />,
  },
  {
    name: 'twitter',
    website: 'https://twitter.com/ankr',
    icon: <TwitterIcon />,
  },
  {
    name: 'telegram',
    website: 'https://t.me/ankrnetwork',
    icon: <TelegramIcon />,
  },
  {
    name: 'medium',
    website: 'https://medium.com/ankr-network',
    icon: <MediumIcon />,
  },
  {
    name: 'discord',
    website: 'https://discord.gg/uYaNu23Ww7',
    icon: <DiscordIcon />,
  },
];

export const Footer = () => {
  const classes = useFooterStyles();
  const locale = useLocale();
  const updateLocale = useLocaleUpdate();

  const handleChange = (e: ChangeEvent<{ value: unknown }>) => {
    const value = e.target.value;
    updateLocale && updateLocale(value as Locale);
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.copyright}>
          {tHTML('footer.copyright', { currentYear: new Date().getFullYear() })}
          <a className={classes.mailTo} href={`mailto:${t('footer.mail')}`}>
            {t('footer.mail')}
          </a>
        </div>
        <div className={classes.intro}>
          {IS_LOCALIZATION_AVAILABLE && (
            <Select
              title="change language"
              className={classes.selectWrapper}
              name="language"
              value={locale}
              onChange={handleChange}
              native
              classes={{
                select: classes.selectInput,
                icon: classes.selectIcon,
              }}
            >
              {locales.map(option => (
                <option key={uid(option)} value={option.value}>
                  {option.name}
                </option>
              ))}
            </Select>
          )}
          {SOCIAL_BUTTONS.map(social => (
            <IconButton
              title={social.name}
              className={classes.socialButton}
              key={uid(social)}
              component="a"
              href={social.website}
              target="_blank"
            >
              {social.icon}
            </IconButton>
          ))}
        </div>
      </div>
    </div>
  );
};
