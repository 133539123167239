import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { useGetAddressResolverQuery } from 'api/getAddressResolver';
import { t } from 'common/utils/intl';
import { ErrorMessage } from 'components/ErrorMessage';
import { Spinner } from 'components/Spinner';
import { Container } from 'uiKit/Container';
import { Layout } from '../Layout';
import { AddressRoutesConfig } from '../router/const';

export const AddressResolver = () => {
  const navigate = useNavigate();
  const { address } = AddressRoutesConfig.addressResolver.useParams();
  const { data, isLoading, isFetching, isError, refetch } =
    useGetAddressResolverQuery({
      address,
    });

  useEffect(() => {
    if (data?.address) {
      navigate(AddressRoutesConfig.address.generatePath(data.address));
    }
  }, [data?.address]);

  return (
    <Layout>
      <Container>
        {(isLoading || isFetching) && <Spinner />}
        {isError && (
          <Box mt={4}>
            <ErrorMessage
              text={t('errorMessages.wrongNamespace')}
              onClick={refetch}
            />
          </Box>
        )}
      </Container>
    </Layout>
  );
};
