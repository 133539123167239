import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { explorerRpc } from 'api/explorerRpc';
import { web3Api } from 'api/web3Api';
import { queryErrorLogger } from './errorMiddleware';

const rootReducer = combineReducers({
  [explorerRpc.reducerPath]: explorerRpc.reducer,
  [web3Api.reducerPath]: web3Api.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      // TODO: fix serializable error
      // https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
      serializableCheck: false,
    })
      .concat(queryErrorLogger)
      .concat(explorerRpc.middleware)
      .concat(web3Api.middleware),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
