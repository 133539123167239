import React from 'react';
import { uid } from 'react-uid';
import { Box, Typography } from '@material-ui/core';
import { TransferCaptionType } from 'common/const';
import { INftTransfer } from 'common/types';
import { getTimePassed } from 'common/utils/getTimePassed';
import { BlockchainIcon } from 'components/BlockchainIcon';
import {
  TableBodyCell,
  TableCellAddress,
  TableCellHash,
  TableRow,
} from 'components/TableComponents';
import { TransactionDirection } from 'components/TransactionDirection/TransactionDirection';
import { useTransactionsTableCellStyles } from 'modules/Transactions/components/TransactionsUI/TransactionsTableCellStyles';
import { ImageWithExternalSrc } from 'uiKit/ImageWithExternalSrc/ImageWithExternalSrc';
import { RequestType } from '../../types';
import { getPlains } from './TransactionsTableUtils';

interface IProps {
  address?: string;
  items: INftTransfer[];
  transfersCaptions: TransferCaptionType[];
  type: RequestType;
}

export const TransfersTableRows = ({
  address,
  items,
  transfersCaptions,
  type,
}: IProps) => {
  const classes = useTransactionsTableCellStyles();

  return (
    <>
      {items?.map(
        (
          {
            blockchainName,
            transactionHash,
            fromAddress,
            fromAddressName,
            toAddress,
            toAddressName,
            valueFormatted,
            valueSymbol,
            logo,
            timestamp,
            blockchainLogo,
            collectionName,
            fromAddressIsContract,
            toAddressIsContract,
            direction,
            contractAddress,
            tokenId,
          },
          i,
        ) => {
          const { toPlainText, fromPlainText } = getPlains(direction);

          const token = (
            <Box className={classes.tableCellToken}>
              <BlockchainIcon className={classes.icon} type={blockchainName} />
              <Typography variant="caption">
                {valueSymbol || blockchainName}
              </Typography>
            </Box>
          );

          return (
            <TableRow key={uid(transactionHash, i)}>
              <TableCellHash
                label={transfersCaptions[0].label}
                blockchainName={blockchainName}
                blockchainLogo={blockchainLogo}
                transactionHash={transactionHash}
              />

              <TableBodyCell
                className={classes.tableCell}
                label={transfersCaptions[1].label}
              >
                {getTimePassed(timestamp)}
              </TableBodyCell>

              <TableCellAddress
                label={transfersCaptions[2].label}
                address={fromAddress}
                addressName={fromAddressName}
                isContract={fromAddressIsContract}
                plainText={fromPlainText}
              />

              <TableBodyCell
                className={classes.tableCell}
                label={transfersCaptions[3].label}
              >
                {address && (
                  <TransactionDirection
                    address={address}
                    fromAddress={fromAddress}
                    toAddress={toAddress}
                  />
                )}
              </TableBodyCell>

              <TableCellAddress
                label={transfersCaptions[4].label}
                address={toAddress}
                addressName={toAddressName}
                isContract={toAddressIsContract}
                plainText={toPlainText}
              />

              <TableBodyCell
                className={classes.tableCell}
                label={transfersCaptions[5].label}
              >
                {valueFormatted}
              </TableBodyCell>

              {type === 'nftTransfers' ? (
                <>
                  <TableBodyCell
                    className={classes.tableCell}
                    label={transfersCaptions[6].label}
                  >
                    {tokenId}
                  </TableBodyCell>
                  <TableCellAddress
                    label={transfersCaptions[7].label}
                    address={contractAddress}
                    addressName={collectionName || contractAddress}
                  />
                  <TableBodyCell
                    className={classes.tableCell}
                    label={transfersCaptions[8].label}
                  >
                    <Box className={classes.hashCell}>
                      <ImageWithExternalSrc
                        className={classes.icon}
                        alt="icon"
                        src={logo}
                      />
                      <Typography
                        className={classes.ellipsis}
                        variant="caption"
                      >
                        {valueSymbol || blockchainName}
                      </Typography>
                    </Box>
                  </TableBodyCell>
                </>
              ) : contractAddress ? (
                <TableCellAddress
                  label={transfersCaptions[6].label}
                  address={contractAddress}
                  addressName={valueSymbol || blockchainName}
                >
                  {token}
                </TableCellAddress>
              ) : (
                <TableBodyCell
                  label={transfersCaptions[6].label}
                  className={classes.tableCell}
                >
                  {token}
                </TableBodyCell>
              )}
            </TableRow>
          );
        },
      )}
    </>
  );
};
