import { createStyles } from '@material-ui/core';
import { defaultTheme } from 'common/themes/mainTheme';

export const TABLE_MIN_WIDTH = 900;

export const tableStyles = createStyles({
  container: {
    [defaultTheme.breakpoints.up('md')]: {
      overflow: 'hidden',
      overflowX: 'auto',
    },

    [defaultTheme.breakpoints.up('lg')]: {
      overflow: 'visible',
    },
  },

  withFooter: {
    [defaultTheme.breakpoints.up('md')]: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: 'none',
    },
  },

  table: {
    position: 'relative',

    [defaultTheme.breakpoints.up('md')]: {
      minWidth: TABLE_MIN_WIDTH,
      overflow: 'visible',
      height: '100%',
      boxSizing: 'border-box',
    },
  },
});
