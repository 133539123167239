import React from 'react';
import { featureConfig } from '../../common/featureConfig';
import { Layout } from '../Layout';
import { Filter } from './components/Filter';
import { Latest } from './components/Latest';
import { NeuraFilterHeader } from './components/NeuraFilterHeader/NeuraFilterHeader';

export const Lobby = () => {
  return (
    <Layout>
      <Filter
        HeaderComponent={featureConfig.isNeura ? NeuraFilterHeader : undefined}
      />
      <Latest />
    </Layout>
  );
};
