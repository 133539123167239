import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useBalanceStyles = makeStyles<Theme>(theme => ({
  paper: {
    overflow: 'hidden',
    border: '1px solid #E6E6E6',
    borderTopColor: 'transparent',
    borderRadius: '0 0 20px 20px',
  },
  assetsTable: {
    border: 'none',
  },
  skeletonContainer: {
    display: 'flex',
    gap: '10px',
    marginBottom: 30,
  },
  skeletonItem: {
    margin: 5,
    width: 150,
    height: 42,
  },
  item: {
    margin: '0px 0px 15px 0px',
  },
  info: {
    fontSize: 14,
    padding: '21px 28px',
    borderLeft: '1px solid #E7E7E7',
    borderRight: '1px solid #E7E7E7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#FFFFFF',
    flexWrap: 'wrap',
    transition: 'border-radius 0.3s',
    [theme.breakpoints.down('sm')]: {
      padding: '12px',
      paddingTop: 0,
    },
  },
  borderRadiusBottom: {
    borderBottom: '1px solid #E7E7E7',
    borderRadius: '0 0 20px 20px',
  },
  totalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 16,
  },
  totalTitle: {
    color: '#9AA1B0',
    marginBottom: theme.spacing(1),
  },
  totalValue: {
    fontWeight: 800,
    fontSize: 24,
    fontFeatureSettings: '"ss01" 1, "ss02" 0, "calt" 1, "tnum" 0, "lnum" 0',
  },
  btn: {
    backgroundColor: 'transparent',
    color: '#9AA1B0',
    border: '2px solid #F2F5FA',
    borderColor: '#F2F5FA',
    transition: 'color .4s, border-color .4s',
    textTransform: 'none',

    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      borderColor: '#9AA1B0',
    },
  },
  collapseButton: {
    marginLeft: theme.spacing(2),
  },
  btnArrow: {
    transition: 'transform .3s',
  },
  btnArrowReverted: {
    transform: 'rotate(-180deg)',
  },
  updateButton: {
    marginLeft: theme.spacing(2),
    padding: 10,
    minWidth: 'auto',
    width: 40,
    height: 40,
  },
  isRefetching: {
    pointerEvents: 'none',
  },
  spinner: {
    position: 'static',
  },
  assetLogo: {
    marginLeft: -8,
    borderRadius: '50%',
    backgroundColor: '#FFFFFF',
  },
  balances: {
    display: 'flex',
    alignItems: 'center',
  },
  groupName: {
    fontWeight: 500,
    fontSize: 14,
    marginBottom: 19,
    display: 'flex',
    alignItems: 'center',
  },
  assetLogoCell: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateAreas: `'value icon'`,
    gridTemplateColumns: '1fr auto',
    gap: theme.spacing(0, 1.5),
    [theme.breakpoints.up('md')]: {
      gridTemplateAreas: `'icon value'`,
      gridTemplateColumns: 'auto 1fr',
    },
  },
  assetText: {
    gridArea: 'value',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  logo: {
    gridArea: 'icon',
    width: 28,
    height: 28,
  },
  icon: {
    marginRight: 8,
    position: 'relative',
    top: '-1px',
    width: 16,
    height: 16,
  },
  group: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 25,
  },
  pagination: {
    '&&': { border: 'none' },
  },
  error: {
    marginBottom: 30,
    borderBottomRightRadius: 30,
    borderBottomLeftRadius: 30,
    borderTopColor: 'transparent',
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '0 0 20px 20px',
    border: '1px solid #E7E7E7',
    borderTop: 'none',
    marginBottom: theme.spacing(6.25),
    padding: theme.spacing(0, 2.5, 3),
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  emptyIcon: {
    width: 20,
    height: 20,
  },
  emptyText: {
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: -0.01,
  },
}));
