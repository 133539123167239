import React from 'react';
import { uid } from 'react-uid';
import { Box, BoxProps, Grid, Hidden, Select } from '@material-ui/core';
import classNames from 'classnames';
import { t } from 'common/utils/intl';
import { Pagination } from '../Pagination';
import { useTablePaginationStyles } from './TablePaginationStyles';

export const perPageOptions = [
  {
    value: 10,
    label: '10',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100',
  },
];

interface ITablePaginationProps extends BoxProps {
  onPerPageChange: (event: any) => void;
  onPaginationChange: (page: number, event: any) => void;
  perPage: string | number;
  isLoading?: boolean;
  hasNextPage: boolean;
  currentPage: number;
  pagesCount?: number;
}

export const TablePagination = ({
  onPerPageChange,
  onPaginationChange,
  perPage,
  isLoading = false,
  hasNextPage,
  currentPage,
  className,
  pagesCount,
  ...rest
}: ITablePaginationProps) => {
  const classes = useTablePaginationStyles();
  return (
    <Box className={classNames(classes.root, className)} {...rest}>
      <Grid container spacing={3} alignItems="center">
        <Hidden smDown>
          <Grid item md>
            <Box display="flex" alignItems="center">
              <Box mr={2} fontWeight={500}>
                {t('tablePagination.show')}
              </Box>

              <Select
                name="filter"
                onChange={onPerPageChange}
                value={perPage}
                disabled={isLoading}
                native
              >
                {perPageOptions.map(option => (
                  <option key={uid(option)} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </Box>
          </Grid>
        </Hidden>

        <Grid item xs md="auto">
          <Pagination
            hasNextPage={hasNextPage}
            page={currentPage}
            onChange={onPaginationChange}
            disabled={isLoading}
            pagesCount={pagesCount}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
