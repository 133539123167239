import { IAbiItem } from 'api/getContractAbi';
import { TChainId } from 'api/types';
import { useContractMethods } from 'modules/Address/hooks/useContractMethods';
import { isWriteMethod } from 'modules/Address/utils';

interface IUseWriteMehtods {
  methods: IAbiItem[];
  isLoading: boolean;
}

export function useWriteMehtods(chainId?: TChainId): IUseWriteMehtods {
  const { contractABI, isContractAbiLoading } = useContractMethods(chainId);

  const writeMethods = contractABI.filter(isWriteMethod);

  return {
    methods: writeMethods,
    isLoading: isContractAbiLoading,
  };
}
