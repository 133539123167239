import { useState } from 'react';
import { useGetLatestTransactionsQuery } from 'api/getLatestTransactions';
import { IApiBlockchainName } from 'api/types';
import { IS_EMBEDDED, CUSTOM_CHAINS } from '../../../../common/const';

export const useLatestTransactions = (chain?: IApiBlockchainName) => {
  const [blockchainNames, setBlockchainNames] = useState<
    IApiBlockchainName | undefined
  >(chain);
  const { data, error, isLoading, isFetching, refetch } =
    useGetLatestTransactionsQuery({
      blockchainNames:
        blockchainNames || (IS_EMBEDDED ? CUSTOM_CHAINS : undefined),
      size: blockchainNames ? 10 : 1,
    });

  return {
    data,
    loading: isLoading || isFetching,
    error,
    blockchain: blockchainNames,
    setBlockchain: setBlockchainNames,
    refetch,
  };
};
