import { makeStyles, Theme } from '@material-ui/core';

export const useBlockInfoStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },

  text: {
    color: theme.palette.text.secondary,
  },

  link: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '0.3em',

    '&::after': {
      content: `''`,
      height: 16,
      width: 1,
      margin: theme.spacing(0, 1.5),
      background: '#E7E7E7',
    },
  },

  icon: {
    marginRight: theme.spacing(1),
    fontSize: 18,
  },

  totalTransactionsCount: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
      width: '100%',
    },

    '&::before': {
      content: `''`,
      height: 16,
      width: 1,
      margin: theme.spacing(0, 1.5),
      background: '#E7E7E7',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  },
}));
