import React, { ReactNode } from 'react';
import { Box, Typography } from '@material-ui/core';
import { IApiBlockchainName } from 'api/types';
import { featureConfig } from 'common/featureConfig';
import { IBlockMapped } from 'common/types';
import { t } from 'common/utils/intl';
import { Container } from 'uiKit/Container';
import { PageTop } from '../../components/PageTop';
import { Layout } from '../Layout';
import { BlocksUi } from './BlocksUi';
import { useBlocksStyles } from './BlocksStyles';

// feature for blocks by chain filtering
const isBlocksFilterAvailable = !featureConfig.isNeura;

interface IBlocksPageProps {
  items?: IBlockMapped[];
  isLoading?: boolean;
  error: ReactNode;
  blockchainNames?: IApiBlockchainName;
  setBlockchainNames?: (b: IApiBlockchainName | undefined) => void;
}

export const BlocksPage = ({
  items,
  isLoading,
  error,
  blockchainNames,
  setBlockchainNames,
}: IBlocksPageProps) => {
  const classes = useBlocksStyles();

  return (
    <Layout>
      <Box component="section" className={classes.root}>
        <Container>
          <PageTop>
            <Typography variant="h2" className={classes.title}>
              {t('blocksComponent.title')}
            </Typography>
          </PageTop>
          <BlocksUi
            setBlockchainNames={setBlockchainNames}
            blockchainNames={blockchainNames}
            items={items}
            isLoading={isLoading}
            error={error}
            isBlocksFilterAvailable={isBlocksFilterAvailable}
          />
        </Container>
      </Box>
    </Layout>
  );
};
