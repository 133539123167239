import BigNumber from 'bignumber.js';
import { REQUEST_ERROR_MESSAGE } from 'common/const';
import { IBlockDetailsMap } from 'common/types';
import { explorerRpc } from './explorerRpc';
import { IApiBlock, IApiBlockchainName } from './types';
import { buildRpcRequest } from './utils/buildRpcRequest';
import { formatTimestamp } from './utils/formatTimestamp';

interface IRequestParams {
  blockchainName: IApiBlockchainName;
  blockNumber: number;
}

interface IBlockDetailsReplyV2 {
  result?: { block: IApiBlock };
  error?: { code: number; message: string };
}

type BlockDetailsQueryV2 = IBlockDetailsMap;

export const { useGetBlockDetailsQuery } = explorerRpc.injectEndpoints({
  endpoints: build => ({
    getBlockDetails: build.query<BlockDetailsQueryV2, IRequestParams>({
      query: ({ blockchainName, blockNumber }) =>
        buildRpcRequest({
          method: 'explorer_getBlockDetails',
          params: {
            blockchainName,
            blockNumber,
          },
        }),

      transformResponse: (data: IBlockDetailsReplyV2) => {
        if (data.error || !data.result?.block) {
          throw data.error || REQUEST_ERROR_MESSAGE;
        }

        const block = data.result.block;
        const ethBlock = block.details?.ethBlock;
        return {
          blockchainName: block.blockchainName,
          ethBlock: {
            blockHash: block.blockHash || '',
            blockHeight: block.blockHeight.toString() || '',
            gasUsed: new BigNumber(ethBlock?.gasUsed.toString() || ''),
            mineAddress: ethBlock?.miner || '',
            parentHash: block.parentHash || '',
            sha3Uncles: ethBlock?.sha3Uncles || '',
            size: new BigNumber(ethBlock?.size || ''),
            stateRoot: ethBlock?.stateRoot || '',
            timestamp: formatTimestamp(block.timestamp),
            transactionsCount: block.transactionsCount.toString() || '0',
            extraData: ethBlock?.extraData || '',
            nonce: Number(ethBlock?.nonce) || 0,
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});
