import BigNumber from 'bignumber.js';
import { REQUEST_ERROR_MESSAGE } from 'common/const';
import { ITxDetailsMap } from '../common/types';
import { Statuses } from '../modules/Transaction/components/Status';
import { explorerRpc } from './explorerRpc';
import {
  IApiGetTransactionTransfersReply,
  IApiGetTransactionTransfersRequest,
} from './types';
import { buildRpcRequest } from './utils/buildRpcRequest';
import { formatTimestamp } from './utils/formatTimestamp';

interface ITransactionTransfersReply {
  result?: IApiGetTransactionTransfersReply;
  error?: { code: number; message: string };
}

type ITransactionTransfersQuery = ITxDetailsMap;

export const { useGetTransactionTransfersQuery } = explorerRpc.injectEndpoints({
  endpoints: build => ({
    getTransactionTransfers: build.query<
      ITransactionTransfersQuery,
      IApiGetTransactionTransfersRequest
    >({
      query: ({ blockchainName, transactionHash }) =>
        buildRpcRequest({
          method: 'explorer_getTransactionTransfers',
          params: {
            blockchainName,
            transactionHash,
          },
        }),
      transformResponse(data: ITransactionTransfersReply) {
        if (data.error || !data.result?.transaction) {
          throw data.error || REQUEST_ERROR_MESSAGE;
        }

        const tx = data.result.transaction;
        const ethTransaction = tx.details?.ethTransaction;
        return {
          common: {
            ...tx,
            valueFormatted: tx.valueHumanReadable || '0',
            id: tx.transactionHash || '',
            blockchainVerboseName: tx.blockchainName,
            blockchainLogo: 'TODO',
            fromAddressName: tx.fromAddressName || '',
            fromAddressIsContract: false,
            toAddressName: tx.toAddressName || tx.toAddress || '',
            toAddressIsContract: false,
            blockHeight: tx.blockHeight.toString() || '',
          },
          blockchainName: tx.blockchainName,
          ethTransaction: {
            value: new BigNumber(tx.value || 0),
            timestamp: formatTimestamp(tx.timestamp),
            transactionFee: new BigNumber(ethTransaction?.transactionFee || 0),
            gasPrice: new BigNumber(ethTransaction?.gasPrice || 0),
            gasLimit: new BigNumber(ethTransaction?.gasLimit || 0),
            gasUsed: new BigNumber(ethTransaction?.gasUsed || 0),
            blockHeight: tx.blockHeight.toString() || '',
            status: ethTransaction?.success ? Statuses.success : Statuses.error,
            fromAddress: tx.fromAddress || '',
            nonce: ethTransaction?.nonce || '',
            rawInputData: ethTransaction?.input || '',
            toAddress: tx.toAddress || '',
            transactionHash: tx.transactionHash || '',
            transactionIndex: 0,
            valueHumanReadable: tx.valueHumanReadable || '',
          },
          moneyTransfers:
            tx.tokenTransfers?.map(transfer => {
              return {
                ...transfer,
                logo: transfer.imageUrl || transfer.thumbnail,
                contractAddressName: transfer.contractAddress,
                valueFormatted: transfer.valueHumanReadable,
                id: transfer.transactionHash,
                blockchainVerboseName: tx.blockchainName,
                blockchainLogo: transfer.imageUrl || transfer.thumbnail,
                fromAddressIsContract: false,
                toAddressIsContract: false,
                method: tx.method,
                methodName: tx.methodName,
                transferType: 'tokenTransfers',
                blockHeight: transfer.blockHeight.toString(),
                fromAddressName:
                  transfer.fromAddressName || transfer.fromAddress,
                toAddressName: transfer.toAddressName
                  ? transfer.toAddressName
                  : transfer.toAddress,
              };
            }) || [],
          nftTransfers:
            tx.nftTransactions?.map(transfer => {
              return {
                ...transfer,
                logo: transfer.imageUrl,
                contractAddressName: transfer.contractAddress || '',
                valueFormatted: transfer.value,
                id: transfer.transactionHash,
                blockchainVerboseName: transfer.blockchainName,
                blockchainLogo: transfer.imageUrl || transfer.thumbnail,
                fromAddressIsContract: false,
                toAddressIsContract: false,
                method: tx.method,
                methodName: tx.methodName,
                tokenId: transfer.tokenId || '',
                transferType: 'nftTransfers',
                valueDecimals: tx.valueDecimals,
                valueSymbol:
                  transfer.collectionSymbol || transfer.blockchainName,
                valueHumanReadable: transfer.value,
                contractAddress: transfer.contractAddress || '',
                direction: '?',
                collectionName:
                  transfer.collectionName ||
                  transfer.collectionSymbol ||
                  transfer.blockchainName,
                blockHeight: transfer.blockHeight.toString(),
                fromAddressName: transfer.fromAddressName
                  ? transfer.fromAddressName
                  : transfer.fromAddress,
                toAddressName: transfer.toAddressName
                  ? transfer.toAddressName
                  : transfer.toAddress,
              };
            }) || [],
        };
      },
    }),
  }),
  overrideExisting: false,
});
