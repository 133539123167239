import React, { ReactNode } from 'react';
import { CssBaseline, MuiThemeProvider, Theme } from '@material-ui/core';
import { AppLocaleProvider } from 'common/locales/AppLocaleContext/AppLocaleContext';

interface IAppProps {
  theme: Theme;
  children: ReactNode;
}

const AppBase = ({ theme, children }: IAppProps) => {
  return (
    <AppLocaleProvider>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </AppLocaleProvider>
  );
};

export default AppBase;
