import { useEffect, useState } from 'react';
import { getTimePassed } from 'common/utils/getTimePassed';
import { useInterval } from 'common/utils/useInterval';
import moment from 'moment';

const FIFTEEN_SECONDS = 15 * 1000;

export const useTxActualTimePassed = (timestamp: Date) => {
  const [actualTimePassed, setActualTimePassed] = useState('');

  const formattedDate = `${moment(timestamp.toString())
    .utc()
    .format('MMM Do, YYYY h:mm:ss A')} +UTC`;

  useEffect(() => {
    setActualTimePassed(getTimePassed(timestamp));
  }, [timestamp]);

  useInterval(() => {
    setActualTimePassed(getTimePassed(timestamp));
  }, FIFTEEN_SECONDS);

  return {
    actualTimePassed,
    formattedDate,
  };
};
