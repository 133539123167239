import React, { FC } from 'react';
import { IMoneyTransfer, INftTransfer } from 'common/types';
import { NothingFoundMargin } from 'components/NothingFound';
import {
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
} from 'components/TableComponents';
import { transfersTablesConfig } from 'modules/Address/components/constants';
import { TransfersTableRows } from 'modules/Address/components/TransfersTable/TransfersTableRows';
import { useTransactionsTableCellStyles } from 'modules/Transactions/components/TransactionsUI/TransactionsTableCellStyles';
import { RequestType } from '../Address/types';

interface IProps {
  items?: INftTransfer[] | IMoneyTransfer[];
  type: RequestType;
}

export const TxTransfersTable: FC<IProps> = ({ items = [], type }) => {
  const classes = useTransactionsTableCellStyles();
  const currentTableConfig = transfersTablesConfig.find(
    config => config.type === type,
  );

  if (!currentTableConfig) {
    return null;
  }

  return (
    <>
      {items?.length ? (
        <Table
          customCell={currentTableConfig.tableGridTemplateColumns}
          columnsCount={currentTableConfig.transfersCaptions.length}
          className={classes.txTable}
          classes={{
            table: classes.tableInner,
          }}
          withFooter
        >
          <TableHead className={classes.head}>
            {currentTableConfig.transfersCaptions.map(cell => (
              <TableHeadCell
                className={classes.headCell}
                key={cell.key}
                label={cell.label}
              />
            ))}
          </TableHead>
          <TableBody>
            <TransfersTableRows
              items={items as INftTransfer[]}
              transfersCaptions={currentTableConfig.transfersCaptions}
              type={type}
            />
          </TableBody>
        </Table>
      ) : null}

      {items?.length === 0 ? <NothingFoundMargin /> : null}
    </>
  );
};
