import React, { ReactNode } from 'react';
import cn from 'classnames';
import { TransferCaptionType } from 'common/const';
import { useTransactionsTableCellStyles } from 'modules/Transactions/components/TransactionsUI/TransactionsTableCellStyles';
import { Table, TableBody, TableHead, TableHeadCell } from '../TableComponents';

interface ITransferTableProps {
  tableGridTemplateColumns: string;
  transfersCaptions: TransferCaptionType[];
  children: ReactNode;
  className?: string;
}

export const TableView = ({
  tableGridTemplateColumns,
  transfersCaptions,
  children,
  className,
}: ITransferTableProps) => {
  const classes = useTransactionsTableCellStyles();

  return (
    <>
      <Table
        customCell={tableGridTemplateColumns}
        columnsCount={transfersCaptions.length}
        className={cn(classes.table, className)}
        withFooter
      >
        <TableHead className={classes.head}>
          {transfersCaptions.map(cell => (
            <TableHeadCell
              className={classes.headCell}
              key={cell.key}
              label={cell.label}
            />
          ))}
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </>
  );
};
