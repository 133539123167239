import React from 'react';
import { Control, useController } from 'react-hook-form';
import { AbiInputType } from 'api/getContractAbi';
import { isValidETHAddress } from 'common/utils/isValidETHAddress';
import { validateAddressResolver } from 'common/utils/validateAddressResolver';
import { Input } from 'uiKit/Input';

interface IFormInputProps {
  control: Control;
  name: string;
  type: AbiInputType;
}

export function FormInput({
  control,
  name,
  type,
}: IFormInputProps): JSX.Element {
  const { field, fieldState } = useController({
    name: name as any,
    control,
    rules: { validate: validateMethodField(type) },
  });

  return (
    <Input
      name={field.name}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      disabled={field.disabled}
      key={name}
      label={`${name} (${type})`}
      placeholder={type}
      errorText={fieldState.error?.message}
      fullWidth
    />
  );
}

function validateMethodField(type: AbiInputType) {
  return (value: string) => {
    const isAddress = type === 'address';

    if (!value) {
      return `This field is requireds`;
    }

    if (isAddress && !isValidETHAddress(value)) {
      return 'Invalid address';
    }

    return undefined;
  };
}
