import { useGetTransactionsByHashQuery } from 'api/getTransactionsByHash';

export const useTransactionsByHash = (hash: string) => {
  const request = useGetTransactionsByHashQuery({ transactionHash: hash });
  const { data, isLoading, error } = request;
  return {
    data,
    loading: isLoading,
    error,
    getTransactions: request.refetch,
  };
};
