import { TChainId } from 'api/types';
import { MethodsBox } from '../MethodsBox';
import { MethodsItem, MethodsList } from '../MethodsList';
import { WriteMethodForm } from '../WriteMethodForm';
import { useWriteMehtods } from './useWriteMehtods';

interface IWriteMethodsProps {
  chainId: TChainId;
}

export function WriteMethods({ chainId }: IWriteMethodsProps): JSX.Element {
  const { methods, isLoading } = useWriteMehtods(chainId);
  const hasMethods = !!methods.length;

  return (
    <MethodsBox isLoading={isLoading} isNoData={!hasMethods}>
      {hasMethods && (
        <MethodsList>
          {methods.map(({ name, outputs, inputs }, index) => (
            <MethodsItem number={index + 1} name={name} key={name}>
              <WriteMethodForm
                inputs={inputs}
                methodName={name}
                outputs={outputs}
                chainId={chainId}
              />
            </MethodsItem>
          ))}
        </MethodsList>
      )}
    </MethodsBox>
  );
}
