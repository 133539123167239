import React from 'react';
import { IApiBlockchainName } from 'api/types';
import classNames from 'classnames';
import imgPlaceholder from 'uiKit/ImageWithExternalSrc/placeholder.svg';
import iconArbitrium from './assets/arbitrium.svg';
import iconAvaxc from './assets/avax.svg';
import iconBase from './assets/base.svg';
import iconBsc from './assets/bsc.svg';
import iconEth from './assets/eth.svg';
import iconFlare from './assets/flare.svg';
import iconFtm from './assets/ftm.svg';
import iconGnosis from './assets/gnosis.svg';
import iconLinea from './assets/linea.svg';
import iconMetaapes from './assets/metaapes.png';
import iconNeura from './assets/neura.svg';
import iconOp from './assets/optimism.svg';
import iconPolygon from './assets/polygon.svg';
import iconPolygonZkEVM from './assets/polygon_zkevm.svg';
import iconRollux from './assets/rollux.svg';
import iconScroll from './assets/scroll.svg';
import iconStellar from './assets/stellar.svg';
import iconSys from './assets/sys.svg';
import iconZCEra from './assets/zksync_era.svg';
import { useBlockchainIconStyles } from './BlockchainIconStyles';

const blockchainLogoUrlMap: Partial<Record<IApiBlockchainName, string>> = {
  arbitrum: iconArbitrium,
  avalanche: iconAvaxc,
  bsc: iconBsc,
  eth: iconEth,
  fantom: iconFtm,
  polygon: iconPolygon,
  polygon_mumbai: iconPolygon,
  syscoin: iconSys,
  optimism: iconOp,
  optimism_testnet: iconOp,
  bas_metaapes: iconMetaapes,
  bas_metaapes_testnet: iconMetaapes,
  rollux: iconRollux,
  polygon_zkevm: iconPolygonZkEVM,
  eth_goerli: iconEth,
  avalanche_fuji: iconAvaxc,
  flare: iconFlare,
  gnosis: iconGnosis,
  base: iconBase,
  linea: iconLinea,
  scroll: iconScroll,
  stellar: iconStellar,
  zksync_era: iconZCEra,
  neura_devnet: iconNeura,
  neura_testnet_v1: iconNeura,
};

interface IBlockchainIconProps {
  type: IApiBlockchainName;
  className?: string;
  title?: string;
  logo?: string;
}

export const BlockchainIcon = ({
  type,
  className,
  title,
  logo,
}: IBlockchainIconProps) => {
  const classes = useBlockchainIconStyles();

  if (Object.prototype.hasOwnProperty.call(blockchainLogoUrlMap, type)) {
    return (
      <img
        className={classNames(className, classes.icon)}
        alt={title}
        src={blockchainLogoUrlMap[type]}
        title={title}
      />
    );
  }

  return (
    <img
      className={classNames(className, classes.icon)}
      alt={title}
      src={logo || imgPlaceholder}
      title={title}
    />
  );
};
