import React, { ReactElement } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { mainTheme } from 'common/themes/mainTheme';
import AppBase from 'components/App/AppBase';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { MainRoutes } from 'modules/router/components/MainRoutes';
import { store } from 'store';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/fonts/inter.css';
import { useWidget } from './modules/widget';

function App(): ReactElement {
  useWidget();

  return (
    <ErrorBoundary>
      <StoreProvider store={store}>
        <AppBase theme={mainTheme}>
          <ToastContainer />
          <MainRoutes />
        </AppBase>
      </StoreProvider>
    </ErrorBoundary>
  );
}

export default App;
