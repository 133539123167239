import { Tab, TabProps } from '@material-ui/core';
import { useStyledTabStyles } from './useStyledTabStyles';

interface IStyledTabProps extends TabProps {
  isHidden?: boolean;
}

export const StyledTab = ({ isHidden, hidden, ...props }: IStyledTabProps) => {
  const classes = useStyledTabStyles();
  return (
    <Tab
      className={isHidden ? classes.hidden : undefined}
      hidden={isHidden || hidden}
      {...props}
    />
  );
};
