import { TChainId } from 'api/types';
import { web3Api } from 'api/web3Api';
import { getReadContractData } from 'api/web3Sdk';
import { Address } from 'common/types';
import { RootState } from 'store';
import { getContractAbiSelector } from './getContractAbi';

interface Args {
  chainId: TChainId;
  address: Address;
  proxyAddress?: Address;
  methodName: string;
  methodArgs?: unknown;
}

interface IReadContractData {
  isError: boolean;
  data: unknown;
}

export const { useGetReadContractDataQuery, useLazyGetReadContractDataQuery } =
  web3Api.injectEndpoints({
    endpoints: build => ({
      getReadContractData: build.query<IReadContractData, Args>({
        queryFn: async (
          { address, proxyAddress, chainId, methodName, methodArgs },
          { getState },
        ) => {
          const state = getState() as RootState;

          const select = getContractAbiSelector({
            address: proxyAddress ?? address,
            chainId,
          });

          const { data: abi } = select(state);

          if (!abi) {
            throw new Error('ABI not found');
          }

          try {
            const data = await getReadContractData({
              chainId,
              address,
              abi,
              methodName,
              methodArgs,
            });

            return {
              data: {
                isError: false,
                data,
              },
            };
          } catch (error) {
            return {
              data: {
                isError: true,
                data: error,
              },
            };
          }
        },
      }),
    }),
  });
