import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DEFENSE_HEADER_HEIGHT, HEADER_HEIGHT } from '../const';

export const useTableBodyStyles = makeStyles<
  Theme,
  {
    count: number;
    customCell?: string;
    paddingCollapse?: boolean;
    defense?: boolean;
  }
>(theme => ({
  bodyWrapper: {
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
    padding: theme.spacing(0, 1.5),

    [theme.breakpoints.up('md')]: {
      height: props =>
        props.defense
          ? `calc(100% - ${DEFENSE_HEADER_HEIGHT}px)`
          : `calc(100% - ${HEADER_HEIGHT}px)`,

      padding: theme.spacing(0, 2.5),
    },
  },

  body: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: props =>
        props.customCell ? props.customCell : `repeat(${props.count}, 1fr)`,
      alignItems: 'stretch',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));
