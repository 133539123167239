import React from 'react';
import {
  StyledComponentProps,
  StyleRules,
  Theme,
  withStyles,
} from '@material-ui/core';
import NotFound from '../../../../assets/404.gif';
import { Layout } from '../../../Layout';

const styles = (theme: Theme): StyleRules => ({
  block: {
    marginBottom: theme.spacing(10),
    textAlign: 'center',
    paddingTop: theme.spacing(10),
  },
  hometitle: {
    textAlign: 'center',
    color: '#252D36',
    lineHeight: '36px',
    fontSize: '36px',
    fontWeight: 300,
    marginTop: theme.spacing(6),
  },
});

type IHomeProps = StyledComponentProps;

const PageNotFoundComponent = ({ classes = {} }: IHomeProps) => {
  return (
    <Layout>
      <div className={classes.root}>
        <div className={classes.block}>
          <img src={NotFound} alt="no fund" className={classes.notFound} />
        </div>
      </div>
    </Layout>
  );
};

const PageNotFound = withStyles(styles)(PageNotFoundComponent);

export { PageNotFound };
