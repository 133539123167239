import { useEffect, useState } from 'react';
import { useGetAddressNftsQuery } from 'api/getAddressNfts';
import { useGetNftTransfersByAddressQuery } from 'api/getNftTransfersByAddress';
import { useGetTokenTransfersByAddressQuery } from 'api/getTokenTransfersByAddress';
import { useGetTransactionsByAddressQuery } from 'api/getTransactionsByAddress';
import { IApiBlockchainName } from 'api/types';
import { IBlockchainForAddressInfo } from 'components/Blockchains/types';
import { AddressRoutesConfig } from '../../../router/const';
import { RequestType } from '../../types';

interface IRequestParams {
  walletAddress: string;
  blockchain?: IApiBlockchainName;
  pageToken?: string;
}

export function useAddressData<T>({
  blockchain,
  type,
}: {
  blockchain?: IBlockchainForAddressInfo;
  type: RequestType;
}) {
  const { address } = AddressRoutesConfig.address.useParams();
  const blockchainName = blockchain as IApiBlockchainName;
  const initParams = {
    walletAddress: address,
    blockchain: blockchainName,
  };
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [loadedData, setLoadedData] = useState<T[] | undefined>([]);
  const [requestParams, setRequestParams] =
    useState<IRequestParams>(initParams);

  const fetchByType = (type: RequestType) => {
    switch (type) {
      case 'transactions':
        return useGetTransactionsByAddressQuery(requestParams);
      case 'tokenTransfers':
        return useGetTokenTransfersByAddressQuery(requestParams);
      case 'nftTransfers':
        return useGetNftTransfersByAddressQuery(requestParams);
      case 'nftAssets':
        return useGetAddressNftsQuery({
          ...requestParams,
          address,
        });
    }
  };

  const { data, error, isLoading, isFetching, refetch, isSuccess } =
    fetchByType(type);

  const updateLoadedData = () => {
    if (isFirstLoad) {
      // @ts-ignore TODO: fix typings for data?.items
      setLoadedData(data?.items);
    } else {
      // @ts-ignore TODO: fix typings for prevState
      setLoadedData(prevState => {
        if (prevState === data?.items) {
          return prevState;
        }
        return [...(prevState || []), ...(data?.items || [])];
      });
    }
  };

  useEffect(() => {
    if (isFirstLoad) {
      setRequestParams(prevState => ({
        ...prevState,
        pageToken: undefined,
        nextPageToken: undefined,
      }));
      setLoadedData([]);
      if (isSuccess) {
        updateLoadedData();
      }
    }
  }, [isFetching]);

  useEffect(() => {
    setIsFirstLoad(true);
    setRequestParams(initParams);
  }, [blockchain, address, type]);

  useEffect(() => {
    updateLoadedData();
  }, [data?.items]);

  const loadMore = () => {
    setIsFirstLoad(false);
    setRequestParams(prevState => ({
      ...prevState,
      pageToken: data?.nextPageToken,
      nextPageToken: data?.nextPageToken,
    }));
  };

  return {
    error,
    loadMore,
    address,
    loading: isLoading || isFetching,
    isFetching,
    items: loadedData,
    hasNextPage: Boolean(data?.nextPageToken),
    refetch,
  };
}
