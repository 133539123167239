import React, { ReactNode } from 'react';
import { Box, Typography } from '@material-ui/core';
import { IApiBlockchainName } from 'api/types';
import { ITransaction } from 'common/types';
import { t } from 'common/utils/intl';
import { Layout } from 'modules/Layout';
import { Container } from 'uiKit/Container';
import { PageTop } from '../../../../components/PageTop';
import emptyIcon from './assets/emptyIcon.png';
import { TransactionsUi } from './TransactionsUi';
import { useTransactionsStyles } from './TransactionsStyles';

interface ITransactionsPageProps {
  blockchain?: IApiBlockchainName;
  setBlockchainFilter?: (b: IApiBlockchainName | undefined) => void;
  blockHeight?: string;
  error?: ReactNode;
  isLoading?: boolean;
  data?: ITransaction[];
}

// feature for blocks by chain filtering
const isTransactionsFilterAvailable = true;

export const TransactionsPage = ({
  blockchain,
  setBlockchainFilter,
  blockHeight,
  error,
  isLoading = false,
  data,
}: ITransactionsPageProps) => {
  const classes = useTransactionsStyles();

  return (
    <Layout>
      <section className={classes.root}>
        <Container>
          <PageTop>
            <Typography variant="h2" className={classes.title}>
              {t('transactions.title')}
            </Typography>
          </PageTop>
          {data?.length ? (
            <TransactionsUi
              isTransactionsFilterAvailable={isTransactionsFilterAvailable}
              blockchain={blockchain}
              setBlockchainFilter={setBlockchainFilter}
              blockHeight={blockHeight}
              error={error}
              isLoading={isLoading}
              data={data}
            />
          ) : (
            <Box className={classes.emptyContainer}>
              <Typography variant="h2">
                {t('transactions.emptyTitle')}
              </Typography>
              <Typography variant="body1" className={classes.emptyDesc1}>
                <img
                  src={emptyIcon}
                  title="empty"
                  className={classes.emptyIcon}
                />
                {t('transactions.emptyDesc1')}
              </Typography>
              <Typography variant="body1" className={classes.emptyDesc2}>
                {t('transactions.emptyDesc2')}
              </Typography>
            </Box>
          )}
        </Container>
      </section>
    </Layout>
  );
};
