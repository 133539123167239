import React, { ReactNode } from 'react';
import { PATH } from 'modules/router/const';
import { Container } from 'uiKit/Container';
import { ErrorMessage } from '../ErrorMessage';
import { useErrorPageStyles } from './ErrorPageStyles';

interface IErrorPageProps {
  children?: ReactNode;
}

export const ErrorPage = ({ children }: IErrorPageProps) => {
  const classes = useErrorPageStyles();

  const handleErrorButtonClick = () => {
    window.location.replace(PATH.HOME);
  };

  return (
    <main className={classes.root}>
      <Container className={classes.container} maxWidth={false}>
        <ErrorMessage
          className={classes.errorMessage}
          title={'An error has occurred'}
          text={'Try again later'}
          btnText={'Go to main page'}
          onClick={handleErrorButtonClick}
        />
        {children}
      </Container>
    </main>
  );
};
