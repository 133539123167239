import React from 'react';
import { Typography } from '@material-ui/core';
import { useBlockchainName } from 'common/hooks/useBlockchainName';
import { t } from 'common/utils/intl';
import { BlockchainIcon } from 'components/BlockchainIcon';
import { ErrorMessage } from 'components/ErrorMessage';
import { BlocksUi } from 'modules/Blocks/BlocksUi';
import { Layout } from 'modules/Layout';
import { useLatestBlocks } from 'modules/Lobby/components/Latest/useLatestBlocks';
import { useLatestTransactions } from 'modules/Lobby/components/Latest/useLatestTransactions';
import { ChainRoutesConfig } from 'modules/router/const';
import { TransactionsUi } from 'modules/Transactions/components/TransactionsUI/TransactionsUi';
import { IS_EMBEDDED } from '../../common/const';
import { PageTop } from '../../components/PageTop';
import { BlockchainStats } from '../BlockchainStats';
import { useWidgetRouteChange } from '../widget';
import { useChainDetailsStyles } from './ChainDetainsStyles';

export const ChainDetails = () => {
  const { chain } = ChainRoutesConfig.chainDetails.useParams();

  const {
    data: dataBlocks,
    loading: loadingBlocks,
    error: errorBlocks,
    blockchainNames: blockchainNamesBlocks,
    setBlockchainNames: setBlockchainNamesBlocks,
    refetch: refetchBlocks,
  } = useLatestBlocks(chain);

  const {
    data: dataTransactions,
    loading: loadingTransactions,
    error: errorTransactions,
    blockchain: blockchainTransactions,
    setBlockchain: setBlockchainTransactions,
    refetch: refetchTransactions,
  } = useLatestTransactions(chain);

  const classes = useChainDetailsStyles();
  const chainName = useBlockchainName(chain);

  const renderedError = (refetch: () => void) => {
    return (
      <ErrorMessage text={t('errorMessages.requestError')} onClick={refetch} />
    );
  };

  useWidgetRouteChange('home', '', blockchainNamesBlocks);

  return (
    <Layout>
      <div className={classes.wrapper}>
        <PageTop className={classes.top}>
          <Typography variant="h1" className={classes.title}>
            <BlockchainIcon type={chain} className={classes.icon} /> {chainName}
          </Typography>
        </PageTop>
        <BlockchainStats blockchain={chain} />
        <div className={classes.itemsWrapper}>
          <div className={classes.item}>
            <Typography variant="h2" className={classes.title}>
              {t('latest.blocks.title')}
            </Typography>
            <BlocksUi
              className={classes.table}
              isBlocksFilterAvailable={false}
              items={dataBlocks?.items}
              isLoading={loadingBlocks}
              error={errorBlocks && renderedError(refetchBlocks)}
              blockchainNames={blockchainNamesBlocks}
              setBlockchainNames={setBlockchainNamesBlocks}
            />
          </div>
          {!IS_EMBEDDED && (
            <div className={classes.item}>
              <Typography variant="h2" className={classes.title}>
                {t('latest.transactions.title')}
              </Typography>
              <TransactionsUi
                className={classes.table}
                isTransactionsFilterAvailable={false}
                data={dataTransactions?.items}
                isLoading={loadingTransactions}
                error={errorTransactions && renderedError(refetchTransactions)}
                blockchain={blockchainTransactions}
                setBlockchainFilter={setBlockchainTransactions}
              />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};
