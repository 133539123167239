import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PALETTE } from 'common/themes/mainTheme';

export const useTableShowOlderStyles = makeStyles<Theme>(theme => ({
  root: {
    color: theme.palette.text.secondary,
    background: PALETTE.background.paper,
    padding: theme.spacing(2, 2),
    borderLeft: '1px solid #E6E6E6',
    borderRight: '1px solid #E6E6E6',
    borderBottom: '1px solid #E6E6E6',
    justifyContent: 'center',
    display: 'flex',
  },
  showOlder: {
    color: '#808692',
    width: 260,
    height: 36,
    borderRadius: '6px',
    background: PALETTE.background.paper,
    border: '1px solid #E6E6E6',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));
