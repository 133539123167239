import { Address } from 'common/types';
import { Web3 } from 'web3';

const ZERO_ADDRESS: Address =
  '0x0000000000000000000000000000000000000000000000000000000000000000';

/**
 * See https://eips.ethereum.org/EIPS/eip-1967
 */
const SPECIFIC_STORAGE_SLOT: Address =
  '0x360894a13ba1a3210667c828492db98dca3e2076cc3735a920a3ca505d382bbc';

/**
 * Extract the last 40 characters (20 bytes) from the storage content
 */
const LAST_CHARS = 40;

/**
 * For more information, see https://medium.com/@MRG_DevWeb3.co/retrieving-implementation-contract-addresses-from-proxy-contracts-in-evm-networks-934cd2c659d5
 *
 * @returns the address of the contract that the proxy is pointing to
 */
export async function getProxyContractAddress(
  web3: Web3,
  address: Address,
): Promise<Address> {
  const implementationStorage = await web3.eth.getStorageAt(
    address,
    SPECIFIC_STORAGE_SLOT,
  );

  if (implementationStorage === ZERO_ADDRESS) {
    throw new Error('Proxy contract address not found.');
  }

  const implementationAddressHex =
    '0x' + implementationStorage.slice(-LAST_CHARS);

  // Validate the address
  const currentImplementationAddress = web3.utils.toChecksumAddress(
    implementationAddressHex,
  );

  return currentImplementationAddress;
}
