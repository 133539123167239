import { IAbiItem } from 'api/getContractAbi';
import { TChainId } from 'api/types';
import { Address } from 'common/types';
import { useContractMethods } from 'modules/Address/hooks/useContractMethods';
import { isWriteMethod } from 'modules/Address/utils';

interface IUseProxyWriteMehtods {
  methods: IAbiItem[];
  isLoading: boolean;
  proxyAddress?: Address;
}

export function useProxyWriteMehtods(
  chainId?: TChainId,
): IUseProxyWriteMehtods {
  const {
    proxyContractABI,
    proxyAddress,
    isProxyAddressLoading,
    isProxyContractAbiLoading,
  } = useContractMethods(chainId);

  const writeMethods = proxyContractABI.filter(isWriteMethod);

  return {
    methods: writeMethods,
    proxyAddress,
    isLoading: isProxyAddressLoading || isProxyContractAbiLoading,
  };
}
