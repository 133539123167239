import { ITransaction } from 'common/types';
import { explorerRpc } from './explorerRpc';
import { IApiBlockchainName } from './types';
import { buildRpcRequest } from './utils/buildRpcRequest';
import { mapTransactions } from './utils/mapTransactions';

interface ITransactionsQueryV2 {
  lastTransaction?: ITransaction;
  items?: ITransaction[];
}

interface ITransactionsRequestParams {
  blockchainNames?: IApiBlockchainName | IApiBlockchainName[];
  size?: number;
}

export const { useGetLatestTransactionsQuery } = explorerRpc.injectEndpoints({
  endpoints: build => ({
    getLatestTransactions: build.query<
      ITransactionsQueryV2,
      ITransactionsRequestParams
    >({
      query: ({ blockchainNames, size }) =>
        buildRpcRequest({
          method: 'explorer_getLatestTransactions',
          params: { blockchainNames, size },
        }),
      transformResponse: mapTransactions,
    }),
  }),
  overrideExisting: false,
});
