import {
  addressTransactionsCaptions,
  moneyTransfersCaptions,
  nftTransactionsCaptions,
} from 'common/const';
import { ITransfersTableConfig } from '../types';

export const transfersTablesConfig: ITransfersTableConfig[] = [
  {
    type: 'transactions',
    transfersCaptions: addressTransactionsCaptions,
    tableGridTemplateColumns: '1.2fr 1fr 0.8fr 1fr 0.8fr 75px 0.8fr 1.4fr',
  },
  {
    type: 'tokenTransfers',
    transfersCaptions: moneyTransfersCaptions,
    tableGridTemplateColumns: '1.2fr 0.8fr 1fr 75px 1fr 1.2fr 0.8fr',
  },
  {
    type: 'nftTransfers',
    transfersCaptions: nftTransactionsCaptions,
    tableGridTemplateColumns: '1.1fr 0.8fr 1fr 75px 1fr 70px 0.6fr 1.1fr 1.2fr',
  },
];
