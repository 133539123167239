import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { IApiBlockchainName } from 'api/types';
import { useBlockchainName } from 'common/hooks/useBlockchainName';
import { EthBlockDetailsMap } from 'common/types';
import { t } from 'common/utils/intl';
import { BlockchainIcon } from 'components/BlockchainIcon';
import {
  Table,
  TableBody,
  TableBodyCell,
  TableRow,
} from 'components/TableComponents';
import moment from 'moment';
import { QuestionIcon } from 'uiKit/Icons/QuestionIcon';
import { Tooltip } from 'uiKit/Tooltip/Tooltip';
import {
  AddressRoutesConfig,
  BlockRoutesConfig,
  ChainRoutesConfig,
  TransactionsRouteConfig,
} from '../../../router/const';
import { useBlockStyles } from '../BlockUI/BlockStyles';

interface IEthDetailsProps extends EthBlockDetailsMap {
  blockchainName: IApiBlockchainName;
}

export const EthDetails = ({
  blockchainName,
  blockHash,
  blockHeight,
  extraData,
  gasUsed,
  mineAddress,
  parentHash,
  sha3Uncles,
  size,
  stateRoot,
  timestamp,
  transactionsCount,
  nonce,
}: IEthDetailsProps) => {
  const classes = useBlockStyles();
  const chainName = useBlockchainName(blockchainName);
  const formattedDate = `${moment(timestamp.toString())
    .utc()
    .format('MMM Do, YYYY h:mm:ss A')} +UTC`;

  const previousBlockHeight = `${+blockHeight - 1}`;

  return (
    <Table columnsCount={2} customCell="0.3fr 0.7fr">
      <TableBody>
        <TableRow>
          <TableBodyCell>{t('block.ethDetails.blockchain')}</TableBodyCell>

          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <BlockchainIcon
                className={classes.blockchainIcon}
                type={blockchainName}
              />
              <Link
                to={ChainRoutesConfig.chainDetails.generatePath(blockchainName)}
              >
                {`${chainName} (${blockchainName})`}
              </Link>
            </Box>
          </TableBodyCell>
        </TableRow>

        <TableRow>
          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <Tooltip
                title={t('block.ethDetails.blockHeightTooltip')}
                placement="top"
              >
                <QuestionIcon className={classes.questionIcon} />
              </Tooltip>
              {t('block.ethDetails.blockHeightLabel')}
            </Box>
          </TableBodyCell>

          <TableBodyCell>{blockHeight}</TableBodyCell>
        </TableRow>

        <TableRow>
          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <Tooltip
                title={t('block.ethDetails.timestampTooltip')}
                placement="top"
              >
                <QuestionIcon className={classes.questionIcon} />
              </Tooltip>
              {t('block.ethDetails.timestampLabel')}
            </Box>
          </TableBodyCell>

          <TableBodyCell>{formattedDate}</TableBodyCell>
        </TableRow>

        <TableRow>
          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <Tooltip
                title={t('block.ethDetails.quantitiesTooltip')}
                placement="top"
              >
                <QuestionIcon className={classes.questionIcon} />
              </Tooltip>
              {t('block.ethDetails.quantitiesLabel')}
            </Box>
          </TableBodyCell>

          <TableBodyCell>
            {transactionsCount === '0' ? (
              0
            ) : (
              <Link
                to={TransactionsRouteConfig.transactionsByBlock.generatePath(
                  blockchainName,
                  blockHeight,
                )}
              >
                {transactionsCount}
              </Link>
            )}
          </TableBodyCell>
        </TableRow>

        <TableRow>
          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <Tooltip
                title={t('block.ethDetails.mineAddressTooltip')}
                placement="top"
              >
                <QuestionIcon className={classes.questionIcon} />
              </Tooltip>
              {t('block.ethDetails.mineAddressLabel')}
            </Box>
          </TableBodyCell>

          <TableBodyCell>
            <Link to={AddressRoutesConfig.address.generatePath(mineAddress)}>
              {mineAddress}
            </Link>
          </TableBodyCell>
        </TableRow>

        <TableRow>
          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <Tooltip
                title={t('block.ethDetails.sizeTooltip')}
                placement="top"
              >
                <QuestionIcon className={classes.questionIcon} />
              </Tooltip>
              {t('block.ethDetails.sizeLabel')}
            </Box>
          </TableBodyCell>

          <TableBodyCell>{size.toFormat()}</TableBodyCell>
        </TableRow>

        <TableRow>
          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <Tooltip
                title={t('block.ethDetails.gasUsedTooltip')}
                placement="top"
              >
                <QuestionIcon className={classes.questionIcon} />
              </Tooltip>
              {t('block.ethDetails.gasUsedLabel')}
            </Box>
          </TableBodyCell>

          <TableBodyCell>{gasUsed.toFormat()}</TableBodyCell>
        </TableRow>

        <TableRow>
          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <Tooltip
                title={t('block.ethDetails.extraDataTooltip')}
                placement="top"
              >
                <QuestionIcon className={classes.questionIcon} />
              </Tooltip>
              {t('block.ethDetails.extraDataLabel')}
            </Box>
          </TableBodyCell>

          <TableBodyCell>
            <span className={classes.breakWord}>{extraData || 'no data'}</span>
          </TableBodyCell>
        </TableRow>

        <TableRow>
          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <Tooltip
                title={t('block.ethDetails.blockHashTooltip')}
                placement="top"
              >
                <QuestionIcon className={classes.questionIcon} />
              </Tooltip>
              {t('block.ethDetails.blockHashLabel')}
            </Box>
          </TableBodyCell>

          <TableBodyCell>
            <span className={classes.breakWord}>{blockHash}</span>
          </TableBodyCell>
        </TableRow>

        <TableRow>
          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <Tooltip
                title={t('block.ethDetails.parentHashTooltip')}
                placement="top"
              >
                <QuestionIcon className={classes.questionIcon} />
              </Tooltip>
              {t('block.ethDetails.parentHashLabel')}
            </Box>
          </TableBodyCell>

          <TableBodyCell>
            <Link
              className={classes.breakWord}
              to={BlockRoutesConfig.blockDetails.generatePath(
                blockchainName,
                previousBlockHeight,
              )}
            >
              {parentHash}
            </Link>
          </TableBodyCell>
        </TableRow>

        <TableRow>
          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <Tooltip
                title={`${t('block.ethDetails.sha3Tooltip1')} ${chainName} ${t(
                  'block.ethDetails.sha3Tooltip2',
                )}`}
                placement="top"
              >
                <QuestionIcon className={classes.questionIcon} />
              </Tooltip>
              {t('block.ethDetails.sha3Label')}
            </Box>
          </TableBodyCell>

          <TableBodyCell>
            <span className={classes.breakWord}>{sha3Uncles}</span>
          </TableBodyCell>
        </TableRow>

        <TableRow>
          <TableBodyCell>
            <Box display="flex" alignItems="center">
              <Tooltip
                title={t('block.ethDetails.stateRootTooltip')}
                placement="top"
              >
                <QuestionIcon className={classes.questionIcon} />
              </Tooltip>
              {t('block.ethDetails.stateRootLabel')}
            </Box>
          </TableBodyCell>

          <TableBodyCell>
            <span className={classes.breakWord}>{stateRoot}</span>
          </TableBodyCell>
        </TableRow>

        {Boolean(nonce) && (
          <TableRow>
            <TableBodyCell>
              <Box display="flex" alignItems="center">
                <Tooltip
                  title={t('block.ethDetails.nonceTooltip')}
                  placement="top"
                >
                  <QuestionIcon className={classes.questionIcon} />
                </Tooltip>
                {t('block.ethDetails.nonceLabel')}
              </Box>
            </TableBodyCell>

            <TableBodyCell>{nonce}</TableBodyCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
