import { Box, useTheme } from '@material-ui/core';
import { IAbiInput } from 'api/getContractAbi';
import { TChainId } from 'api/types';
import { ACTION_CACHE_LONG } from 'common/const';
import { useGetReadContractDataQuery } from 'modules/Address/actions/getReadContractData';
import { AddressRoutesConfig } from 'modules/router/const';
import { MethodOutput } from '../MethodOutput';

interface IReadMethodOutputProps {
  methodName: string;
  outputs?: IAbiInput[];
  shouldRequestData?: boolean;
  chainId: TChainId;
  proxyAddress?: string;
}

export function ReadMethodOutput({
  methodName,
  outputs = [],
  shouldRequestData,
  chainId,
  proxyAddress,
}: IReadMethodOutputProps): JSX.Element {
  const { address } = AddressRoutesConfig.address.useParams();
  const theme = useTheme();

  const { data: contractData, isLoading } = useGetReadContractDataQuery(
    { address, chainId, methodName, proxyAddress },
    { refetchOnMountOrArgChange: ACTION_CACHE_LONG, skip: !shouldRequestData },
  );

  const { data, isError } = contractData || {};

  const parsedData = parseData(data);

  return (
    <>
      {outputs.map(output => (
        <MethodOutput
          key={output.name}
          name={output.name}
          type={output.type}
          value={isError ? undefined : parsedData}
          isLoading={isLoading}
        />
      ))}

      {isError && <Box color={theme.palette.error.main}>{parsedData}</Box>}
    </>
  );
}

function parseData(data: unknown) {
  return `${data}`;
}
