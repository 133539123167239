import { IApiBlockchainName } from 'api/types';
import { CHAIN_NAME } from '../const';
import { t } from '../utils/intl';

export const useBlockchainName = (chain?: IApiBlockchainName) => {
  if (!chain) return '';
  if (CHAIN_NAME[chain]) {
    return t(CHAIN_NAME[chain]);
  }
  return chain;
};
