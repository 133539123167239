import React from 'react';
import { Link } from 'react-router-dom';
import { IApiBlockchainName } from 'api/types';
import cn from 'classnames';
import { TransferCaptionType } from 'common/const';
import { useBlockchainName } from 'common/hooks/useBlockchainName';
import { IBalanceMap } from 'common/types';
import { normalizeBigNumber } from 'common/utils/format';
import { t } from 'common/utils/intl';
import { BlockchainIcon } from 'components/BlockchainIcon';
import {
  TableBodyCell,
  TableCellAddress,
  TableRow,
} from 'components/TableComponents';
import { ChainRoutesConfig } from 'modules/router/const';
import { ImageWithExternalSrc } from 'uiKit/ImageWithExternalSrc/ImageWithExternalSrc';
import { useBalanceStyles } from './BalanceStyles';

interface IBalanceRowProps {
  asset: IBalanceMap;
  assetsCaptions: TransferCaptionType[];
  groupName: IApiBlockchainName;
}

export const BalanceRow = ({
  asset,
  assetsCaptions,
  groupName,
}: IBalanceRowProps) => {
  const classes = useBalanceStyles();
  const chainName = useBlockchainName(groupName);

  return (
    <TableRow>
      {assetsCaptions.map(caption => {
        const normalizedUsdPrice = normalizeBigNumber(asset.usdPrice);
        const normalizedUsdValue = normalizeBigNumber(asset.usdValue);

        switch (caption.key) {
          case 'asset':
            return (
              <TableBodyCell
                key={caption.key}
                className={cn(classes.tableCell)}
                label={caption.label}
              >
                <div className={classes.assetLogoCell}>
                  <ImageWithExternalSrc
                    className={classes.logo}
                    alt="icon"
                    src={asset.logo}
                  />
                  <span className={classes.assetText}>{asset.valueSymbol}</span>
                </div>
              </TableBodyCell>
            );
          case 'chain':
            return (
              <TableBodyCell
                key={caption.key}
                className={classes.tableCell}
                label={caption.label}
              >
                <div className={classes.assetLogoCell}>
                  <BlockchainIcon
                    className={classes.logo}
                    type={groupName as IApiBlockchainName}
                    title={groupName}
                  />
                  <Link
                    to={ChainRoutesConfig.chainDetails.generatePath(groupName)}
                  >
                    {chainName || groupName}
                  </Link>
                </div>
              </TableBodyCell>
            );
          case 'address':
            return (
              <TableCellAddress
                key={caption.key}
                label={caption.label}
                address={asset.contractAddress}
                addressName={asset.contractAddress}
              />
            );
          case 'quantity':
            return (
              <TableBodyCell
                key={caption.key}
                className={classes.tableCell}
                label={caption.label}
                tooltipText={t('unitToken', {
                  value: asset.valueFormatted,
                  unit: asset.valueSymbol,
                })}
                tooltipPlacement="bottom-start"
              >
                {t('unitToken', {
                  value: asset.valueFormatted,
                  unit: undefined,
                })}
              </TableBodyCell>
            );
          case 'price':
            return (
              <TableBodyCell
                key={caption.key}
                className={classes.tableCell}
                label={caption.label}
                tooltipText={normalizedUsdPrice}
                tooltipPlacement="bottom-start"
              >
                {t('unitUSD', {
                  value: asset.usdPrice,
                })}
              </TableBodyCell>
            );
          case 'usd':
            return (
              <TableBodyCell
                key={caption.key}
                className={classes.tableCell}
                label={caption.label}
                tooltipText={normalizedUsdValue}
                tooltipPlacement="bottom-start"
              >
                {t('unitUSD', {
                  value: asset.usdValue,
                })}
              </TableBodyCell>
            );
        }
      })}
    </TableRow>
  );
};
