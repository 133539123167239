import {
  CUSTOM_CHAINS,
  IS_BROWSER_HISTORY_DISABLED,
  IS_EMBEDDED,
  IS_SCROLL_DISABLED,
  SHOW_NFT_TRANSACTIONS,
  SHOW_NFTS,
} from './const';

const IS_NEURA = CUSTOM_CHAINS?.some(
  item => item === 'neura_devnet' || item === 'neura_testnet_v1',
);

export const featureConfig = {
  showNFT: SHOW_NFTS,
  /**
   * Disable scroll on the page in case of the widget mode
   */
  disableScroll: IS_SCROLL_DISABLED,
  isContractMethodsActive:
    process.env.REACT_APP_IS_CONTRACT_METHOD_ACTIVE === 'true',
  showNftTransactions: SHOW_NFT_TRANSACTIONS,
  isBrowserHistoryDisabled: IS_BROWSER_HISTORY_DISABLED,
  isNeura: IS_NEURA,
  isSearchInsidePageDisplayed: IS_EMBEDDED && !IS_NEURA,
} as const;
