import { TChainId } from 'api/types';
import { ReadProvider } from './ReadProvider';
import { getRpcUrl } from './utils/getRpcUrl';

const providers: Record<number, ReadProvider> = {};

export function getReadProvider(chainId: TChainId): ReadProvider {
  if (providers[chainId]) {
    return providers[chainId];
  }

  const url = getRpcUrl(chainId);
  const provider = new ReadProvider(url, chainId);
  providers[chainId] = provider;

  return provider;
}
