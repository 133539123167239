import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import intl from 'react-intl-universal';
import { Locale } from '../../types';
import { LOCALES_DATA } from '../locales';

const LocaleContext = createContext<Locale | null>(null);
const LocaleUpdateContext = createContext<((locale: Locale) => void) | null>(
  null,
);

export function useLocale() {
  return useContext(LocaleContext);
}

export function useLocaleUpdate() {
  return useContext(LocaleUpdateContext);
}

interface IAppLocaleContext {
  children: ReactNode;
}

export function AppLocaleProvider({ children }: IAppLocaleContext) {
  const [locale, setLocale] = useState<Locale>(Locale.en);
  const [initDone, setInitDone] = useState(false);

  useEffect(() => {
    setInitDone(false);
    intl
      .init({
        currentLocale: locale,
        locales: LOCALES_DATA,
        fallbackLocale: Locale.en,
        warningHandler: console.warn,
        commonLocaleDataUrls: {
          en: 'https://g.alicdn.com/react-intl-universal/locale-data/1.0.0/en.js',
          zh: 'https://g.alicdn.com/react-intl-universal/locale-data/1.0.0/zh.js',
        },
      })
      .then(() => {
        setInitDone(true);
      });
  }, [locale]);

  const updateLocale = useCallback((newLocale: Locale) => {
    setLocale(newLocale);
  }, []);

  return (
    <LocaleContext.Provider value={locale}>
      <LocaleUpdateContext.Provider value={updateLocale}>
        {initDone && children}
      </LocaleUpdateContext.Provider>
    </LocaleContext.Provider>
  );
}
