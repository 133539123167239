import { skipToken } from '@reduxjs/toolkit/query';
import { IAbiItem } from 'api/getContractAbi';
import { TChainId } from 'api/types';
import { getChainIdByName } from 'api/utils/getChainIdByName';
import { ACTION_CACHE_LONG } from 'common/const';
import { featureConfig } from 'common/featureConfig';
import { AddressRoutesConfig } from 'modules/router/const';
import { useGetContractAbiQuery } from '../actions/getContractAbi';
import { useGetProxyContractAddressQuery } from '../actions/getProxyContractAddress';

interface IUseContractMethods {
  proxyAddress?: string;
  contractABI: IAbiItem[];
  proxyContractABI: IAbiItem[];
  isContractAbiLoading: boolean;
  isProxyAddressLoading: boolean;
  isProxyContractAbiLoading: boolean;
}

export function useContractMethods(chainId?: TChainId): IUseContractMethods {
  const { address } = AddressRoutesConfig.address.useParams();

  // 1. get contract ABI
  const { data: contractABI = [], isLoading: isContractAbiLoading } =
    useGetContractAbiQuery(chainId ? { address, chainId } : skipToken, {
      refetchOnMountOrArgChange: ACTION_CACHE_LONG,
      skip: !featureConfig.isContractMethodsActive,
    });

  const hasContractABI = !!contractABI.length;

  // 2. if we have contract ABI, get proxy contract address
  const { data: proxyAddress, isLoading: isProxyAddressLoading } =
    useGetProxyContractAddressQuery(
      chainId ? { address, chainId } : skipToken,
      {
        refetchOnMountOrArgChange: ACTION_CACHE_LONG,
        skip: !featureConfig.isContractMethodsActive || !hasContractABI,
      },
    );

  // 3. if we have proxy contract address, get proxy contract ABI
  const { data: proxyContractABI = [], isLoading: isProxyContractAbiLoading } =
    useGetContractAbiQuery(
      chainId && proxyAddress ? { address: proxyAddress, chainId } : skipToken,
      {
        refetchOnMountOrArgChange: ACTION_CACHE_LONG,
        skip: !featureConfig.isContractMethodsActive,
      },
    );

  return {
    proxyAddress,
    contractABI,
    proxyContractABI,
    isContractAbiLoading,
    isProxyAddressLoading,
    isProxyContractAbiLoading,
  };
}
