import React, { useMemo } from 'react';
import { uid } from 'react-uid';
import Skeleton from '@material-ui/lab/Skeleton';
import classNames from 'classnames';
import { useLatestStyles } from './LatestStyles';

export const LatestItemSkeletons = () => {
  const classes = useLatestStyles();

  const renderedSkeletons = useMemo(
    () =>
      Array(7)
        .fill(0)
        .map((_, i) => (
          <li
            className={classNames(classes.item, classes.itemSkeleton)}
            key={uid(i)}
          >
            <Skeleton variant="circle" width={36} height={36} />

            <div>
              <Skeleton variant="text" width="60%" />
              <Skeleton variant="text" width="40%" />
            </div>
          </li>
        )),
    [classes.item, classes.itemSkeleton],
  );

  return <ul className={classes.list}>{renderedSkeletons}</ul>;
};
