import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useTransactionsStyles = makeStyles<Theme>(theme => ({
  root: {
    padding: theme.spacing(5, 0, 7),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(7.5, 0),
    },
  },
  tableTransactions: {
    paddingTop: 20,
    borderRadius: 20,
    borderTopColor: '#E6E6E6',
  },
  tableWithPagination: {
    [theme.breakpoints.up('md')]: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  title: {
    fontWeight: 700,
  },
  tableSkeleton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(8),
    borderRadius: 20,
  },
  emptyContainer: {
    padding: theme.spacing(4, 2.5),
    border: '1px solid #E3E8F0',
    background: theme.palette.background.paper,
    borderRadius: 20,
  },
  emptyDesc1: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  emptyDesc2: {
    marginTop: theme.spacing(0.5),
  },
  emptyIcon: {
    width: 20,
    height: 20,
  },
}));
