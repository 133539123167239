import React from 'react';
import { Box, Paper, PaperProps, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { t } from 'common/utils/intl';
import { Button } from 'uiKit/Button';
import { useErrorMessageStyles } from './ErrorMessageStyles';

interface IErrorMessageProps extends PaperProps {
  title?: string;
  text?: string;
  onClick?: () => void;
  btnText?: string;
  noBorderRadius?: boolean;
  noBorders?: boolean;
}

export const ErrorMessage = ({
  className,
  title,
  text,
  btnText,
  onClick,
  children,
  noBorderRadius,
  noBorders,
  ...restProps
}: IErrorMessageProps) => {
  const classes = useErrorMessageStyles();

  return (
    <Paper
      variant="outlined"
      className={classNames(
        classes.root,
        className,
        noBorderRadius && classes.noBorderRadius,
        noBorders && classes.noBorders,
      )}
      {...restProps}
    >
      <Typography className={classes.title} variant="h2">
        {title || t('errorMessages.error')}
      </Typography>

      {text && <Typography>{text}</Typography>}

      {children}

      {typeof onClick === 'function' && (
        <Box mt={4}>
          <Button onClick={onClick}>{btnText || 'Try to reload'}</Button>
        </Box>
      )}
    </Paper>
  );
};
