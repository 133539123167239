import { FMT_BYTES, FMT_NUMBER, Web3 } from 'web3';
import { connectorsConfig } from './connectorsConfig';
import { IProvider, OProviderEvents, OWalletId, TWalletId } from './types';
import { isEventProvider } from './utils/isEventProvider';

const defaultConnector = OWalletId.injected;
const CHAIN_NOT_ADDED_ERROR_CODE = 4902;
const USER_REJECTED_ERROR_CODE = 4001;
const INTERNAL_ERROR = -32603;

const USER_REJECTED_ERROR_MSG = 'Network add denied by user';

const SWITCH_NETWORK_ERROR_MSG =
  'Switch network error. Perhaps the network is not added to your wallet.';

const events = Object.values(OProviderEvents);

export class WriteProvider implements IProvider {
  private static instance: WriteProvider;

  private provider = (window as any).ethereum;

  private _web3?: Web3;

  chainId = 0;

  public get web3(): Web3 {
    if (!this._web3) {
      throw new Error('Web3 not initialized');
    }
    return this._web3;
  }

  private _currentAccount: string | null = null;

  public get currentAccount(): string {
    return this._currentAccount || '';
  }

  public set currentAccount(addr: string | null) {
    this._currentAccount = addr;
  }

  static getInstance(): WriteProvider {
    if (!WriteProvider.instance) {
      WriteProvider.instance = new WriteProvider();
    }

    return WriteProvider.instance;
  }

  public async connect(walletId?: TWalletId): Promise<boolean> {
    const connectProvider = connectorsConfig[walletId ?? defaultConnector];

    try {
      const provider = await connectProvider();
      const web3 = new Web3(provider);
      this.provider = provider;
      this._web3 = web3;
    } catch (error) {
      console.error(error);
      throw new Error(`Failed to connect wallet.`);
    }

    this.chainId = await this.web3.eth.getChainId({
      number: FMT_NUMBER.NUMBER,
      bytes: FMT_BYTES.HEX,
    });

    await this.getUnlockedAccounts();
    this.addWalletEventsListener();

    return true;
  }

  /**
   * Disconnects the web3 provider
   */
  public disconnect() {
    this._web3 = undefined;
    this.currentAccount = null;
    this.removeWalletEventsListener();
  }

  private async getUnlockedAccounts(): Promise<string[]> {
    const unlockedAccounts: string[] = await this.web3.eth.getAccounts();

    const [currentAccount] = unlockedAccounts;
    if (!unlockedAccounts.length || !currentAccount) {
      throw new Error('Unable to detect unlocked MetaMask account');
    }
    this.currentAccount = currentAccount;
    return unlockedAccounts;
  }

  public isConnected(): boolean {
    return !!this._web3;
  }

  public createContract(abi: any, address: string) {
    return new this.web3.eth.Contract(abi, address);
  }

  public async switchNetwork(chainId: number): Promise<any> {
    const { provider } = this;

    const hexChainId = this.web3.utils.toHex(chainId);

    try {
      return await provider.request({
        /**
         * Method [API](https://docs.metamask.io/guide/rpc-api.html#wallet-switchethereumchain)
         */
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: hexChainId }],
      });
    } catch (switchError) {
      const errorCode = (switchError as { code?: number | string }).code;

      if (
        errorCode === CHAIN_NOT_ADDED_ERROR_CODE ||
        errorCode === INTERNAL_ERROR
      ) {
        throw new Error(SWITCH_NETWORK_ERROR_MSG);
      }

      if (errorCode === USER_REJECTED_ERROR_CODE) {
        throw new Error(USER_REJECTED_ERROR_MSG);
      }

      // handle other "switch" errors
      throw new Error(SWITCH_NETWORK_ERROR_MSG);
    }
  }

  /**
   * Adds event listeners for the wallet
   */
  private addWalletEventsListener() {
    const eventProvider = this.provider;

    if (!isEventProvider(eventProvider)) {
      return;
    }

    eventProvider.on(
      OProviderEvents.accountsChanged,
      async (data: string[]) => {
        const currentAddress = data.length ? data[0] : undefined;

        // After clicking on the "lock" button in the MetaMask extension,
        // the event is triggered with an empty array. And in this case,
        // we need to disconnect the user.
        if (!currentAddress) {
          this.disconnect();
        } else {
          this.currentAccount = currentAddress;
        }
      },
    );

    eventProvider.on(OProviderEvents.chainChanged, data => {
      const chainId = data.toString().startsWith('0x')
        ? data
        : this.web3.utils.numberToHex(data);

      const selectedChainId = Number.parseInt(chainId, 16);

      this.chainId = selectedChainId;
    });
  }

  /**
   * Removes event listeners for the wallet
   */
  private removeWalletEventsListener() {
    const eventProvider = this.provider;

    if (!isEventProvider(eventProvider)) {
      return;
    }

    events.forEach(event => {
      eventProvider.removeAllListeners(event);
    });
  }
}
