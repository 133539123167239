import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useErrorPageStyles = makeStyles<Theme>(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  container: {
    maxWidth: '80%',
    marginTop: 50,
  },
  errorMessage: {
    textAlign: 'center',
  },
}));
