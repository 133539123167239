import { generatePath, useParams } from 'react-router';
import { createRouteConfig } from '@ankr.com/utils';
import { IApiBlockchainName } from 'api/types';
import { DEFAULT_CHAIN, ROOT_PATH } from 'common/const';
import { featureConfig } from 'common/featureConfig';
import { HISTORY_ANCHORS } from '../Address/utils';

export const PATH = {
  HOME: '/',
  PAGE_NOT_FOUND: '/404',
  ADDRESS: '/address',
  ADDRESS_RESOLVER: '/addressResolver',
  TRANSACTIONS: '/transactions',
  TRANSACTIONS_BY_BLOCK: '/transactions/block',
  TRANSACTIONS_BY_HASH: '/transactions/hash',
  TRANSACTION_DETAILS: '/transactions/chain',
  BLOCKS: '/blocks',
  BLOCKS_BY_HEIGHT: '/blocks/height',
  BLOCK_DETAILS: '/blocks/chain',

  CHAIN: '/chain',

  // redirect path for support metamask route structure which is used by blockchain explorer like EtherScan
  TOKEN_REDIRECT: '/token',
  TRANSACTION_REDIRECT: '/tx',
};

// EIP-3091 based routes
const NEURA_ROUTES = {
  ADDRESS: `${ROOT_PATH}address/:address`,
  ADDRESS_TRANSACTIONS: `${ROOT_PATH}address/:address/${HISTORY_ANCHORS.TRANSACTIONS}`,
  ADDRESS_MONEY_TRANSFERS: `${ROOT_PATH}address/:address/${HISTORY_ANCHORS.MONEY_TRANSFERS}`,
  ADDRESS_NFT_TRANSACTIONS: `${ROOT_PATH}address/:address/${HISTORY_ANCHORS.NFT_TRANSACTIONS}`,
  ADDRESS_RESOLVER: `${ROOT_PATH}addressResolver/:address`,
  BLOCKS: `${ROOT_PATH}blocks`,
  BLOCK_DETAILS: `${ROOT_PATH}block/:blockHeight`,
  TRANSACTIONS: `${ROOT_PATH}txs`,
  TRANSACTIONS_BY_BLOCK: `${ROOT_PATH}txs/block/:blockHeight`,
  TRANSACTIONS_BY_HASH: `${ROOT_PATH}tx/:hash`,
  TRANSACTION_DETAILS: `${ROOT_PATH}tx/:transactionHash`,

  // redirect route for support metamask route structure which is used by blockchain explorer like EtherScan
  ADDRESS_TOKEN_REDIRECT: `${ROOT_PATH}token/:address`,
  TRANSACTION_BY_HASH_REDIRECT: `${ROOT_PATH}tx/:hash`,

  // unused routes for single chain explorer
  BLOCKS_BY_HEIGHT: `${ROOT_PATH}blocks/height/:blockHeight`,
  CHAIN: `${ROOT_PATH}chain`,
  CHAIN_DETAILS: `${ROOT_PATH}chain/:chain`,
};

/**
 * Routes that are used for ankrscan.io and chain scanner app
 */
export const INITIAL_ROUTES = {
  ADDRESS: `${PATH.ADDRESS}/:address`,
  ADDRESS_TRANSACTIONS: `${PATH.ADDRESS}/:address/${HISTORY_ANCHORS.TRANSACTIONS}`,
  ADDRESS_MONEY_TRANSFERS: `${PATH.ADDRESS}/:address/${HISTORY_ANCHORS.MONEY_TRANSFERS}`,
  ADDRESS_NFT_TRANSACTIONS: `${PATH.ADDRESS}/:address/${HISTORY_ANCHORS.NFT_TRANSACTIONS}`,
  ADDRESS_RESOLVER: `${PATH.ADDRESS_RESOLVER}/:address`,
  BLOCKS: PATH.BLOCKS,
  BLOCK_DETAILS: `${PATH.BLOCK_DETAILS}/:blockchainName/:blockHeight`,
  BLOCKS_BY_HEIGHT: `${PATH.BLOCKS_BY_HEIGHT}/:blockHeight`,
  TRANSACTIONS: PATH.TRANSACTIONS,
  TRANSACTION_DETAILS: `${PATH.TRANSACTION_DETAILS}/:blockchainName/:transactionHash`,
  TRANSACTIONS_BY_BLOCK: `${PATH.TRANSACTIONS_BY_BLOCK}/:blockHeight/:blockchainName`,
  TRANSACTIONS_BY_HASH: `${PATH.TRANSACTIONS_BY_HASH}/:hash`,

  CHAIN: '/chain',
  CHAIN_DETAILS: `${PATH.CHAIN}/:chain`,

  // redirect route for support metamask route structure which is used by blockchain explorer like EtherScan
  ADDRESS_TOKEN_REDIRECT: `${PATH.TOKEN_REDIRECT}/:address`,
  TRANSACTION_BY_HASH_REDIRECT: `${PATH.TRANSACTION_REDIRECT}/:hash`,
};

const ROUTE = featureConfig.isNeura ? NEURA_ROUTES : INITIAL_ROUTES;

export const AddressRoutesConfig = createRouteConfig(
  {
    address: {
      path: ROUTE.ADDRESS,
      generatePath: (address: string) =>
        // in some cases we have empty addresses in transactions, for example: FAMw7AGJCJURnYtbpa1ULqEnp4jVtAV3qvn82xQZX27ezBB
        // so that we added additional check for coming address value to avoid render error here
        address && generatePath(ROUTE.ADDRESS, { address }),
      useParams: () => {
        const { address } = useParams<{ address: string }>();

        return {
          address,
        } as { address: string };
      },
    },
    addressResolver: {
      path: ROUTE.ADDRESS_RESOLVER,
      generatePath: (address: string) =>
        generatePath(ROUTE.ADDRESS_RESOLVER, { address }),
      useParams: () => {
        const { address } = useParams<{ address: string }>();

        return {
          address,
        } as { address: string };
      },
    },
    addressTransactions: {
      path: ROUTE.ADDRESS_TRANSACTIONS,
      redirectPath: `${ROUTE.ADDRESS}/?history=${HISTORY_ANCHORS.TRANSACTIONS}`,
      generatePath: (address: string) =>
        generatePath(ROUTE.ADDRESS_TRANSACTIONS, { address }),
      useParams: () => {
        const { address } = useParams<{ address: string }>();

        return {
          address,
        };
      },
    },
    addressTransfersMoney: {
      path: ROUTE.ADDRESS_MONEY_TRANSFERS,
      redirectPath: `${ROUTE.ADDRESS}/?history=${HISTORY_ANCHORS.MONEY_TRANSFERS}`,
      generatePath: (address: string) =>
        generatePath(ROUTE.ADDRESS_MONEY_TRANSFERS, { address }),
      useParams: () => {
        const { address } = useParams<{ address: string }>();

        return {
          address,
        };
      },
    },
    addressTransfersNft: {
      path: ROUTE.ADDRESS_NFT_TRANSACTIONS,
      redirectPath: `${ROUTE.ADDRESS}/?history=${HISTORY_ANCHORS.NFT_TRANSACTIONS}`,
      generatePath: (address: string) =>
        generatePath(ROUTE.ADDRESS_NFT_TRANSACTIONS, { address }),
      useParams: () => {
        const { address } = useParams<{ address: string }>();

        return {
          address,
        };
      },
    },
    addressTokenRedirect: {
      path: ROUTE.ADDRESS_TOKEN_REDIRECT,
      redirectPath: ROUTE.ADDRESS,
      generatePath: (address: string) =>
        address && generatePath(ROUTE.ADDRESS, { address }),
      useParams: () => {
        const { address } = useParams<{ address: string }>();

        return {
          address,
        };
      },
    },
  },
  ROUTE.ADDRESS,
);

export const BlockRoutesConfig = createRouteConfig(
  {
    blocks: {
      path: ROUTE.BLOCKS,
      generatePath: () => ROUTE.BLOCKS,
    },
    blockDetails: {
      path: ROUTE.BLOCK_DETAILS,
      generatePath: (blockchainName: IApiBlockchainName, blockHeight: string) =>
        generatePath(ROUTE.BLOCK_DETAILS, { blockchainName, blockHeight }),
      useParams: () => {
        const { blockchainName = DEFAULT_CHAIN, blockHeight } = useParams<{
          blockchainName: IApiBlockchainName;
          blockHeight: string;
        }>();
        return {
          blockchainName,
          blockHeight,
        };
      },
    },
    blocksByHeight: {
      path: ROUTE.BLOCKS_BY_HEIGHT,
      generatePath: (blockHeight: string) =>
        generatePath(ROUTE.BLOCKS_BY_HEIGHT, { blockHeight }),
      useParams: () => {
        const { blockHeight } = useParams<{ blockHeight: string }>();
        return {
          blockHeight,
        };
      },
    },
  },
  ROUTE.BLOCKS,
);

export const ChainRoutesConfig = createRouteConfig(
  {
    chain: {
      path: ROUTE.CHAIN,
      generatePath: () => ROUTE.CHAIN,
    },
    chainDetails: {
      path: ROUTE.CHAIN_DETAILS,
      generatePath: (chain: IApiBlockchainName) =>
        generatePath(ROUTE.CHAIN_DETAILS, { chain }),
      useParams: () => {
        const { chain = DEFAULT_CHAIN } =
          useParams<{ chain: IApiBlockchainName }>();
        return {
          chain,
        } as { chain: IApiBlockchainName };
      },
    },
  },
  ROUTE.CHAIN,
);

export const TransactionsRouteConfig = createRouteConfig(
  {
    transactions: {
      path: ROUTE.TRANSACTIONS,
      generatePath: () => ROUTE.TRANSACTIONS,
    },
    transactionDetails: {
      path: ROUTE.TRANSACTION_DETAILS,
      generatePath: (
        blockchainName: IApiBlockchainName,
        transactionHash: string,
      ) =>
        generatePath(ROUTE.TRANSACTION_DETAILS, {
          blockchainName,
          transactionHash,
        }),
      useParams: () => {
        const { blockchainName = DEFAULT_CHAIN, transactionHash } = useParams<{
          blockchainName: IApiBlockchainName;
          transactionHash: string;
        }>();

        return {
          blockchainName,
          transactionHash,
        } as {
          blockchainName: IApiBlockchainName;
          transactionHash: string;
        };
      },
    },
    transactionsByBlock: {
      path: ROUTE.TRANSACTIONS_BY_BLOCK,
      generatePath: (blockchainName: IApiBlockchainName, blockHeight: string) =>
        generatePath(ROUTE.TRANSACTIONS_BY_BLOCK, {
          blockchainName,
          blockHeight,
        }),
      useParams: () => {
        const { blockchainName = DEFAULT_CHAIN, blockHeight } = useParams<{
          blockchainName: IApiBlockchainName;
          blockHeight: string;
        }>();
        return {
          blockchainName,
          blockHeight,
        } as {
          blockchainName: IApiBlockchainName;
          blockHeight: string;
        };
      },
    },
    transactionsByHash: {
      path: ROUTE.TRANSACTIONS_BY_HASH,
      generatePath: (hash: string) =>
        generatePath(ROUTE.TRANSACTIONS_BY_HASH, { hash }),
      useParams: () => {
        const { hash } = useParams<{ hash: string }>();
        return {
          hash,
        } as { hash: string };
      },
    },
  },
  ROUTE.TRANSACTIONS,
);

export const TransactionsRedirectRouteConfig = createRouteConfig(
  {
    transactionsByHash: {
      path: ROUTE.TRANSACTION_BY_HASH_REDIRECT,
      generatePath: (hash: string) =>
        generatePath(ROUTE.TRANSACTION_BY_HASH_REDIRECT, { hash }),
      useParams: () => {
        const { hash } = useParams<{ hash: string }>();
        return {
          hash,
        } as { hash: string };
      },
    },
  },
  PATH.TRANSACTION_REDIRECT,
);
