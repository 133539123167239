import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useChainDetailsStyles = makeStyles<Theme>(() => ({
  wrapper: {
    padding: 40,
  },
  icon: {
    marginRight: 10,
  },
  top: {
    marginBottom: 40,
  },
  title: {
    display: 'flex',
  },
  search: {
    maxWidth: 600,
  },
  table: {
    overflow: 'auto',
  },
  itemsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    columnGap: 16,
  },
  item: {
    flex: 1,
    overflowX: 'auto',
  },
}));
